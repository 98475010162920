import React, {useState } from 'react';
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import Footer from './Footer';
import { Link, useNavigate } from "react-router-dom";
import Spinner from './layout/Spinner'
// import { Link } from "react-router-dom";
// import swal from 'sweetalert';



const ForgetPassword = () => {
    const navigate = useNavigate()
    const [email, setEmail] = useState();
    const [error, setError] = useState('none');
    const [loading, setIsLoading] = useState(false);


    async function sendPasswordResetUrl() {
        return fetch(`https://apis.mutani.co/api/reset-password/${email}`)
          .then(data => data.json())
    }

       const handleSubmit = async e => {
        e.preventDefault();
        setIsLoading(true)
        const response = await sendPasswordResetUrl();

        if (response.status !== 200) {
            setIsLoading(false)
            setError('');   
            
            setTimeout(() => {
                setError('none');
              }, 3000); // Delay of 3000 milliseconds (3 seconds)
        }

        if ('success' in response) {
            setIsLoading(false)
            navigate('/password_reset',{replace: true})
        } else {
            setIsLoading(false)
        }
    }

    if (loading) {
        return <Spinner />
      }
    
  return (
    <div className="md:bg-[#f4f9fd] h-screen w-full font-roboto ">
         <div className='block md:hidden lg:hidden'>
            <div className='max-w-sm mx-4 mb-4 mt-24'>
                <div>
                    <div className='flex justify-between items-center'>
                        <div>
                            <img
                                src={process.env.PUBLIC_URL + `/images/logo.png`}
                                alt=''
                                className='h-24 w-24'
                                id='co_founder'
                            />  
                        </div>
                        <Link to="/signup" className='flex w-32 border border-black justify-center items-center h-12'>
                            <button>Sign Up</button>
                        </Link>

                    </div>
                    <h1 className='my-4 text-gray-600'>Powerful Self-Service Messaging Platform</h1>
                </div>
                
                <form className='mt-4 rounded' onSubmit={handleSubmit}>
                    <div className='flex w-full my-4'>
                        <div className="w-full">
                            <label htmlFor="inputEmail" className='flex text-sm my-2'>Email<p className="text-red-500">*</p></label>
                            <input
                                className='appearance-none border border-black h-10 w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline'
                                type='text'
                                onChange={e => setEmail(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className='flex w-full mt-10'>
                        <div className='flex justify-center items-center bg-[#B0DD7F] h-10 w-10'>
                            <MdOutlineKeyboardArrowRight className=''/>
                        </div>
                        <button className='bg-[#92D050] h-10 text-white w-full'>Login</button>

                    </div>
                </form>

            </div>       
        </div>
        <div class="hidden md:flex flex-col justify-center items-center h-screen text-[#757575]">
            <div className='flex flex-col justify-center items-center md:w-1/3 lg:2/3 h-[38rem]  bg-white my-16'>
                <div className='w-full'>
                    <div className='flex justify-between items-center mx-12 mt-10 lg:mt-6'>
                        <div>
                            <img
                                src={process.env.PUBLIC_URL + `/images/logo.png`}
                                alt=''
                                className='h-24 w-24'
                                id='co_founder'
                            />  
                        </div>
                        <Link to="/login" className='flex w-32 border justify-center items-center h-12'>
                            <button className='text-black'>Login</button>
                        </Link>

                    </div>
                    <h1 className='my-4 mx-12 text-gray-500'>Powerful Self-Service Messaging Platform</h1>
                    <h2 className='my-4 mx-12 text-black text-3xl font-semibold leading-tight'>Password Reset</h2>
                    <form className='mt-4 rounded mx-12' onSubmit={handleSubmit}>
                    <div className='flex w-full my-4'>
                        <div className="w-full">
                            <label htmlFor="inputEmail" className='flex text-sm my-2'>Email<p className="text-red-500 text-xs text-center items-center">*  Please enter your email reset your account</p></label>
                            <input
                                className='appearance-none border h-10 w-full py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline'
                                type='text'
                                onChange={e => setEmail(e.target.value)}
                                required/>
                        </div>
                    </div>
                    <div style={{display: error}} className="text-white px-6 py-4 border-0 rounded relative mb-4 bg-red-500">
                        <span className="text-xl inline-block mr-5 align-middle">
                            <i className="fas fa-bell" />
                        </span>
                        <span className="inline-block align-middle mr-8">
                            <b className="capitalize">User Email Unrecognised</b> 
                        </span>
                        <button className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none">
                            <span>×</span>
                        </button>
                    </div>

                    <div className='flex w-full mt-10'>
                        <div className='flex justify-center items-center bg-[#B0DD7F] h-10 w-10'>
                            <MdOutlineKeyboardArrowRight className=''/>
                        </div>
                        <button className='bg-[#92D050] h-10 text-white w-full'>Reset Password</button>
                    </div>
                    <Link to="/signup" className='flex justify-center my-4 text-[#92D050]'>
                        <p>Sign Up?</p>

                    </Link>
                </form>   
                </div>            
            </div>
        </div>
       </div>

 
    

   
  )
}

export default ForgetPassword




