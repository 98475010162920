import React from 'react'
import { Link } from 'react-router-dom'

const Sidebar = () => {
  return (
    <div>
    <ul className='flex flex-col justify-center mt-24 align-center font-roboto'>
      <div className=''>
        <Link to="/report">
          <li className='border-b border-t border-gray-500 pl-8 py-1  bg-gray-500'>BiRD</li>
        </Link>
        <Link to="/report/dlr">
          <li className='border-b pl-8 py-2 border-gray-500 h-12 items-center flex'>SMS Delivery Receipt (DLRs)</li>
        </Link>
        <li className='border-b pl-8 py-1 border-gray-500 h-12 items-center flex'>Campaign Engagement Report</li>
        <li className='border-b pl-8 py-1 border-gray-500 h-12 items-center flex'>Platform Permance Reports</li>
        <li className='border-b pl-8 py-1 border-gray-500 h-12 items-center flex'>Billing Reports</li>
        <li className='border-b pl-8 py-1 border-gray-500 h-12 items-center flex'>Resources</li>
        <li className='border-b pl-8 py-1 border-gray-500 h-12 items-center flex'>My Account</li>
      </div>
      
    </ul>
    
</div>
  )
}

export default Sidebar