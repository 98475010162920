import React, {useEffect, useState, useContext} from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../../Navbar'
import Nav from './Navigations'
import Managers from './Managers'

const Index = () => {

  return (
      <div className='flex font-roboto'>
        <div className='hidden md:block md:w-1/6 bg-[#394975] h-[49rem] text-white'>
        <Sidebar 
        />
        </div>
        <div className='w-full md:w-5/6'>
         <Navbar/>
          <div className='flex justify-between bg-white mb-4'>
            <p className='flex justify-start ml-4 my-4 text-xl'>User Manager</p>
            <div className='flex justify-end ml-4 my-4'>
              <div className='flex justify-center items-center mr-4 space-x-4'>
                <p className='text-sm text-gray-500'>SMS Wallet Balance</p>
                <span className='bg-[#00B050] h-8 w-16 flex justify-center items-center rounded'>655</span>
                <button className='bg-[#ffc000] h-8 w-16 flex justify-center items-center rounded'>Top Up</button>
              </div>
            </div>
          </div>
          <Nav/>
          <Managers/>
        </div>
      </div>
   )
}

export default Index