import {
  GET_ROLE,
  GET_ROLE_ONLY,
  GET_ALL_USERS,
  GET_SINGLE_USER,
  GET_USER_PERMISSIONS_AND_ROLES,
  GET_ADMIN_PERMISSIONS_AND_ROLES,
  GET_LOGGED_IN_USER_PERMISSIONS,
  SET_LOADING,
  ADMIN_CREATE_USER,
  CPM_CREATE_USER,
  DELETE_USER,
  SEARCH_USER,
  CHANGE_PASSWORD
} from '../types';

export default (state, action) => {
  switch (action.type) {   
    case GET_ROLE: {
      return {
        ...state,
        user_details: action.payload,
        loading: false

      };
    }
    case GET_ROLE_ONLY: {
      return {
        ...state,
        roles: action.payload,
        loading: false

      };
    }
    case GET_ALL_USERS: {
      return {
        ...state,
        users: action.payload,
        loading: false

      };
    }
    case GET_SINGLE_USER: {
      return {
        ...state,
        user: action.payload,
        loading: false

      };
    }
    case GET_LOGGED_IN_USER_PERMISSIONS: {
      return {
        ...state,
        loggedInUserPermissions: action.payload,
        loading: false

      };
    }
    case GET_USER_PERMISSIONS_AND_ROLES: {
      return {
        ...state,
        permissions: action.payload,
        loading: false

      };
    }
    case GET_ADMIN_PERMISSIONS_AND_ROLES: {
      return {
        ...state,
        adminPermissions: action.payload,
        loading: false

      };
    }
    case ADMIN_CREATE_USER: {
      return {
        ...state,
        createUser: action.payload,
        loading: false

      };
    }
    case CPM_CREATE_USER: {
      return {
        ...state,
        cpm: action.payload,
        loading: false

      };
    }
    case CHANGE_PASSWORD: {
      return {
        ...state,
        pass_change: action.payload,
        loading: false

      };
    }
    case SEARCH_USER: {
      return {
        ...state,
        foundUsers: action.payload,
        loading: false

      };
    }
    case DELETE_USER: {
      return {
        ...state,
        loading: false

      };
    }
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};
