import React, {useState, useEffect, useContext} from 'react'
import SenderIdContext from '../../../context/senderids/senderidContext'
import Select from "react-select";
import axios from 'axios'
import { Link } from 'react-router-dom';
import SmsCredit from '../../SmsCredit';

const SendSingleSms = () => {
    const senderIdContext = useContext(SenderIdContext);
    const { 
        getUserSenderIdByUserEmail,
        userSenderIds 
    } 
    = senderIdContext;

    const user = localStorage.getItem("user");
    const parseUser = JSON.parse(user);
    const email = parseUser.email
    const token = localStorage.getItem("token");

    const [message, setMessage] = useState('')
    const [messageLegnth, setMessageLegnth] = useState(0)
    const [recipients, setRecipient] = useState("")
    const [sender, setSender] = useState('')
    const [selectSid, setSelectSid] = useState([""])
    const [displaySuccessAlert, setDisplaySuccessAlert] = useState('none')
    const [displayForm, setDisplayForm] = useState('')

    const [recipientError, setrecipientError] = useState('1px solid gray')
    const [senderError, setSenderError] = useState('1px solid gray')
    const [messageError, setMessageError] = useState('1px solid gray')

    
  useEffect(() => {
    formNewArrayFromUserSenderIds()
    if (userSenderIds.length == 0) {
        getUserSenderIdByUserEmail(email)
    }
         
    }, [userSenderIds])

    const formNewArrayFromUserSenderIds = () =>{
        const arr = [];
        userSenderIds.map((sids)=>{
          return arr.push({value: sids.sender_id, label: sids.sender_id});
        })
        setSelectSid(arr);
    }

    const  sendSmsParamsToBackend = async (credentials) => {
        const headers = {
             'Content-Type': 'application/json', 
             Authorization: 'Bearer ' + token,    
         }
         axios.post(`https://apis.mutani.co/api/sms/`, 
         JSON.stringify(credentials),
         {
            headers: headers,
    
        })
        .then((response) => {
          const data = response.data

          if ('success' in data) {
            setDisplayForm('none')
            setDisplaySuccessAlert('')
            
          }
          console.log(data)
         
        })
        .catch(function (error) {
            console.error(error);
            
            
        });
    }

    const handleSubmit = async (e) => 
    {
        e.preventDefault();
        console.log(message)
        const recipient = recipients.split(",");
        const arr = ['']

        if (JSON.stringify(recipient) === JSON.stringify(arr)) {
            setrecipientError('1px solid red')

            return setTimeout(() => {
                setrecipientError('1px solid gray');
            }, 3000); // Delay of 3000 milliseconds (3 seconds
            
        }else if (sender === '') {
            setSenderError('1px solid red')

            return setTimeout(() => {
                setSenderError('1px solid gray');
            }, 3000); // Delay of 3000 milliseconds (3 seconds
            
        }else if (message === '') {
            setMessageError('1px solid red')

            return setTimeout(() => {
                setMessageError('1px solid gray');
            }, 3000); // Delay of 3000 milliseconds (3 seconds
            
        }
        else{
            await sendSmsParamsToBackend({
                message,
                sender,
                recipient,
            });

        }

     

    }

    const countMessageCharacters = (e) => {
        setMessage(e.target.value);
        setMessageLegnth(e.target.value.length);
    }

  return (
    <div className=' bg-white mx-8 h-[32rem] my-4 font-roboto'>
        <SmsCredit activity='Send Sms'/>
        <form style={{display: displayForm}} onSubmit={handleSubmit} className='flex flex-col justify-center items-center w-full my-4'>
            <div className="flex flex-col justify-center items- w-2/3">
                <label htmlFor="inputEmail" className='flex text-sm my-2'>Enter Phone Number(s) – must begin with country code (e.g. 233 or 44)</label>
                <input
                    className='appearance-none h-10 w-full py-2 px-3 text-gray-500 leading-tight focus:outline-none focus:shadow-outline'
                    type='text'
                    style={{border: recipientError}}
                    required
                    onChange={e => setRecipient(e.target.value)}
                />
            </div>
            <div className="flex justify-between items- w-2/3">
                <div className='justify-start'></div>
                <p className='flex items-center justify-center text-sm text-gray-500'>Multiple numbers should comma separated</p>
            </div>
            <div className="flex flex-col justify-center items- w-2/3">
                <label htmlFor="inputEmail" className='flex text-sm my-2'>Select or Add Sender ID</label>
                <Select
                    options={selectSid}
                    placeholder="Select Sender ID"
                    onChange={(sender) => setSender(sender.value)}
                    className="basic-multi-select w-full"
                    classNamePrefix="select"
                />
                {/* <input
                    className='appearance-none border border-gray-300 h-10 w-full py-2 px-3 text-gray-500 leading-tight focus:outline-none focus:shadow-outline'
                    type='text'
                    // onChange={e => setUserName(e.target.value)}
                /> */}
            </div>
            <div className="flex flex-col justify-center items- w-2/3">
                <div className='flex justify-between'>
                    <label htmlFor="inputEmail" className='flex justify-start text-sm my-2'>Draft your message content</label>
                    <label htmlFor="inputEmail" className='flex justify-start text-sm my-2 text-red-500'>{messageLegnth} character(s), Y page</label>
                </div>
                <textarea 
                    required
                    name="message" 
                    id="" 
                    cols="15" 
                    rows="8"    
                    onChange={countMessageCharacters}  
                    style={{border: messageError}}

                    className='appearance-none  w-full py-2 px-3 text-gray-500 leading-tight focus:outline-none focus:shadow-outline'></textarea>
            </div>
            <div className="flex justify-between items- w-2/3 mt-1">
                <div className='justify-start'></div>
                <button onClick={handleSubmit} className='flex items-center justify-center text-white bg-[#00B050] w-40 h-8'>Send Sms</button>
            </div>
        </form>

        <div className='' style={{display: displaySuccessAlert}}>
            <div className='flex flex-col bg-[#ECF3FA] mx-4'>
                <div className='flex mt-4 mx-4'>
                    <p className='text-sm mx-1 text-red-400'>
                    {recipients.split(",").length}
                    </p>
                    <p className='text-sm mx-1'> messages(s) sent</p>
                    <p className='text-sm mx-1 text-green-400'>successfully</p>

                </div>
                <div className='flex my-4 mx-4'>
                    <p className='text-sm mx-1 text-red-400'>
                        {0}
                    </p>
                    <p className='text-sm mx-1'>messages(s)</p>
                    <p className='text-sm mx-1 text-red-400'>failed</p>

                </div>
            </div>
            <div className='flex justify-end w-full mr-24 mt-8'>
                <Link to='/bam/sms' className='flex justify-center text-sm text-white bg-[#00B050] w-40 h-8 items-center mr-4'>Send New SMS</Link>
            </div>    
        </div>
    </div>  
)
}

export default SendSingleSms