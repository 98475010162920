import React, {useState} from 'react'
import {AiFillFolderAdd} from 'react-icons/ai'


const Users = ({  user, clickToUploadPersonalisedPricing}) => {
  return (
    <>
        <tr key={user.id}>
            <td className='text-left border-b border-t-2 text-sm text-gray-500'>{ user.customer_id }</td>
            <td className='text-left border-b border-t-2 text-sm text-gray-500'>{ user.email }</td>
            <td className='text-left border-b border-t-2 text-sm text-gray-500'>{ user.first_name } { user.last_name }</td>
            <td className='text-left border-b border-t-2 text-sm text-gray-500'>{ user.user_group }</td>
            <td className='text-left border-b border-t-2 text-sm text-gray-500'>GHS { 100 }</td>
            <td className="text-left border-t-2 px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <button 
                    onClick={()=> clickToUploadPersonalisedPricing(user.id)} 
                    className="text-left flex text-gray-500 text-lg">
                    <AiFillFolderAdd 
                        className='mx-2'/>
                </button>
            </td>
            <td>
            {/* {
                confirmDelete ? <ConfirmDeletion text = "user" user={ user } deleteUser = {deleteUser} handleCancle={handleCancle}/> : null
            }
            </td>
            <td>
            {
                isEditing ? 
                <UserEdit 
                    user={user} 
                    cancelEditForm={cancelEditForm} 
                    user_roles={user_roles} 
                    updateUser={updateUser}
                /> : null
            } */}
                
            </td>
            

        </tr> 
        </>
        )
}

export default Users