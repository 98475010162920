import React, {useEffect, useState, useContext} from 'react';
import GithubContext from '../../context/user/userContext';
import "react-phone-input-2/lib/bootstrap.css";
import ChangePassword from './ChangePassword';
import {FiEdit} from 'react-icons/fi'
import EditUserProfile from './EditUserProfile';
import PasswordChangeSuccess from './PasswordChangeSuccess';

const Profile = () => {
    const githubContext = useContext(GithubContext);
    const { 
        user_details, 
        getUserRole, 
        updateUser,
        loading,
        pass_change,
        changeUserPassword 
    } = 
    githubContext;

    const [isChangingPassword, setIsChangingPassword] = useState(false)
    const [isEditProfile, setIsEditProfile] = useState(false)
    const [passwordChange, setPasswordChange] = useState(false)

    const user = localStorage.getItem("user");
    const parseUser = JSON.parse(user);
    const id = parseUser.id


    const changePassword = () => {
        setIsChangingPassword(true)
    }

    const handleCancel = () => {
        setIsChangingPassword(false)
    }
   
    useEffect(() => {
        getUserRole(id);   
    }, []);

    const editProfile = () =>{
        setIsEditProfile(true)
    }

    

    const onOkayPress = () =>{
        setPasswordChange(false)
    }


    const cancelEditProfile = () =>{
        setIsEditProfile(false)
    }

    if (loading) return <div>Loading</div>

  return (
    
    <div className='block md:flex items-center justify-center mx-2'>
        <div className='flex flex-col w-full md:mx-2 h-[29rem] border-4 border-gray-100'>
            <div className='w-full'>
                <div className='flex justify-between my-2'>
                    <div></div>
                    <h3 className='flex justify-center items-center'></h3>
                    <div>
                    <button 
                        onClick={editProfile} 
                        className="flex justify-center items-center bg-[#97a39d] mr-60 w-32 text-white h-10">
                        <FiEdit className='text-xl mx-1'/>
                        <p className='px-1 text-xs'>Edit Profile</p>                                    
                    </button> 
                    </div>
                </div>
            <div className='flex flex-col text-gray-300 justify-center items-center'>
                <div className='flex w-[38rem] h-10 my-2'>
                    <p className='flex w-[7rem] text-black text-left mx-2'>First name</p>
                    <p className='flex mx-2 w-full justify-center items-center border border-gray-300 shadow h-10 rounded mb text-center'>{user_details.first_name}</p>
                </div>
                <div className='flex w-[38rem] h-10 my-2'>
                    <p className='flex w-[7rem] text-black text-left mx-2'>Last name</p>
                    <p className='flex mx-2 w-full justify-center items-center border border-gray-300 shadow h-10 rounded mb text-center'>{user_details.last_name}</p>
                </div>
                <div className='flex w-[38rem] h-10 my-2'>
                    <p className='flex w-[7rem] text-black text-left mx-2'>Email</p>
                    <p className='flex mx-2 w-full justify-center items-center border border-gray-300 shadow h-10 rounded mb text-center'>{user_details.email}</p>
                </div>
                <div className='flex w-[38rem] h-10 my-2'>
                    <p className='flex w-[7rem] text-black text-left mx-2'>Telephone</p>
                    <p className='flex mx-2 w-full justify-center items-center border border-gray-300 shadow h-10 rounded mb text-center'>{user_details.phone}</p>
                </div>
                <div className='flex w-[38rem] h-10 my-2'>
                    <p className='flex w-[7rem] text-black text-left mx-2'>Country</p>
                    <p className='flex mx-2 w-full justify-center items-center border border-gray-300 shadow h-10 rounded mb text-center'>{user_details.country}</p>
                </div>
                <div className='flex w-[38rem] h-10 my-2'>
                    <p className='flex w-[7rem] text-black text-left mx-2'>User Group</p>
                    <p className='flex mx-2 w-full justify-center items-center border border-gray-300 shadow h-10 rounded mb text-center'>{user_details.user_group}</p>
                </div>
                <div className='flex w-[40rem] h-10 my-2'>
                    <label htmlFor='password' className='flex justify-center items-center text-black  text-left pl-6 mr-8'>Password</label>
                    <button onClick={changePassword} className="w-[30rem] text-gray-700 bg-[#ECF3FA] border border-gray-300 shadow rounded mb text-center">Change Password</button>
                </div>
            </div> 
            {
                isChangingPassword && 
                <ChangePassword 
                    handleCancel={handleCancel}
                    changeUserPassword={changeUserPassword}
                    pass_change={pass_change}
                    onOkayPress = {onOkayPress}
                />
            }
            {
                isEditProfile && 
                <EditUserProfile
                    cancelEditProfile={cancelEditProfile}
                    user_details={user_details}
                    updateUser={updateUser}
                />
            }
            {
               passwordChange && <PasswordChangeSuccess/>
            }
            </div>
           
        </div>

    </div>
    )
}
export default Profile