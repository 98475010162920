import React from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../../Navbar'
import Nav from './Nav'
import ViewAllPermissions from './ViewAllPermissions'
import Footer from '../../Footer'
const Index = () => {
  return (
    <>
    <div className='flex'>
      <div className='hidden md:block md:w-1/6 bg-[#394975] h-[49rem] text-white'>
        <Sidebar/>
      </div>
      <div className='w-full md:w-5/6 bg-[#ECF3FA]'>
        <Navbar/>
        <div className='bg-white mt-8 mx-10 pb-2'>
          <Nav/>
          <ViewAllPermissions/>
        </div>
      </div>
    </div>
    <Footer/>
  </>  
  )
}

export default Index