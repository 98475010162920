import React from 'react'
import LoginUserPermissions from './LoginUserPermissions'

const UserPermissions = ({permission}) => {
  return (
        <thead>
            <tr>
                <th className=" border-b bg-gray-100 text-xs font-semibold text-gray-700 uppercase tracking-wider">
                    <p className='flex w-full justify-center items-center text-center text-gray-500 h-10 text-xs' key={permission.id}>{permission.name}</p>     
                </th>
                <td className="bg-white text-sm border">
                    {
                        permission.permissions.length > 0
                        ?
                        permission.permissions.map((user) => (
                            <LoginUserPermissions user={user} key={user.id}/>
                        ))
                        :
                    <tr>
                        <td colSpan="5" className="text-center">No Persmissions Found.</td>
                    </tr> 
                }
                </td>
            </tr>                               
        </thead>
    )
}

export default UserPermissions