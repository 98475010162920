import React, {useState, useContext} from 'react'
import {GrSettingsOption, GrFormAdd} from 'react-icons/gr'
import Nav from './Nav'
import Permissions from './Permissions'
import ShowPermissions from './ShowPermissions'
import RoleContext from '../../../context/roles/roleContext'

const Button = ({role}) => {
    const roleContext = useContext(RoleContext);

    const { 
        getRolesPermissionsById, 
        role_permission,
    } = roleContext;

    const [hide, setHide] = useState("none")

    const handleClick = () =>{
        getRolesPermissionsById(role.id)
        setHide("")
    }

    const hideViewPermission = () =>{
        setHide("none")
    }
  return (
    <>
        <button onClick={() =>  {

            handleClick()
            }} 
            className="flex justify-center items-center mx-2 h-10">
            <GrSettingsOption className='mx-1'/>
            <p className='px-1'>view permissions</p>                                    
        </button> 
        <div style={{ display: hide}}>
        {
            <ShowPermissions hideViewPermission={hideViewPermission} role_permission={role_permission}/>
            // role.permissions.map((permission)=>
            // (
            //     console.log(permission.id)

            //     // <Permissions permission={permission} key={permission.id}/>
            // ))
        }

        </div>

    </>
     )
}

export default Button