import React from 'react'
import Sidebar from '../../Sidebar'
import Navbar from '../../Navbar'
import SendSingleSms from './SendSingleSms'

const Single = () => {
  return (
    <div className='flex'>
        <div className='w-1/6 bg-[#394975] h-screen text-white'>
            <Sidebar/>
        </div>
        <div className='w-5/6 bg-white'>
            <Navbar/>
            <div className='flex flex-col mx-6 py-2 bg-[#ECF3FA]'>    
                <SendSingleSms/>
            </div>
        </div>
    </div>  
)
}

export default Single