import React, {useState} from 'react'
import Button from '../../Button'
import SmsCredit from '../../SmsCredit'
import { Link, useNavigate  } from "react-router-dom";
import Payments from './Payments';

const PaymentHistory = ({payments}) => {
    const navigate = useNavigate()

    const gotoPaymentHistory = () =>{
        navigate('/payment/history',{replace: true})
    }

    const gotoAddFunds = () =>{
        navigate('/payment',{replace: true})
    }
  return (
<div className='flex flex-col mx-6 py-2 bg-[#ECF3FA]'>    
        <div className=' bg-white mx-8 h-[30rem] my-4 font-roboto'>
            <SmsCredit activity='Billing & Payments Manager'/>
            <div className='flex ml-10 text-black mt-6'>
            <Button 
                clickToMakeButtonActive={gotoAddFunds} 
                className='' text='Add Funds' 
                color={''}/>
            <Button 
                clickToMakeButtonActive={gotoPaymentHistory} 
                className=''
                text='Payment History' 
                color={'#ECF3FA'}/>
            <Button 
                // clickToMakeButtonActive={gotoAddFunds} 
                className='' 
                text='Payment Method' 
                color={''}/>
            <Button 
                // clickToMakeButtonActive={clickToMakeReviewActive} 
                className='' text='Low Balance Alert' 
                color={''}/>
            </div> 
            <div className='border-2 border-gray-200 h-80 mt-4 mx-10'>
                <div className='flex justify-center items-center mx-4'>
                    <div  className="overflow-y-scroll h-60 mt-8">
                        <table className="table border-collapse">
                            <thead className=''>
                                <tr className='text-center bg-[#ECF3FA]'>
                                    <th className="tracking-wider text-sm w-64">
                                        Transaction <br /> Reference
                                    </th>
                                    <th className="tracking-wider text-sm w-64">
                                        Date
                                    </th>
                                    <th className="tracking-wider text-sm w-64">
                                        Amount
                                    </th>
                                    <th className="tracking-wider text-sm w-64">
                                        Wallet <br /> Activity <br /> Type
                                    </th>
                                    <th className="tracking-wider text-sm w-64">
                                        Wallet <br /> Balance
                                    </th>
                                    <th className="tracking-wider text-sm w-64">
                                        Payment <br /> Method
                                    </th>
                                </tr>
                            </thead>
                            <tbody className=''>
                            {
                                    payments.length > 0
                                    ?
                                    payments.map((history) => (
                                            <Payments 
                                            key={history.id} 
                                            history={history} 
                                            />
                                    ))
                                    :
                                    <tr>
                                        <td colSpan="5" className="text-center">No SenderIds Found.</td>
                                    </tr> 
                            }
                            
                            </tbody>
                        </table>
                        
                    </div> 
                </div>

            </div>
        </div>
    </div>    
    )
}

export default PaymentHistory