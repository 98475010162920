import React,{useState, useEffect, useContext, useLayoutEffect } from 'react'
import {MdKeyboardArrowLeft} from 'react-icons/md'
import { Link, useNavigate } from "react-router-dom";
import Button from '../../Button';
import Select from "react-select";
import { read, utils, XLSX } from 'xlsx';
import Modal from './Modal';
import SenderIdContext from '../../../context/senderids/senderidContext'
import axios from 'axios'
import SmsCredit from '../../SmsCredit';


const FileUpload = () => {
    const senderIdContext = useContext(SenderIdContext);
    const { 
        getUserSenderIdByUserEmail,
        userSenderIds 
    } 
    = senderIdContext;


    const navigate = useNavigate()

    const [showExtractedSpreedSheet, setShowExtractSpreedSheet] = useState(true)

    const [data, setdata] = useState([])
    const [cols, setcols] = useState([])


    const [sender_idd, setSenderId] = useState('none')
    const [messageDisplay, setMessageDispaly] = useState('none')
    const [file_up, setFileUp] = useState('')
    const [review, setReview] = useState('none')
    const [conf, setConf] = useState('none')

    const [duplicate, setDuplicate] = useState([])
    const [dup, setDup] = useState([])
    const [recipient, setRecipients] = useState([])

    const [defColorFile, setdefColorFile] = useState('#cccccc')

    const [totalContact, setTotalContact] = useState()

    const [filecolor, setFileRed] = useState('#ECF3FA')
    const [sendercolor, setSendereRed] = useState('white')
    const [messagecolor, setMessageColor] = useState('white')
    const [reviewcolor, setReviewColor] = useState('white')
    const [confirmation, setConfirmation] = useState('white')

    const [message, setMessage] = useState('')
    const [sender, SetUserSenderId] = useState('')
    const [recipients, setRecipient] = useState("");

    const [pageCount, setPageCount] = useState('1');

    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    const parseUser = JSON.parse(user);
    const email = parseUser.email
    const [messageLegnth, setMessageLegnth] = useState(0)
    const [user_permission, setPermission] = useState('')
    const [selectPermission, setSelectPermission] = useState([""])
    const [pricingList, setPricingList] = useState([]);
    const [phoneNumbers, setPhoneNumbers] = useState([]);
    const [price, setPrice] = useState('');

       
    const getPricelist = () => {
        const headers = {
             accept: 'application/json',
             Authorization: 'Bearer ' + token
         }
         axios.get(`https://apis.mutani.co/api/pricelist`, {
            headers: headers
        })
        .then((response) => {
          const data = response.data

          const parsedPricingList = data.pricelist.map(item => ({
            country_code: (`${item.country_code}`),
            price: parseFloat(item.new_price.toFixed(4)),
            country: item.country

          }));

          setPricingList(parsedPricingList);
  
        })
        .catch(function (error) {
            console.error(error);
            
        });
    }

  useEffect(() => {
    formNewArrayFromUserSenderIds()
    if (pricingList.length === 0) {
        getPricelist()
    }
    getUserSenderIdByUserEmail(email)
    }, [])



    const formNewArrayFromUserSenderIds = () =>{
    const arr = [];
    userSenderIds.map((role)=>{
      return arr.push({value: role.sender_id, label: role.sender_id});
    })
    setSelectPermission(arr);
    }

    const clickToMakeSenderIdActive = () => {
        setFileUp('none')
        setFileRed('white')
        setMessageColor('')
        setReviewColor('')
        setSendereRed('#ECF3FA')
        setSenderId('')
        setMessageDispaly('none')
        setReview('none')

    }

    const clickToMakefileUploadActive = () => {
        setFileUp('')
        setFileRed('#ECF3FA')
        setMessageColor('')
        setReviewColor('')
        setSendereRed('white')
        setSenderId('none')
        setReview('none')
        setMessageDispaly('none')

    }

    const clickToMakeMessageActive = () => {
        setMessageDispaly('')
        setReviewColor('')
        setSendereRed('white')
        setMessageColor('#ECF3FA')
        setReview('none')
        setSenderId('none')
        setFileUp('none')
        setFileRed('')


    }
       const activateSubmitBtn = () => {
        if (messageLegnth > 0) {
            console.log('go')
        }
    }

    const clickToMakeReviewActive = () => {
        activateSubmitBtn()
        setReview('')
        setSendereRed('white')
        setMessageColor('white')
        setReviewColor('#ECF3FA')
        setSenderId('none')
        setMessageDispaly('none')
        setFileUp('none')
        setFileRed('')


    }

    const  sendSmsParamsToBackend = async (credentials) => {
        const headers = {
             'Content-Type': 'application/json', 
             Authorization: 'Bearer ' + token,    
         }
         axios.post(`https://apis.mutani.co/api/sms/`, 
         JSON.stringify(credentials),
         {
            headers: headers,
    
        })
        .then((response) => {
          const data = response.data
         
        })
        .catch(function (error) {
            console.error(error);
            
            
        });
    }

    const handleSubmit = async (e) => 
    {
        e.preventDefault();
        setReview('none')
        setConf('')
        setReviewColor('white')
        setConfirmation('#ECF3FA')

        await sendSmsParamsToBackend({
            message,
            sender,
            recipient,
            token,            
          });
    }
 
    const make_cols = refstr => {
        let o = [],
        C = utils.decode_range(refstr).e.c + 1;
        for (var i = 0; i < C; ++i) o[i] = { name: utils.encode_col(i), key: i };
        return o;
    };


    const countMessageCharacters = event => {
        const messLength = event.target.value.length;
        const oldPrice = price
        setMessageLegnth(messLength);
        setMessage(event.target.value);
        if (messLength >= 60) {
            setPageCount(2)
            const newPrice = (oldPrice * 2)
            setPrice(newPrice)
        }else if (messLength < 60) {
            setPageCount(1)
            setPrice(oldPrice)
        }
        
    };


    const displaySenderPage = (e) => {
        e.preventDefault();
        // const countryOccurrences = getCountryOccurrences(phoneNumbers);

        if (duplicate.length === 0) {
            setFileUp('none')
            setFileRed('white')
            setSendereRed('#ECF3FA')
            setSenderId('')
        }
        setFileUp('none')
        setSenderId('')
    }

    const proceedWithDeletedDuplicate = (e) => {
            e.preventDefault();
            setFileUp('none')
            setFileRed('white')
            setSendereRed('#ECF3FA')
            setSenderId('')
            setDuplicate(0)

    }

    const removeDuplicates = (duplicatedArray) => {
        return duplicatedArray.filter((item,
        index) => duplicatedArray.indexOf(item) === index);
    }


    const displayMessagePage = (e) => {
        e.preventDefault();
        SetUserSenderId(user_permission.value)
        setSenderId('none')
        setMessageDispaly('')
        setSendereRed('white')
        setMessageColor('#ECF3FA')
        setSenderId('none')
    }

    const displayReviewPage = (e) => {
        e.preventDefault();
        setSenderId('none')
        setMessageDispaly('none')
        setSendereRed('white')
        setMessageColor('white')
        setReviewColor('#ECF3FA')
        setSenderId('none')
        setReview('')
    }

    const handleReviewPreviousBtnClick = (e) =>{
        e.preventDefault()
        setSenderId('none')
        setMessageDispaly('')
        setSendereRed('white')
        setMessageColor('#ECF3FA')
        setReviewColor('white')
        setReview('none')
    }

   
    const gotoUploadFilePrevBtn = (e) => {
        e.preventDefault();
        setFileUp('')
        setFileRed('#ECF3FA')
        setSendereRed('white')
        setSenderId('none')
    }
    const gotoSenderIdPrevBtn = (e) => {
        e.preventDefault()
        setFileRed('white')
        setSendereRed('#ECF3FA')
        setSenderId('')
        setMessageColor('white')
        setMessageDispaly('none')
    }

    const findDuplicates = (arr) => {
    let sorted_arr = arr.slice().sort()
    let results = []
    for (let i = 0; i < sorted_arr.length - 1; i++) {
      if (sorted_arr[i + 1] === sorted_arr[i]) {
        results.push(sorted_arr[i]);
      }
    }
    return results;
    }

    const sendNewSms = (e) =>{
        e.preventDefault()
        navigate(0)
    }

    const reUploadFile = (e) =>{
        e.preventDefault()
        // navigate('/bam/sendsms/excel',{replace: true})
        navigate(0)

    }    

    
    // async function fetchCountryCode(phone) {
    //     try {
    //     const response = await axios.get(`http://apilayer.net/api/validate?access_key=81e252c017ba06d0e66d8357cce02460&number=${phone}`);
    //     const data = response.data;
    
    //     const countryCode = data.country_prefix;

    //     console.log(countryCode)
    
    //     return countryCode;
    //     } catch (error) {
    //     console.error('Error fetching country code:', error);
    //     return ''; // Return an empty string if there's an error
    //     }
    // }

    // const calculateTotalPrice = async (telephoneNumbers) => {
    //     let totalPrice = 0;
      
    //     for (const number of telephoneNumbers) {
    //       let countryCode = '';
      
    //       if (number) {
    //         // Make an API call to fetch the country code based on the number
    //         countryCode = await fetchCountryCode(number);
    //       }
      
    //       const country = pricingList.find((item) => item.country_code === countryCode);
      
    //       if (country) {
    //         const price = country.price;
    //         totalPrice += price;
    //       }
    //     }
      
    //     const roundedNumber1 = totalPrice.toFixed(4);

    //     console.log(roundedNumber1);
      
    //     return totalPrice;
    // };


    const calculateTotalPrice = (telephoneNumbers) => {
        let totalPrice = 0;

        telephoneNumbers.forEach((number) => {

        let countryCode = '';
    
        if (number) {
            if (number.startsWith('+')) {
            for (let i = 0; i <= 4; i++) {
                countryCode = number.substring(1, i + 1);
                if (pricingList.some((item) => item.country_code === countryCode)) {
                break;
                }
            }
            } else {
            for (let i = 0; i <= 4; i++) {
                countryCode = number.substring(0, i + 1);
                if (pricingList.find((item) => item.country_code === countryCode)) {
                break;
                }
            }
            }
        }
    
        const country = pricingList.find((item) => item.country_code === countryCode);

        const price = country ? country.price : 0;
        totalPrice += price;
        });


        return totalPrice;
    };

    
    const getCountryOccurrences = (telephoneNumbers) => {
        const countryOccurrences = {};
      
        telephoneNumbers.forEach((number) => {
          let countryCode = '';
      
          if (number) {
            for (let i = 0; i <= 4; i++) {
              countryCode = number.substring(0, i + 1);
              if (pricingList.some((item) => item.country_code === countryCode)) {
                break;
              }
            }
          }
      
          if (countryCode) {
            countryOccurrences[countryCode] = (countryOccurrences[countryCode] || 0) + 1;
          }
        });
      
        return countryOccurrences;
      };


    const handleSpreedSheetImport = ($event) => {
    setdefColorFile('#ECF3FA')
    setShowExtractSpreedSheet(!showExtractedSpreedSheet)
    const files = $event.target.files;
    if (files.length) {
        const file = files[0];
        const reader = new FileReader();

        reader.onload = (event) => {
            const wb = read(event.target.result);                
            const sheets = wb.SheetNames;
            const wsname = wb.SheetNames[0];

            const ws = wb.Sheets[wsname];

            if (sheets.length) {
                const rows = utils.sheet_to_json(ws, { header: 1 });
                const result = rows.map(([value]) => `${value}`);


                setPhoneNumbers(result)

                const total = calculateTotalPrice(result);
                const roundedNumber = total.toFixed(4);
                
                setPrice(roundedNumber)

        
                const cols = make_cols(ws["!ref"])

                setdata(rows)
                setcols(cols)


                setTotalContact(result.length)

                setDuplicate(findDuplicates(result));
                setDup(findDuplicates(result));
                setRecipients(removeDuplicates(result))


                setRecipient(rows)

            }
        }
        reader.readAsArrayBuffer(file);

        }
    }



  return (
    <div>
        <div className=' bg-white mx-8 h-[32rem] mb-4'>
        <SmsCredit activity='Send Sms'/>

          {/* <Crumbs/> */}
            {/* <SendingCrumbs crumbs={crumbs} selected={selected}/> */}
            <div className='flex ml-10 text-black mt-6'>
                <Button 
                    clickToMakeButtonActive={clickToMakefileUploadActive} 
                    className='' text='File Upload' 
                    color={filecolor}/>
                <Button 
                    clickToMakeButtonActive={clickToMakeSenderIdActive} 
                    className=''
                    text='Sender ID' 
                    color={sendercolor}/>
                <Button 
                    clickToMakeButtonActive={clickToMakeMessageActive} 
                    className='' 
                    text='Message' 
                    color={messagecolor}/>
                <Button 
                    clickToMakeButtonActive={clickToMakeReviewActive} 
                    className='' text='Review & Send SMS' 
                    color={reviewcolor}/>
                <Button className='' text='Confirmation' color={confirmation}/>
            </div>
            <div className='h-28 mx-8 mt-6'>
                <div className='flex w-full my-4'>
                    <form onSubmit={handleSubmit} className='flex flex-col w-full'>
                        <div className='' style={{display: file_up}}>
                            {
                                    showExtractedSpreedSheet ?
                                    <>
                                        <div className='border mx-2'>
                                            <p className='text-xs text-gray-400 pl-2 ml-2 my-2'>Upload Contact (Format: CSV, XLSX, XLS)</p>
                                            <div className='flex justify-center items-center w-1/2 mx-4 h-20 border border-gray-300 my-4'>
                                                <input type="file" name='file' className='text-gray-700 ml-2 mx-2 px-4 file:h-10 file:w-40 hover:file:cursor-pointer hover:file:bg-white'
                                                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                required onChange={handleSpreedSheetImport}/>   
                                            </div>
                                            
                                        </div>
                                    </> : 
                                    <>
                                       <div className="mx-4">
                                        <div className="">
                                            <div className="mb-2">
                                                <h2 className="">Recipient Numbers</h2>
                                            </div>
                                            <div className="overflow-scroll h-52">
                                                <table className="table border-collapse min-w-full h-52 leading-normal">
                                                <thead>
                                                    <tr className='border'>
                                                        {/* {
                                                            cols.map(c => (
                                                                <th className='px-5 py-3 border border-gray-200 bg-gray-100 text-xs font-semibold text-gray-700 uppercase tracking-wider' key={c.key}>
                                                                    {c.name}
                                                                </th>
                                                              ))
                                                        } */}
                                                        {/* <th className="px-5 py-3 border border-gray-200 bg-gray-100 text-xs font-semibold text-gray-700 uppercase tracking-wider">
                                                            ID
                                                        </th>
                                                        <th className="px-5 py-3 border border-gray-200 bg-gray-100 text-xs font-semibold text-gray-700 uppercase tracking-wider">
                                                            Recipient Numbers
                                                        </th> */}
                                                    
                                                    </tr>
                                                </thead>
                                                <tbody className='overflow-y-scroll border'>
                                                {
                                                    data.map((r, i) => (
                                                        <tr key={i}>
                                                          {cols.map(c => (
                                                            <td className='border' key={c.key}>{r[c.key]}</td>
                                                          ))}
                                                        </tr>
                                                      ))
                                                        // recipients.length
                                                        // ?
                                                        // recipients.map((movie, index) => (
                                                        //     <tr key={index}>
                                                        //         <th scope="row" className='border'>{ index + 1 }</th>
                                                        //         <td className='border'>{ movie.recipient }</td>
                                                        //     </tr> 
                                                        // ))
                                                        // :
                                                        // <tr>
                                                        //     <td colSpan="5" className="text-center">No Recipients Found.</td>
                                                        // </tr> 
                                                    }
                                                
                                                </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    </>

                                
                            }
                            <div className='flex justify-between w-full mx-2 my-4'>
                                <Link to='/bam/sendsms'>
                                    <button className='flex justify-center text-sm  bg-[#ECF3FA] text-black w-60 h-10 items-center'>
                                    <div className='flex mx-0 px-0'>
                                        <MdKeyboardArrowLeft className='mx-0 px-0'/>
                                        <MdKeyboardArrowLeft className='mr-0 px-0'/>
                                    </div>      
                                        Back to Bulk SMS Option</button>
                                </Link>
                                <button  
                                    // disabled={disableFileUploadBtn} 
                                    onClick={displaySenderPage} 
                                    className='flex justify-center text-black w-24  h-8 items-center mr-4' 
                                    style={{ backgroundColor: defColorFile}}>Next</button>
                            </div>
                            
                        </div>
                        <div className='' style={{display: sender_idd}}>
                            {
                                 duplicate.length > 0 ?
                                 <>
                                 <Modal 
                                    duplicate={dup.length} 
                                    removeBtnClick = {proceedWithDeletedDuplicate} 
                                    reloadUploadBtnClick={reUploadFile}/>
                                 </> : 
                                 <div className=''>
                                    <div className='border mx-2'>
                                        <p className='text-sm text-gray-400 pl-2 ml-2 my-2'>Select Sender ID</p>
                                        <div className='flex justify-center items-center w-1/2 mx-4 h-20 border border-gray-300 my-4'>
                                        <Select
                                            options={selectPermission}
                                            placeholder="Select Sender ID"
                                            // value={user_permission}
                                            // onChange={getSenderIdInput}
                                            onChange={(user_permission) => setPermission(user_permission)}
                                            className="basic-multi-select w-4/5"
                                            classNamePrefix="select"
                                        />
                                            {/* <select className='h-10 w-40'>
                                                {userSenderIds.map((option) => (
                                                <option 
                                                    value={option.value} 
                                                    key={option.id}
                                                >
                                                    {option.sender_id}
                                                </option>
                                                ))}
                                            </select>  */}
                                            {/* <input type="text" onChange={} placeholder='Type Sender ID' className='text-gray-700 ml-2 mx-2 h-10 border px-4 file:h-10 file:w-40 hover:file:cursor-pointer hover:file:bg-white'/>   */}
                                        
                                        </div>

                                    </div>
                                   
                                    <div className='flex justify-between w-full mx-2 my-4'>
                                        <button onClick={gotoUploadFilePrevBtn} className='flex justify-center text-sm text-black bg-[#ECF3FA] w-32 h-10 items-center'>
                                            <div className='flex mx-0 px-0'>
                                                <MdKeyboardArrowLeft className=''/>
                                                <MdKeyboardArrowLeft className='mr-2'/>
                                            </div>      
                                            <p>Previous</p>
                                        </button>
                                        {/* </div>  */}
                                        <button 
                                        // disabled={disableSenderIdNextBtn} 
                                        onClick={displayMessagePage} 
                                        className='flex justify-center text-black bg-[#ECF3FA] w-24  h-10 items-center mr-4'>Next</button>
                                    </div>

                                 </div>

                            }
                            
                          
                        </div>
                        <div className='' style={{display: messageDisplay}}>
                            <div className='flex justify-between'>
                                <p className='text-sm text-gray-400 pl-2 ml-2 my-2'>Draft your message Content</p>
                                <p className='text-red-400 mr-8'>
                                    {`${messageLegnth} character(s), ${pageCount} page`}
                                </p>
                            </div>
                            <div className='md:flex w-full mt-2 mb:mt-0 md:px-3'>
                                <textarea 
                                    onChange={countMessageCharacters} 
                                    className="border w-full" 
                                    name="message" 
                                    id="" 
                                    cols="30" 
                                    rows="6"
                                />
                            </div>
                            <div className='flex justify-between w-full mx-4 my-4'>
                                <button 
                                    onClick={gotoSenderIdPrevBtn} 
                                    className='flex justify-center text-sm text-black bg-[#ECF3FA] w-32 h-10 items-center'>
                                    <div className='flex mx-0 px-0'>
                                        <MdKeyboardArrowLeft className=''/>
                                        <MdKeyboardArrowLeft className='mr-2'/>
                                    </div>      
                                    <p>Previous</p>
                                </button>
                                <button 
                                    // disabled={disableMessageNextBtn} 
                                    onClick={displayReviewPage} 
                                    className='flex justify-center bg-[#ECF3FA] w-24 mr-6 h-10 text-black items-center'>Next</button>
                            </div>
                        </div>
                        <div className='' style={{display: review}}>
                            <div className='flex flex-col'>
                                <p className='text-sm text-gray-600 pl-2 ml-2 my-1 font-light leading-relaxed'>Review Send Details</p>
                                <div className='flex mt-2'>
                                    <p className='text-sm text-red-400 pl-2 ml-2 my-1 font-light leading-relaxed'>Total Contacts Uploaded:</p>
                                    <p className='text-sm text-gray-600 pl-2 ml-2 my-1 font-light leading-relaxed'>{totalContact} contact(s)</p>
                                </div>
            
                                <div className='flex my-1 ml-2'>
                                    <p className="text-sm text-red-400 pl-2 font-light leading-relaxed">Total Duplicate(s) Removed:</p>
                                    <p className='text-sm text-gray-600 pl-2 font-light leading-relaxed'>{`${dup.length} duplicates found`}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-red-400 pl-2 ml-2 my-1 font-light leading-relaxed'>Total Contacts to Send to:</p>
                                    <p className='text-sm text-gray-600 pl-2 ml-1 my-1 font-light leading-relaxed'>{`${recipient.length} contacts`}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-red-400 pl-2 ml-2 my-1 font-light leading-relaxed'>Send When:</p>
                                    <p className='text-sm text-gray-600 pl-2 ml-1 my-1 font-light leading-relaxed'>Send Now</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-red-400 pl-2 ml-2 my-1 font-light leading-relaxed'>Credit Balance:</p>
                                    <p className='text-sm text-gray-600 pl-2 ml-1 my-1 font-light leading-relaxed'>GHS 100.00</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-red-400 pl-2 ml-2 my-1 font-light leading-relaxed'>Message Content:</p>
                                    <p className='text-sm text-gray-600 pl-2 ml-2 my-1 font-light leading-relaxed'>{message}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-red-400 pl-2 ml-2 my-1 font-light leading-relaxed'>Sender ID:</p>
                                    <p className='text-sm text-gray-600 pl-2 ml-1 my-1 font-light leading-relaxed'>{sender}</p>
                                </div>
                                <div className='flex'>
                                    <p className='text-sm text-red-400 pl-2 ml-2 my-1 font-light leading-relaxed'>Sms Cost:</p>
                                    <p className='text-sm text-gray-600 pl-2 ml-1 my-1 font-light leading-relaxed'>{price}</p>
                                </div>
                            </div>
                            <div className='flex justify-between w-full mx-4 mt-8'>
                                <button 
                                    onClick={handleReviewPreviousBtnClick} 
                                    className='flex justify-center text-sm text-black bg-[#ECF3FA] w-40 h-10 items-center'>Previous</button>
                                <button onClick={handleSubmit} 
                                    // disabled={true} 
                                    className='flex justify-center text-black bg-[#ECF3FA] w-60  h-10 items-center mr-8'>Confirm & Send SMS</button>
                            </div>    
                        </div>
                        <div className='' style={{display: conf}}>
                            <div className='flex flex-col bg-[#ECF3FA] mx-4'>
                                <div className='flex mt-4 mx-4'>
                                    <p className='text-sm mx-1 text-red-400'>
                                        {recipient.length}
                                    </p>
                                    <p className='text-sm mx-1'>messages(s) sent</p>
                                    <p className='text-sm mx-1 text-green-400'>successfully</p>

                                </div>
                                <div className='flex my-4 mx-4'>
                                    <p className='text-sm mx-1 text-red-400'>
                                        {0}
                                    </p>
                                    <p className='text-sm mx-1'>messages(s)</p>
                                    <p className='text-sm mx-1 text-red-400'>failed</p>

                                </div>
                            </div>
                            <div className='flex justify-end w-full mr-24 mt-8'>
                                <button onClick={sendNewSms} className='flex justify-center text-sm text-white bg-[#00B050] w-40 h-8 items-center mr-4'>Send New SMS</button>
                            </div>    
                        </div>
                        
                    </form>   
                </div>
            </div>
        </div>
    </div>
  )
}

export default FileUpload