import React, {useState, useEffect, useContext} from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import Wallet from './Wallet'

const Index = () => {
  return (
    <div className='flex'>
        <div className='w-1/6 bg-[#394975] h-screen text-white'>
            <Sidebar/>
        </div>
        <div className='w-5/6 bg-[#ECF3FA]'>
          <Navbar/>
          <Wallet/>
          
          
          {/* <Breadcrumbs/> */}
        </div>

    </div>
  )
}

export default Index