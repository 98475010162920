import React from 'react'
import Navbar from '../Navbar'
import Sidebar from './Sidebar'
import Crumbs from './Crumbs'

const SendBulkSms = () => {
  return (
    <div className='flex'>
        <div className='w-1/6 bg-[#394975] h-screen text-white'>
            <Sidebar/>
        </div>
        <div className='w-5/6 bg-[#ECF3FA]'>
          <Navbar/>
          <Crumbs/>
        </div>


    </div>
  )
}

export default SendBulkSms