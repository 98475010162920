export const SET_LOADING = 'SET_LOADING';
export const GET_ROLE = 'GET_ROLE';
export const GET_ROLE_ONLY = 'GET_ROLE_ONLY';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_SINGLE_USER = 'GET_SINGLE_USER';
export const GET_USER_PERMISSIONS_AND_ROLES = 'GET_USER_PERMISSIONS_AND_ROLES';
export const GET_ADMIN_PERMISSIONS_AND_ROLES = 'GET_ADMIN_PERMISSIONS_AND_ROLES';
export const GET_ALL_ROLES = 'GET_ALL_ROLES';
export const ADMIN_CREATE_USER = 'ADMIN_CREATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const SET_ROLES_LOADING_OFF = 'SET_ROLES_LOADING_OFF';
export const SET_LOADING_OFF = 'SET_LOADING_OFF';
export const ADD_NEW_ROLE = 'ADD_NEW_ROLE';
export const ADD_NEW_PERMISSION = 'ADD_NEW_PERMISSION';
export const GET_ROLE_PERMISSION_BY_ID = 'GET_ROLE_PERMISSION_BY_ID';
export const GET_ALL_PERMISSIONS = 'GET_ALL_PERMISSIONS';
export const CPM_CREATE_USER = 'CPM_CREATE_USER';
export const GET_LOGGED_IN_USER_PERMISSIONS = 'GET_LOGGED_IN_USER_PERMISSIONS';

//COLLABORATIONS
export const GET_ALL_COLLABORATORS = 'GET_ALL_COLLABORATORS';
export const SET_COLLABORATION_LOADING = 'SET_COLLABORATION_LOADING';
export const SET_COLLABORATION_LOADING_OFF = 'SET_COLLABORATION_LOADING_OFF';


export const ADD_SENDER_IDS   = 'ADD_SENDER_IDS';
export const GET_ALL_SENDER_IDS   = 'GET_ALL_SENDER_IDS';
export const SET_SENDER_IDS_LOADING_OFF = 'SET_SENDER_IDS_LOADING_OFF';
export const GET_SENDER_ID_BY_ID = 'GET_SENDER_ID_BY_ID';
export const GET_ALL_USER_MAILS = 'GET_ALL_USER_MAILS';
export const SEARCH_USER = 'SEARCH_USER';
export const SEARCH_ROLE = 'SEARCH_ROLE';
export const PERMISSION_SEARCH = 'PERMISSION_SEARCH';
export const SEARCH_SENDER_ID = 'SEARCH_SENDER_ID';
export const GET_USER_SENDER_ID_BY_EMAIL = 'GET_USER_SENDER_ID_BY_EMAIL';

export const SEARCH_PERMISSION = 'SEARCH_PERMISSION';

export const FETCH_TOTAL_DELIVERED_SMS_FROM_API = 'FETCH_TOTAL_DELIVERED_SMS_FROM_API';
export const FETCH_TOTAL_UNDELIVERED_SMS_FROM_API = 'FETCH_TOTAL_UNDELIVERED_SMS_FROM_API';
export const TOTAL_SUBMITTED = 'TOTAL_SUBMITTED';
export const TOTAL_SUBMITTED_BY_DATE = 'TOTAL_SUBMITTED_BY_DATE';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const FETCH_USER_PAYMENT_HISTORY = 'FETCH_USER_PAYMENT_HISTORY';





export const user_token = "4|qOo63Wq8DlST8UXqR2W9En7YznCMWxIyNyyp7yd6";
