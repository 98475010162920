import React, {useState, useContext} from 'react'
import {BsPencilSquare, BsTrash} from 'react-icons/bs'
import ConfirmDeletion from './ConfirmDeletion'
import EditPermission from './EditPermission';
import GithubContext from '../../../context/user/userContext';
import UnauthorisedAccess from '../../pages/UnauthorisedAccess';

const Permissions = ({permission, deletePermission, updatePermission}) => {
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [isEditing, setIsEditing] = useState(false);
    const [unauthorisedUser, setUnauthorisedUser] = useState(false);

    const githubContext = useContext(GithubContext);
    const { getRole, getUsersRoleAlongWithPermissions, permissions  } = githubContext;
    
    const user = localStorage.getItem("user");
    const parseUser = JSON.parse(user);
    const id = parseUser.id

    const arr = [];

    const formNewArrayFromUserPermissionContext = () =>{
      permissions.map((ed)=>{
        return ed.permissions.map((edd)=>{
          return arr.push(edd.name)
  
        })
  
      })

    }
    formNewArrayFromUserPermissionContext()  

    const handleCancle = () => {
        setConfirmDelete(!ConfirmDeletion);

    }

    const cancelEditForm = () =>{
        setIsEditing(false)


    }

    const deleteBtnClicked = () => {
        getRole(id);   
        getUsersRoleAlongWithPermissions()
        if (arr.includes('can_delete_permissions')) {
            setConfirmDelete(true)                    
        }
        else{
            setUnauthorisedUser(!unauthorisedUser)        
        }
    }

    const EditBtnClicked = () => {
        getRole(id);   
        getUsersRoleAlongWithPermissions()
        if (arr.includes('can_edit_permissions')) {
            setIsEditing(true)                    
        }
        else{
            setUnauthorisedUser(!unauthorisedUser)        
        }
    }

    const okayBtnClicked = () =>  {
        setUnauthorisedUser(!unauthorisedUser)        

    }


  return (
        <tr>
            <td className='text-center border-b border-t-2 text-sm text-gray-500'>
                <p className='text-center text-gray-500 text-sm py-2'>{permission.name}</p>
            </td>
            <td className="text-center border-t-2 px-5 py-3 border-b border-gray-200 bg-white text-sm">
                <button
                    onClick={EditBtnClicked}
                    className="text-center flex text-green-500">
                    <BsPencilSquare className='mx-2'/>
                    <p>Edit</p>                                    
                </button>
                <button onClick={deleteBtnClicked} className="flex justify-center items-center text-red-500">
                    <BsTrash className='mx-2'/>
                    <p>Delete</p>                                    
                </button>
            </td>
            <td>
            {
                confirmDelete ? <ConfirmDeletion text = "permission" permission={ permission } deletePermission = {deletePermission} handleCancle={handleCancle}/> : null
            }

            </td>
            

            <td>
            {
                isEditing ? <EditPermission permission={permission} cancelEditForm={cancelEditForm} updatePermission={updatePermission}/> : null
            }
            </td>

            <td>
            {
                unauthorisedUser ? <UnauthorisedAccess okayBtnClicked={okayBtnClicked}/> : null
            }
            </td>

        </tr>
  )
}

export default Permissions