import React,{useState} from 'react'
import { useLocation } from 'react-router-dom';
import Spinner from '../../layout/Spinner'
import {FaTimes} from 'react-icons/fa'
import {BsFillInfoCircleFill} from 'react-icons/bs'


const Momo = ({gotoPendingPaymentModel, cancelMomoPaymentModel}) => {
  const user = localStorage.getItem("user");

  const parseUser = JSON.parse(user);
  const emailFromToken = parseUser.email
  const id = parseUser.id
  const billing_currency = parseUser.billing_currency


  const [email, setemail] = useState(emailFromToken)
  const [inputAmount, setInputAmount] = useState('')
  const [isAmountEmpty, setisAmountEmpty] = useState('1px gray solid')
  const [isEmailEmpty, setIsEmailEmpty] = useState('1px gray solid')

  const onemailChange = (e) => {
    const inpEmail = e.target.value
    setemail(inpEmail)
  }


  async function makeRequestToPaystackTest(credentials) {
    return fetch('https://api.paystack.co/transaction/initialize', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer sk_test_839dc75c138c67ed9f684cb21dca27c9f119db8f`,
        // 'Authorization': `Bearer sk_live_19d639296e60069cef35e2f2289f7132945003f6`,
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
  }

   async function makeRequestToCurrencyConverterApi() {
    return fetch('https://api.currencyapi.com/v3/latest', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'apikey': `2L2pY4MH6Gucga2dt4LitMAjEJdrXZkZ27ptnbnH`,
        // 'Authorization': `Bearer sk_live_19d639296e60069cef35e2f2289f7132945003f6`,
      },
    })
      .then(data => data.json())
  }


  
  const handleSubmit = async (e) => {
    e.preventDefault()
    const convertedAmount = (inputAmount * 100)
    const callback_url = `https://apis.mutani.co/callback/${id}`

    if (inputAmount == '') {
      setisAmountEmpty('1px solid red')

      return setTimeout(() => {
        setisAmountEmpty('1px solid gray')
      }, 3000);      
    }else if (email == '') {
      setIsEmailEmpty('1px solid red')

      return setTimeout(() => {
        setIsEmailEmpty('1px solid gray')
      }, 3000); 
    }


    if (billing_currency === 'USD') {
      const res = await makeRequestToCurrencyConverterApi();


      const amount = Math.round(res.data.GHS.value * convertedAmount)
  

      const response = await makeRequestToPaystackTest({
        amount,
        email,
        callback_url
      });

      if ('data' in response) {
          gotoPendingPaymentModel()
          return window.open(`${response.data.authorization_url}`, "_blank");
        
      }
      
    }else  if (billing_currency === 'GBP') {
      const res = await makeRequestToCurrencyConverterApi();


      const amount = Math.round(res.data.GHS.value * convertedAmount)
  

      const response = await makeRequestToPaystackTest({
        amount,
        email,
        callback_url
      });

      if ('data' in response) {
          gotoPendingPaymentModel()
          return window.open(`${response.data.authorization_url}`, "_blank");
        
      }
      
    }else{
      const amount = convertedAmount;

      const response = await makeRequestToPaystackTest({
        amount,
        email,
        callback_url
      });
  
      if ('data' in response) {
          gotoPendingPaymentModel()
          window.open(`${response.data.authorization_url}`, "_blank");
        
      }
    }
  }
  return (
    <>
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto my-6 mx-auto max-w-3xl">
        {/*content*/}
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex items-start justify-between p-5 border-b border-solid border-slate-300 rounded-t">
            <div></div>
            <h3 className="text-2xl justify-center text-gray-500 font-semibold">
              Make Payment
            </h3>
            <div 
              onClick={cancelMomoPaymentModel} 
              className="flex items-center justify-center h-8 hover:cursor-pointer text-3xl font-semibold">
              <FaTimes className='flex justify-center items-center fill-gray-500 text-xl'/>
            </div>
          </div>
          {/*body*/}
          <div className="relative flex-auto">
            <form 
              onSubmit={handleSubmit}
            >
                <div className="flex flex-col  mx-6 h-56">
                      <div className='flex flex-col justify-center w-full mr-1 '>
                        <label htmlFor="first_name" className="text-sm my-2">
                          Amount
                        </label>
                        <input
                        type="number"
                        name="first_name"
                        placeholder=''
                        onChange={(e) => setInputAmount(e.target.value)}
                        required
                        className="w-full h-10 px-2"
                        style={{border: isAmountEmpty}}
                        />
                      </div>
                      <div className='flex flex-col justify-center w-full mr-1 my-2'>
                        <label htmlFor="first_name" className="text-sm my-2">
                          Enter email for payment receipt
                        </label>
                        <input
                        type="text"
                        name="email"
                        placeholder=''
                        value={email}
                        onChange={onemailChange}
                        required
                        style={{border: isEmailEmpty}}
                        className="border-gray-300 border w-full h-10 px-2"
                        />
                      </div>   
                </div>
            </form>
          </div>
          <div className="flex flex-col items-center justify-center p-6 border-t border-solid border-slate-300 rounded-b">
            <button
              className="bg-[#00B050] text-white w-96 font-bold uppercase text-sm my-4 px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={handleSubmit}
            >
              Complete Payment
            </button>
            <p className='text-sm'>An additional E-levy fee of 1% may apply to this payment. Learn more</p>

            
          </div>
          <div className="flex text-sm items-center justify-center p-6 border-t border-solid border-slate-300 rounded-b">
            <div>
              <BsFillInfoCircleFill className='text-4xl fill-red-600'/>
            </div>
            <div className='text-xs mx-8'>
              <p>Note that when you ‘you will be redirected to our </p> 
              <p>payment gateway provider (Paystack  - a Stripe </p>  
              <p>Company) to complete your payment. Complete to </p>
              <p> this payment when payment is completed. </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  )
}

export default Momo