import React from 'react'
import { Link } from "react-router-dom";
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import SmsCredit from '../SmsCredit';

const SendingType = () => {

  return (
    <div>
        <div className=' bg-white mx-8 h-[36rem] mb-4'>
            <SmsCredit activity='Send Sms'/>
            <ul className='flex items-center h-12 ml-12 font-bold text-black'>
            <li className='px-1'>
                <Link to='/dashboard'>My Workspace</Link>
            </li>
            <MdOutlineKeyboardArrowRight/>
            <li className='px-1'>
                <Link to='/bam'>BAM</Link>
            </li>
            <MdOutlineKeyboardArrowRight/>
            <li className='px-1'>
                <Link to='/bam/sendsms'>Send SMS</Link>
                </li>
            </ul>
             
           
            <div className='flex items-center justify-center mx-8'>
                <div className='flex w-full my-2'>
                    <div className='flex w-1/2'>
                        <Link to="/bam/sendsms/excel" className='flex flex-col w-full mx-4 h-48 border-4 border-gray-300'>
                            <div className='flex justify-between h-2 my-6 w-full'>
                                <p className='flex justify-start'></p>
                                <button disabled={true} className='flex justify-end bg-green-700 h-2 w-6 mr-4'></button>
                            </div>
                            <div className='flex flex-col justify-center items-center'>
                                <p className='flex items-center justify-center text-2xl bold text-gray-700'>Send Simple SMS</p>
                                <p className='flex items-center justify-center text-2xl bold text-gray-700'>From Excel File</p>
                            </div>   
                        </Link>
                    </div>
                    <div className='flex w-1/2'>
                        <Link to="" className='flex flex-col w-full mx-4 h-48 border-4 border-gray-300'>
                            <div className='flex justify-between h-2 my-6 w-full'>
                                <p className='flex justify-start'></p>
                                <button disabled={true} className='flex justify-end bg-black h-2 w-6 mr-4'></button>
                            </div>
                            <div className='flex flex-col justify-center items-center'>
                                <p className='flex items-center justify-center text-2xl bold text-gray-700'>Send Personalised SMS</p>
                                <p className='flex items-center justify-center text-2xl bold text-gray-700'>From Excel File</p>
                            </div>   
                        </Link>
                    </div>
                </div>
            </div>
            <div className='flex items-center justify-center mx-8'>
                <div className='flex w-full my-2'>
                    <div className='flex w-1/2'>
                        <Link to="" className='flex flex-col w-full mx-4 h-48 border-4 border-gray-300'>
                            <div className='flex justify-between h-2 my-6 w-full'>
                                <p className='flex justify-start'></p>
                                <button disabled={true} className='flex justify-end bg-black h-2 w-6 mr-4'></button>
                            </div>
                            <div className='flex flex-col justify-center items-center'>
                                <p className='flex items-center justify-center text-2xl bold text-gray-700'>Send Simple SMS</p>
                                <p className='flex items-center justify-center text-2xl bold text-gray-700'>From Address Book</p>
                            </div>   
                        </Link>
                    </div>
                    <div className='flex w-1/2'>
                        <Link to="" className='flex flex-col w-full mx-4 h-48 border-4 border-gray-300'>
                            <div className='flex justify-between h-2 my-6 w-full'>
                                <p className='flex justify-start'></p>
                                <button disabled={true} className='flex justify-end bg-black h-2 w-6 mr-4'></button>
                            </div>
                            <div className='flex flex-col justify-center items-center'>
                                <p className='flex items-center justify-center text-2xl bold text-gray-700'>Send Personalised SMS</p>
                                <p className='flex items-center justify-center text-2xl bold text-gray-700'>From Address Book</p>
                            </div>   
                        </Link>
                    </div>
                </div>
            </div>
    </div>
    </div>
  )
}

export default SendingType