import React,{useContext, useEffect} from 'react'
import ReportContext from '../../../context/reports/reportContext'

const TwentyFourHrsRrp = () => {
    const reportContext = useContext(ReportContext);
    const { 
        totalSmsDelivered,
        totalSmsUnDelivered,
        totalSubmitted,
        delivered,
        undelivered,
        submitted
    } 
    = reportContext;


    useEffect(() => {
        totalSmsDelivered()
        totalSmsUnDelivered()
        totalSubmitted()

    }, [delivered])

    console.log(submitted);
    

  return (
    <div className=' bg-white mx-12 md:h-64'>
        <div className=' border-4 border-gray-100 mx-16'>
            <div className="h-8 ml-10 py-4 text-black font-bold">
                My SMS Activity Summary - 
            </div> 
            <div className='block md:flex items-center justify-center h-full md:h-40 mx-4 py-4 uppercase text-white'>
                <div className='flex w-full h-28 md:h-24 sm:h-20 my-2 font-roboto'>
                        <div className='flex md:text-sm flex-col justify-center items-center w-1/2 bg-[#00b0f0] ml-4'>
                        <p>Submitted</p>
                        <p>{submitted}</p>
                    </div>
                    <div className='flex md:text-sm flex-col justify-center items-center w-1/2 bg-[#00b050] mx-4'>
                        <p>Delivered</p>
                        {delivered}
                    </div>                  
                </div>
                <div className='flex w-full h-28 md:h-24 sm:h-20 my-2 font-roboto'>
                    <div className='flex md:text-sm flex-col justify-center items-center w-1/2 bg-[#FFC000]'>
                        <p>Pending</p>
                        <p>100,000</p>
                    </div>
                    <div className='flex md:text-sm flex-col justify-center items-center w-1/2 bg-[#FF0000] mx-4'>
                        <p>Failed</p>
                        <p>{undelivered}</p>
                    </div>                  
                </div>
            </div>

        </div>
       
    </div>
  )
}

export default TwentyFourHrsRrp