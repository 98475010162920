import React, {useEffect, useState} from 'react'
import Select from "react-select";

const EditRole = ({role, cancelEditRoleForm, allPermissions, updateRole}) => {
  const [name, setName] = useState(role.name)
  const [newRole, setnewRole] = useState([""])

  // const [user_permission, setPermission] = useState(newRole)
  const [selectPermission, setSelectPermission] = useState([""])


  const handlePermissionUpdate = async (e) => {
    e.preventDefault()

    const permission = [];
    const guard_name = "web"

    newRole.map((en) => {
      return permission.push(en.value);
    });
    await updateRole({name, permission, guard_name}, role.id)
}

useEffect(() => {
  const newPerm = []


  formNewArrayFromUserPermissionContext()
  const formNewArrayFromUserPermission = () =>{
    role.permissions.map((role)=>{
      return newPerm.push({value: role.name, label: role.name});
    })
    setnewRole(newPerm);
  }

  formNewArrayFromUserPermission();


}, [role]);


  const formNewArrayFromUserPermissionContext = () =>{
    const arr = [];
    allPermissions.map((role)=>{
      return arr.push({value: role.name, label: role.name});
    })
    setSelectPermission(arr);
}



  return (
    <>
    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-6xl">
          <form  className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-center justify-center p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">
                Edit {role.name} Role
              </h3>
            </div>
            <div className="relative p-6 flex-auto">
              <div className=" text-slate-500 text-lg leading-relaxed h-[30rem] w-[50rem]">
                <div className="">
                        <label htmlFor="email" className="flex ml-[4rem]">
                            Role Name <p className='text-red-500'> * </p> 
                        </label>
                        <div className="flex justify-center items-center mt-1">
                        <input
                            type="text"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            className="border-gray-300 border rounded w-4/5 h-10 mx-2"
                            placeholder='Add New Permission'
                        />
                    </div>
                </div>
                <div className="flex flex-col w-full my-2">
                    <label htmlFor="role" className="flex mx-2 ml-[5rem] my-1 text-lg">
                        Permissions <p className='text-red-500'> * </p> 
                    </label>
                    <div className="flex flex-col w-full items-center h-52 overflow-y-auto">
                    <Select
                        options={selectPermission}
                        // defaultValue ={newRole}
                        // defaultValue = {[
                        //   { value: 'can_manage_this', label: 'can_manage_this' },
                        //   { value: 'can_manage_this_show', label: "can_manage_this_show" }
                                      //]}
                        placeholder="Select User Permissions"
                        isMulti
                        value={newRole}
                        onChange={(newRole) => setnewRole(newRole)}
                        className="basic-multi-select w-4/5 text-sm"
                        classNamePrefix="select"
                    />
                    
                    </div>
                </div>
                <div className="flex items-center justify-between mx-16 p-6 ">
                </div>
            </div>
            </div>
            <div className="flex items-center justify-between p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                    className="bg-[#ffc000] active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 mx-8"
                    type="button"
                    onClick={cancelEditRoleForm}
                >
                Cancel
                </button>
              <button
                className="bg-emerald-500 mr-8  text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                type="button"
                // onClick={() => {
                //     updatePermission(permission.id)
                // }}

                onClick={handlePermissionUpdate}
                >
                Update {role.name}'s Role
                </button>
            </div>
          </form>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </> 
  )
}

export default EditRole