import React, {useEffect, useState, useContext} from 'react';
import GithubContext from '../../context/user/userContext';
import { Link } from 'react-router-dom'
import SmsCredit from '../SmsCredit';


const Navigations = ({currentUserPermission}) => {
    // const githubContext = useContext(GithubContext);
    // const { getRole, loading, getUsersRoleAlongWithPermissions, permissions  } = githubContext;
    
    // const user = localStorage.getItem("user");
    // const parseUser = JSON.parse(user);
    // const id = parseUser.id

    // const arr = [];

    // const formNewArrayFromUserPermissionContext = () =>{
    //   permissions.map((ed)=>{
    //     ed.permissions.map((edd)=>{
    //       arr.push(edd.name)
  
    //     })
  
    //   })

    // }
    // formNewArrayFromUserPermissionContext()  

    // useEffect(() => {
    //     getRole(id);   
    //     getUsersRoleAlongWithPermissions()

    // }, [id]);

    // if (loading) return <div>Loading</div>

  return (
    <nav className=''>
        <SmsCredit activity='User Management'/>
        <ul className='flex justify-start items-center my-4 ml-2'>
          <Link to="/manage/team">
            <li className='flex justify-center items-center mx-2 h-16 w-32 px-4 bg-[#ced1db]'>Profile</li>
          </Link>
            {
              currentUserPermission.includes('can_read_collaborators') &&
              <Link to="/usermanager/collaboration">
                <li className='flex justify-center items-center mx-2 h-16 w-32 px-4'>Collaboration</li>
              </Link>

            }
            <li className='flex justify-center items-center mx-2 h-16 w-32 px-4'><Link to="#">Preferences</Link></li>
            <li className='flex justify-center items-center mx-2 h-16 w-32 px-4'><Link to="#">Apps</Link></li>
            <li className='flex justify-center items-center mx-2 h-16 w-50 px-4'><Link to="#">Payments & Biling</Link></li>
            <li className='flex justify-center items-center mx-2 h-16 w-32 px-4'><Link to="#">API Keys</Link></li>
            <Link to="/usermanager/csmanager">
              <li className='flex justify-center items-center mx-2 h-16 w-32 px-4'>Customer Success</li>
            </Link>
            <Link to="/usermanager/csmanager">
              <li className='flex justify-center items-center mx-2 h-16 w-32 px-4'>Terms & Privacy</li>
            </Link>
        </ul>
    </nav>
  )
}

export default Navigations