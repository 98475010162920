import React from 'react'
import {FaTimes} from 'react-icons/fa'

const Success = ({cancelSuccessCardPayment}) => {
  return (
    <>
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto my-6 mx-auto max-w-4xl">
        {/*content*/}
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex items-start justify-between p-5 border-b  border-solid border-slate-300 rounded-t ">
            <div></div>
            <h3 className="text-2xl justify-center text-gray-500 font-semibold">
              Card Payment
            </h3>
            <div 
            onClick={cancelSuccessCardPayment} 
            className="flex items-center justify-center h-8 hover:cursor-pointer text-3xl font-semibold">
              <FaTimes className='flex justify-center items-center fill-gray-500 text-xl'/>
            </div>
          </div>
          {/*body*/}
          <div className="relative p-6 flex-auto border-b mb-10 w-[30rem]">
            <p className='my-2'>80 USD <span className='text-[#00B050]'>successfully</span>  added to your wallet</p>
            <p>Current wallet balance: 200 USD</p>
            <p className='my-2'>Previous wallet balance: 120 USD</p>
          </div>
          {/*footer*/}
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
</>  )
}

export default Success