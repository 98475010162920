import React from 'react'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { Link } from "react-router-dom";
import Sendsms from './Sendsms';

const Breadcrumbs = () => {
  return (
    <div className='flex flex-col mx-6 py-2 bg-[#ECF3FA]'>    
        <Sendsms/>
    </div>
  )
}

export default Breadcrumbs