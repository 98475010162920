import React,{useState} from 'react'
import Button from '../Button'
import Card from './CardPayment/Card'
import Success from './CardPayment/Success'
import Momo from './Momo/Momo'
import OtpVerification from './Momo/OtpVerification'
import PendingPayment from './Momo/PendingPayment'
import SmsCredit from '../SmsCredit'
import { Link, useNavigate  } from "react-router-dom";

const PaymentType = () => {
    const navigate = useNavigate()

    const [cardPaymentSuccess, setCardPaymentSuccess] = useState(false)
    const [MomoPayment, setMomoPayment] = useState(false)
    const [BankPayment, setBankPayment] = useState(false)
    const [cardPayment, setCardPayment] = useState(false)

    const [gotoOtpVerification, setGotoOtpVerification] = useState(false)
    const [gotPendingPayment, setgotPendingPayment] = useState(false)
    const [gotoVodafonePendingPayment, setGotoVodafonePendingPayment] = useState(false)

    
    const gotoCardPaymentModel = () => {
        setCardPaymentSuccess(!cardPaymentSuccess)
    }

    const gotoMomoPaymentModel = () => {
        setMomoPayment(!MomoPayment)
    }

    const gotoPendingPaymentModel = () => {
        setMomoPayment(false)
        setgotPendingPayment(true)
    }

    const gotoVodafonePendingPaymentModel = () => {
        setMomoPayment(false)
        setGotoVodafonePendingPayment(true)
    }


    const cancelMomoPaymentModel = () => {
        setMomoPayment(!MomoPayment)
    }

    const cancelPendingPayment = () => {
        setgotPendingPayment(false)
    }

    

    const otpVerify = () => {
        setMomoPayment(false)
        setGotoOtpVerification(true)
    }

    const cancelotpVerify = () => {
        setGotoOtpVerification(false)
    }

    

    const cancelCardPaymentModel = () => {
        setCardPayment(!cardPayment)
    }

    const cancelSuccessCardPayment = () => {
        setCardPaymentSuccess(false)
    }

    const handleSubmit =()=>{
        setCardPayment(!cardPayment)
        setCardPaymentSuccess(true)

    }

    const gotoPaymentHistory = () =>{
        navigate('/payment/history',{replace: true})
    }
    

  return (
    <div className='flex flex-col mx-6 py-2 bg-[#ECF3FA]'>    
        <div className=' bg-white mx-8 h-[30rem] my-4 font-roboto'>
        <SmsCredit activity='Billing & Payments Manager'/>
            <div className='flex ml-10 text-black mt-6'>
            <Button 
                // clickToMakeButtonActive={clickToMakefileUploadActive} 
                className='' text='Add Funds' 
                color={'#ECF3FA'}/>
            <Button 
                clickToMakeButtonActive={gotoPaymentHistory} 
                className=''
                text='Payment History' 
                color={''}/>
            <Button 
                // clickToMakeButtonActive={clickToMakeMessageActive} 
                className='' 
                text='Payment Method' 
                color={''}/>
            <Button 
                // clickToMakeButtonActive={clickToMakeReviewActive} 
                className='' text='Low Balance Alert' 
                color={''}/>
            </div> 
            <div className='border-2 border-gray-200 h-56 mt-4 mx-10'>
                <div className='flex justify-center items-center h-48 w-4/5'>
                    <div onClick={gotoCardPaymentModel} className='flex hover:cursor-pointer flex-col justify-center items-center w-1/3'>
                        <img
                            src={process.env.PUBLIC_URL + `/images/cards.png`}
                            alt=''
                            className='h-24 w-24'
                            id='co_founder'
                        /> 
                        <p className='mt-2'>Card Payment</p>

                    </div>
                    <div onClick={gotoMomoPaymentModel} className='flex flex-col justify-center items-center hover:cursor-pointer w-1/3'>
                        <img
                            src={process.env.PUBLIC_URL + `/images/momo.png`}
                            alt=''
                            className='h-24 w-24'
                            id='co_founder'
                        /> 
                        <p className='mt-2'>Mobile Money</p>
                    </div>
                    <div className='flex flex-col justify-center items-center hover:cursor-pointer w-1/3'>
                        <img
                            src={process.env.PUBLIC_URL + `/images/bank.png`}
                            alt=''
                            className='h-24 w-24'
                            id='co_founder'
                        /> 
                        <p className='mt-2'>Bank Payment</p>

                    </div>

                </div>

            </div>
        </div>
        {
            cardPayment && <Card cancelCardPaymentModel={cancelCardPaymentModel} handleSubmit={handleSubmit}/>
        }
        {
            MomoPayment && <Momo gotoVodafonePendingPaymentModel={gotoVodafonePendingPaymentModel} gotoPendingPaymentModel={gotoPendingPaymentModel} cancelMomoPaymentModel={cancelMomoPaymentModel} handleSubmit={handleSubmit} otpVerify={otpVerify}/>
        }
        {
            cardPaymentSuccess && <Success cancelSuccessCardPayment={cancelSuccessCardPayment}/>
        }
        {
            gotoOtpVerification && <OtpVerification otpVerify={otpVerify} cancelotpVerify={cancelotpVerify}/>
        }

        {
            gotPendingPayment && <PendingPayment cancelPendingPayment={cancelPendingPayment}/>
        }
    </div>  
    )
}

export default PaymentType