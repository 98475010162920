import React from 'react'
import { Link } from 'react-router-dom'

const Nav = () => {
  return (
    <>
    <div className='flex justify-between bg-white py-2  border-b'>
          <div className="justify-start h-8 ml-6 mb-2 py-4 text-mutani text-xl font-bold">
            User Management
          </div>
          <div className='flex justify-end mr-2 my-4'>
            <div className='flex justify-center items-center mr-4 space-x-4'>
              <p className='text-[#757575]'>SMS Wallet Balance</p>
              <span className='bg-[#00B050] h-8 w-16 flex justify-center items-center rounded'>655</span>
              <button className='bg-[#ffc000] h-8 w-16 flex justify-center items-center rounded'>Top Up</button>
            </div>
          </div>
        </div>
    <nav className='ml-2'>
      <ul className='flex justify-start items-center my-2'>
          {/* <li className='flex justify-center items-center mx-2 h-16 w-32 px-4'><Link to="/usermanager">Users</Link></li> */}
          <li className='flex justify-center items-center mx-2 h-10 w-32 px-4 bg-[#ECF3FA]'><Link to="/usermanager/team">Users</Link></li>
          <li className='flex justify-center items-center mx-2 h-16 w-32 px-4'><Link to="/usermanager/roles">Roles</Link></li>
          <li className='flex justify-center items-center mx-2 h-16 w-32 px-4'><Link to="/usermanager/permissions">Permissions</Link></li>
          <li className='flex justify-center items-center mx-2 h-16 w-32 px-4'><Link to="/usermanager/senderids">Sender IDs</Link></li>

      </ul>
    </nav> 
    </>
     )
}

export default Nav