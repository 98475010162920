import React from 'react'

const OperationSuccess = () => {
  return (
    <div class="card">
      <div className='flex'>
        <i class="checkmark">✓</i>
      </div>
        <h1>Success</h1> 
        <p>We received your purchase request;<br/> we'll be in touch shortly!</p>
      </div>  
      )
}

export default OperationSuccess