import React from 'react'
import Activities from '../Activities'
import SmsCredit from '../SmsCredit'

const Wallet = () => {
  return (
        <div className=' bg-white mx-8 h-[44rem] mb-4'>
            <div className='flex justify-between items-center bg-white mb-4 border-b'>
              <div>
                <p className='flex justify-start ml-10 my-4 '>BiRD</p>
                <p className='flex justify-start ml-10 my-4 text-sm'>Business Insight & Reporting Dashboard</p>

              </div>
                <SmsCredit/>
            </div>
            <div>
                <ul className='flex w-full'>
                    <li className='w-1/4 pl-16'>SMS Delivery</li>
                    <li className='w-1/4'>Campaign Engagement</li>
                    <li className='w-1/4'>Platform Perfomance</li>
                    <li className='w-1/4'>Billing Reports</li>
                </ul>
          </div>
          <div className='my-8'>
            <Activities/>

          </div>
        </div>  
        )
}

export default Wallet