import React from 'react'
import Button from '../Button'
import SmsCredit from '../SmsCredit'
import { Link, useNavigate  } from "react-router-dom";

const PaymentSuccess = () => {
    const navigate = useNavigate()

    const gotoPaymentHistory = () =>{
        navigate('/payment/history',{replace: true})
    }

    const gotoAddFunds = () =>{
        navigate('/payment',{replace: true})
    }
  return (
<div className='flex flex-col mx-6 py-2 bg-[#ECF3FA]'>    
        <div className=' bg-white mx-8 h-[30rem] my-4 font-roboto'>
        <SmsCredit activity='Billing & Payments Manager'/>
            <div className='flex ml-10 text-black mt-6'>
            <Button 
                clickToMakeButtonActive={gotoAddFunds} 
                className='' text='Add Funds' 
                color={'#ECF3FA'}/>
            <Button 
                clickToMakeButtonActive={gotoPaymentHistory} 
                className=''
                text='Payment History' 
                color={''}/>
            <Button 
                // clickToMakeButtonActive={clickToMakeMessageActive} 
                className='' 
                text='Payment Method' 
                color={''}/>
            <Button 
                // clickToMakeButtonActive={clickToMakeReviewActive} 
                className='' text='Low Balance Alert' 
                color={''}/>
            </div> 
            <div className='border-2 border-gray-200 mt-4 mx-10'>
                <div className='flex justify-center items-center h-64 w-full'>
                    <div className='flex flex-col justify-center pl-8 h-48 w-4/5 bg-[#ECF3FA]'>
                        <h3 className='text-green-500 text-2xl font-bold'>Payment Successful</h3>
                        <p className='my-2'>
                            80  <span className='text-green-500'>USD</span> successfully added to your wallet
                        </p>
                        <p>
                            Current wallet balance: 200 USD
                        </p>
                        <p className='my-2'>
                            Previous wallet balance: 120 USD
                        </p>
                    </div>

                </div>

            </div>
        </div>
        
    </div>   )
}

export default PaymentSuccess