
export const momoProviders = [
  { value: 'V', label: 'Primary Account Owner' },
  { value: 'Secondary Account Owner', label: 'Secondary Account Owner' },
  { value: 'Portal Manager', label: 'Portal Manager' },
  { value: 'Advance Portal Manager', label: 'Advance Portal Manager' },
  { value: 'Send Sms Manager', label: 'Send Sms Manager' },
  { value: 'Sms Status Manager', label: 'Sms Status Manager' },
  { value: 'Campaign Engagement Viewer', label: 'Campaign Engagement Viewer' },
  { value: 'Corporate Portal Manager', label: 'Corporate Portal Manager' },
  { value: "Platform Performance Viewer", label: "Platform Performance Viewer" }

];
 
 export const colourOptions = [
    { value: 'Admin', label: 'Admin' },
    { value: 'Account Owner', label: 'Account Owner' },
    { value: 'Portal Manager', label: 'Portal Manager' },
    { value: 'Advance Portal Manager', label: 'Advance Portal Manager' },
    { value: 'Send Sms Manager', label: 'Send Sms Manager' },
    { value: 'Sms Status Manager', label: 'Sms Status Manager' },
    { value: 'Campaign Engagement Viewer', label: 'Campaign Engagement Viewer' },
    { value: 'Corporate Portal Manager', label: 'Corporate Portal Manager' },
    { value: "Platform Performance Viewer", label: "Platform Performance Viewer" }
  ];
  

  export const Groups = [
    { value: 'Admin', label: 'Admin' },
    { value: 'SMPP Registered', label: 'Account Owner' },
    { value: 'Self-Registered', label: 'Self-Registered' },
    { value: 'Reseller', label: 'Reseller' },
    { value: 'Support Team', label: 'Support Team' },
    { value: 'API Registered', label: 'API Registered' },
    { value: 'Sales and Customer Support', label: 'Sales and Customer Support' },
    { value: 'Corporate Portal Manager', label: 'Corporate Portal Manager' },
    { value: "CPM Registered User", label: "CPM Registered User" }
  
  ];

  export const UserRoles = [
    { value: 'Primary Account Owner', label: 'Primary Account Owner' },
    { value: 'Secondary Account Owner', label: 'Secondary Account Owner' },
    { value: 'Portal Manager', label: 'Portal Manager' },
    { value: 'Advance Portal Manager', label: 'Advance Portal Manager' },
    { value: 'Send Sms Manager', label: 'Send Sms Manager' },
    { value: 'Sms Status Manager', label: 'Sms Status Manager' },
    { value: 'Campaign Engagement Viewer', label: 'Campaign Engagement Viewer' },
    { value: 'Corporate Portal Manager', label: 'Corporate Portal Manager' },
    { value: "Platform Performance Viewer", label: "Platform Performance Viewer" }

  ];

  export  const Currencies = [
    {value:'AED', label: 'AED'},
    {value:'AFN', label: 'AFN'},
    {value:'ALL', label: 'ALL'},
    {value:'AMD', label: 'AMD'},
    {value:'ANG', label: 'ANG'},
    {value:'AOA', label: 'AOA'},
    {value:'ARS', label: 'ARS'},
    {value:'AUD', label: 'AUD'},
    {value:'AWG', label: 'AWG'},
    {value:'AZN', label: 'AZN'},
    {value:'BAM', label: 'BAM'},
    {value:'BBD', label: 'BBD'},
    {value:'BDT', label: 'BDT'},
    {value:'BGN', label: 'BGN'},
    {value:'BHD', label: 'BHD'},
    {value:'BIF', label: 'BIF'},
    {value:'BMD', label: 'BMD'},
    {value:'BND', label: 'BND'},
    {value:'BOB', label: 'BOB'},
    {value:'BRL', label: 'BRL'},
    {value:'BSD', label: 'BSD'},
    {value:'BTC', label: 'BTC'},
    {value:'BTN', label: 'BTN'},
    {value:'BWP', label: 'BWP'},
    {value:'BYN', label: 'BYN'},
    {value:'BZD', label: 'BZD'},
    {value:'CAD', label: 'CAD'},
    {value:'CDF', label: 'CDF'},
    {value:'CHF', label: 'CHF'},
    {value:'CLF', label: 'CLF'},
    {value:'CLP', label: 'CLP'},
    {value:'CNH', label: 'CNH'},
    {value:'CNY', label: 'CNY'},
    {value:'COP', label: 'COP'},
    {value:'CRC', label: 'CRC'},
    {value:'CUC', label: 'CUC'},
    {value:'CUP', label: 'CUP'},
    {value:'CVE', label: 'CVE'},
    {value:'CZK', label: 'CZK'},
    {value:'DJF', label: 'DJF'},
    {value:'DKK', label: 'DKK'},
    {value:'DOP', label: 'DOP'},
    {value:'DZD', label: 'DZD'},
    {value:'EGP', label: 'EGP'},
    {value:'ERN', label: 'ERN'},
    {value:'ETB', label: 'ETB'},
    {value:'EUR', label: 'EUR'},
    {value:'FJD', label: 'FJD'},
    {value:'FKP', label: 'FKP'},
    {value:'GBP', label: 'GBP'},
    {value:'GEL', label: 'GEL'},
    {value:'GGP', label: 'GGP'},
    {value:'GHS', label: 'GHS'},
    {value:'GIP', label: 'GIP'},
    {value:'GMD', label: 'GMD'},
    {value:'GNF', label: 'GNF'},
    {value:'GTQ', label: 'GTQ'},
    {value:'GYD', label: 'GYD'},
    {value:'HKD', label: 'HKD'},
    {value:'HNL', label: 'HNL'},
    {value:'HRK', label: 'HRK'},
    {value:'HTG', label: 'HTG'},
    {value:'HUF', label: 'HUF'},
    {value:'IDR', label: 'IDR'},
    {value:'ILS', label: 'ILS'},
    {value:'IMP', label: 'IMP'},
    {value:'INR', label: 'INR'},
    {value:'IQD', label: 'IQD'},
    {value:'IRR', label: 'IRR'},
    {value:'ISK', label: 'ISK'},
    {value:'JEP', label: 'JEP'},
    {value:'JMD', label: 'JMD'},
    {value:'JOD', label: 'JOD'},
    {value:'JPY', label: 'JPY'},
    {value:'KES', label: 'KES'},
    {value:'KGS', label: 'KGS'},
    {value:'KHR', label: 'KHR'},
    {value:'KMF', label: 'KMF'},
    {value:'KPW', label: 'KPW'},
    {value:'KRW', label: 'KRW'},
    {value:'KWD', label: 'KWD'},
    {value:'KYD', label: 'KYD'},
    {value:'KZT', label: 'KZT'},
    {value:'LAK', label: 'LAK'},
    {value:'LBP', label: 'LBP'},
    {value:'LKR', label: 'LKR'},
    {value:'LRD', label: 'LRD'},
    {value:'LSL', label: 'LSL'},
    {value:'LYD', label: 'LYD'},
    {value:'MAD', label: 'MAD'},
    {value:'MDL', label: 'MDL'},
    {value:'MGA', label: 'MGA'},
    {value:'MKD', label: 'MKD'},
    {value:'MMK', label: 'MMK'},
    {value:'MNT', label: 'MNT'},
    {value:'MOP', label: 'MOP'},
    {value:'MRU', label: 'MRU'},
    {value:'MUR', label: 'MUR'},
    {value:'MVR', label: 'MVR'},
    {value:'MWK', label: 'MWK'},
    {value:'MXN', label: 'MXN'},
    {value:'MYR', label: 'MYR'},
    {value:'MZN', label: 'MZN'},
    {value:'NAD', label: 'NAD'},
    {value:'NGN', label: 'NGN'},
    {value:'NIO', label: 'NIO'},
    {value:'NOK', label: 'NOK'},
    {value:'NPR', label: 'NPR'},
    {value:'NZD', label: 'NZD'},
    {value:'OMR', label: 'OMR'},
    {value:'PAB', label: 'PAB'},
    {value:'PEN', label: 'PEN'},
    {value:'PGK', label: 'PGK'},
    {value:'PHP', label: 'PHP'},
    {value:'PKR', label: 'PKR'},
    {value:'PLN', label: 'PLN'},
    {value:'PYG', label: 'PYG'},
    {value:'QAR', label: 'QAR'},
    {value:'RON', label: 'RON'},
    {value:'RSD', label: 'RSD'},
    {value:'RUB', label: 'RUB'},
    {value:'RWF', label: 'RWF'},
    {value:'SAR', label: 'SAR'},
    {value:'SBD', label: 'SBD'},
    {value:'SCR', label: 'SCR'},
    {value:'SDG', label: 'SDG'},
    {value:'SEK', label: 'SEK'},
    {value:'SGD', label: 'SGD'},
    {value:'SHP', label: 'SHP'},
    {value:'SLL', label: 'SLL'},
    {value:'SOS', label: 'SOS'},
    {value:'SRD', label: 'SRD'},
    {value:'SSP', label: 'SSP'},
    {value:'STD', label: 'STD'},
    {value:'STN', label: 'STN'},
    {value:'SVC', label: 'SVC'},
    {value:'SYP', label: 'SYP'},
    {value:'SZL', label: 'SZL'},
    {value:'THB', label: 'THB'},
    {value:'TJS', label: 'TJS'},
    {value:'TMT', label: 'TMT'},
    {value:'TND', label: 'TND'},
    {value:'TOP', label: 'TOP'},
    {value:'TRY', label: 'TRY'},
    {value:'TTD', label: 'TTD'},
    {value:'TWD', label: 'TWD'},
    {value:'TZS', label: 'TZS'},
    {value:'UAH', label: 'UAH'},
    {value:'UGX', label: 'UGX'},
    {value:'USD', label: 'USD'},
    {value:'UYU', label: 'UYU'},
    {value:'UZS', label: 'UZS'},
    {value:'VEF', label: 'VEF'},
    {value:'VES', label: 'VES'},
    {value:'VND', label: 'VND'},
    {value:'VUV', label: 'VUV'},
    {value:'WST', label: 'WST'},
    {value:'XAF', label: 'XAF'},
    {value:'XAG', label: 'XAG'},
    {value:'XAU', label: 'XAU'},
    {value:'XCD', label: 'XCD'},
    {value:'XDR', label: 'XDR'},
    {value:'XOF', label: 'XOF'},
    {value:'XPD', label: 'XPD'},
    {value:'XPF', label: 'XPF'},
    {value:'XPT', label: 'XPT'},
    {value:'YER', label: 'YER'},
    {value:'ZAR', label: 'ZAR'},
    {value:'ZMW', label: 'ZMW'},
    {value:'ZWL', label: 'ZWL'},

]



export const Countries = [
    {   value: "AFGHANISTAN",   label: "AFGHANISTAN"},
    {   value: "ALBANIA",   label: "ALBANIA"},
    {   value: "ALGERIA",   label: "ALGERIA"},
    {   value: "AMERICAN SAMOA",    label: "AMERICAN SAMOA"},
    {   value: "ANDORRA",   label: "ANDORRA"},
    {   value: "ANGOLA",    label: "ANGOLA"},
    {   value: "ANGUILLA",  label: "ANGUILLA"},
    {   value: "ANTARCTICA",    label: "ANTARCTICA"},
    {   value: "ANTIGUA AND BARBUDA",   label: "ANTIGUA AND BARBUDA"},
    {   value: "ARGENTINA", label: "ARGENTINA"},
    {   value: "ARMENIA",   label: "ARMENIA"},
    {   value: "ARUBA", label: "ARUBA"},
    {   value: "AUSTRALIA", label: "AUSTRALIA"},
    {   value: "AUSTRIA",   label: "AUSTRIA"},
    {   value: "AZERBAIJAN",    label: "AZERBAIJAN"},
    {   value: "BAHAMAS",   label: "BAHAMAS"},
    {   value: "BAHRAIN",   label: "BAHRAIN"},
    {   value: "BANGLADESH",    label: "BANGLADESH"},
    {   value: "BARBADOS",  label: "BARBADOS"},
    {   value: "BELARUS",   label: "BELARUS"},
    {   value: "BELGIUM",   label: "BELGIUM"},
    {   value: "BELIZE",    label: "BELIZE"},
    {   value: "BENIN", label: "BENIN"},
    {   value: "BERMUDA",   label: "BERMUDA"},
    {   value: "BHUTAN",    label: "BHUTAN"},
    {   value: "BOLIVIA",   label: "BOLIVIA"},
    {   value: "BOSNIA AND HERZEGOWINA",    label: "BOSNIA AND HERZEGOWINA"},
    {   value: "BOTSWANA",  label: "BOTSWANA"},
    {   value: "BOUVET ISLAND", label: "BOUVET ISLAND"},
    {   value: "BRAZIL",    label: "BRAZIL"},
    {   value: "BRIT. INDIAN OCEAN TERRITORY",  label: "BRIT. INDIAN OCEAN TERRITORY"},
    {   value: "BRUNEI DARUSSALAM", label: "BRUNEI DARUSSALAM"},
    {   value: "BULGARIA",  label: "BULGARIA"},
    {   value: "BURKINA FASO",  label: "BURKINA FASO"},
    {   value: "BURUNDI",   label: "BURUNDI"},
    {   value: "CAMBODIA",  label: "CAMBODIA"},
    {   value: "CAMEROON",  label: "CAMEROON"},
    {   value: "CANADA",    label: "CANADA"},
    {   value: "CAPE VERDE",    label: "CAPE VERDE"},
    {   value: "CAYMAN ISLANDS",    label: "CAYMAN ISLANDS"},
    {   value: "CENTRAL AFRICAN REPUBLIC",  label: "CENTRAL AFRICAN REPUBLIC"},
    {   value: "CHAD",  label: "CHAD"},
    {   value: "CHILE", label: "CHILE"},
    {   value: "CHINA", label: "CHINA"},
    {   value: "CHRISTMAS ISLAND",  label: "CHRISTMAS ISLAND"},
    {   value: "COCOS (KEELING) ISLANDS",   label: "COCOS (KEELING) ISLANDS"},
    {   value: "COLOMBIA",  label: "COLOMBIA"},
    {   value: "COMOROS",   label: "COMOROS"},
    {   value: "CONGO", label: "CONGO"},
    {   value: "COOK ISLANDS",  label: "COOK ISLANDS"},
    {   value: "COSTA RICA",    label: "COSTA RICA"},
    {   value: "COTE D'IVOIRE", label: "COTE D'IVOIRE"},
    {   value: "CROATIA",   label: "CROATIA"},
    {   value: "CUBA",  label: "CUBA"},
    {   value: "CYPRUS",    label: "CYPRUS"},
    {   value: "CZECH REPUBLIC",    label: "CZECH REPUBLIC"},
    {   value: "DENMARK",   label: "DENMARK"},
    {   value: "DJIBOUTI",  label: "DJIBOUTI"},
    {   value: "DOMINICA",  label: "DOMINICA"},
    {   value: "DOMINICAN REPUBLIC",    label: "DOMINICAN REPUBLIC"},
    {   value: "EAST TIMOR",    label: "EAST TIMOR"},
    {   value: "ECUADOR",   label: "ECUADOR"},
    {   value: "EGYPT", label: "EGYPT"},
    {   value: "EL SALVADOR",   label: "EL SALVADOR"},
    {   value: "EQUATORIAL GUINEA", label: "EQUATORIAL GUINEA"},
    {   value: "ERITREA",   label: "ERITREA"},
    {   value: "ESTONIA",   label: "ESTONIA"},
    {   value: "ETHIOPIA",  label: "ETHIOPIA"},
    {   value: "FALKLAND ISLANDS (MALVINAS)",   label: "FALKLAND ISLANDS (MALVINAS)"},
    {   value: "FAEROE ISLANDS",    label: "FAEROE ISLANDS"},
    {   value: "FIJI",  label: "FIJI"},
    {   value: "FINLAND",   label: "FINLAND"},
    {   value: "FRANCE",    label: "FRANCE"},
    {   value: "FRENCH GUIANA", label: "FRENCH GUIANA"},
    {   value: "FRENCH POLYNESIA",  label: "FRENCH POLYNESIA"},
    {   value: "FRENCH SOUTHERN TERRITOR.", label: "FRENCH SOUTHERN TERRITOR."},
    {   value: "GABON", label: "GABON"},
    {   value: "GAMBIA",    label: "GAMBIA"},
    {   value: "GEORGIA",   label: "GEORGIA"},
    {   value: "GERMANY",   label: "GERMANY"},
    {   value: "GHANA", label: "GHANA"},
    {   value: "GIBRALTAR", label: "GIBRALTAR"},
    {   value: "GREECE",    label: "GREECE"},
    {   value: "GREENLAND", label: "GREENLAND"},
    {   value: "GRENADA",   label: "GRENADA"},
    {   value: "GUADELOUPE",    label: "GUADELOUPE"},
    {   value: "GUAM",  label: "GUAM"},
    {   value: "GUATEMALA", label: "GUATEMALA"},
    {   value: "GUINEA",    label: "GUINEA"},
    {   value: "GUINEA-BISSAU", label: "GUINEA-BISSAU"},
    {   value: "GUYANA",    label: "GUYANA"},
    {   value: "HAITI", label: "HAITI"},
    {   value: "HEARD AND MC DONALD ISLANDS",   label: "HEARD AND MC DONALD ISLANDS"},
    {   value: "HONDURAS",  label: "HONDURAS"},
    {   value: "HONG KONG", label: "HONG KONG"},
    {   value: "HUNGARY",   label: "HUNGARY"},
    {   value: "ICELAND",   label: "ICELAND"},
    {   value: "INDIA", label: "INDIA"},
    {   value: "INDONESIA", label: "INDONESIA"},
    {   value: "IRAN",  label: "IRAN"},
    {   value: "IRAQ",  label: "IRAQ"},
    {   value: "IRELAND",   label: "IRELAND"},
    {   value: "ISRAEL",    label: "ISRAEL"},
    {   value: "ITALY", label: "ITALY"},
    {   value: "JAMAICA",   label: "JAMAICA"},
    {   value: "JAPAN", label: "JAPAN"},
    {   value: "JORDAN",    label: "JORDAN"},
    {   value: "KAZAKHSTAN",    label: "KAZAKHSTAN"},
    {   value: "KENYA", label: "KENYA"},
    {   value: "KIRIBATI",  label: "KIRIBATI"},
    {   value: "KOREA, DEM. PEOPLE'S REP. OF",  label: "KOREA, DEM. PEOPLE'S REP. OF"},
    {   value: "KOREA, REPUBLIC OF",    label: "KOREA, REPUBLIC OF"},
    {   value: "KUWAIT",    label: "KUWAIT"},
    {   value: "KYRGYZSTAN",    label: "KYRGYZSTAN"},
    {   value: "LAO PEOPLE'S DEMOCRATIC REP.",  label: "LAO PEOPLE'S DEMOCRATIC REP."},
    {   value: "LATVIA",    label: "LATVIA"},
    {   value: "LEBANON",   label: "LEBANON"},
    {   value: "LESOTHO",   label: "LESOTHO"},
    {   value: "LIBERIA",   label: "LIBERIA"},
    {   value: "LIBYAN ARAB JAMAHIRIYA",    label: "LIBYAN ARAB JAMAHIRIYA"},
    {   value: "LIECHTENSTEIN", label: "LIECHTENSTEIN"},
    {   value: "LITHUANIA", label: "LITHUANIA"},
    {   value: "LUXEMBOURG",    label: "LUXEMBOURG"},
    {   value: "MACAU", label: "MACAU"},
    {   value: "MACEDONIA", label: "MACEDONIA"},
    {   value: "MADAGASCAR",    label: "MADAGASCAR"},
    {   value: "MALAWI",    label: "MALAWI"},
    {   value: "MALAYSIA",  label: "MALAYSIA"},
    {   value: "MALDIVES",  label: "MALDIVES"},
    {   value: "MALI",  label: "MALI"},
    {   value: "MALTA", label: "MALTA"},
    {   value: "MARSHALL ISLANDS",  label: "MARSHALL ISLANDS"},
    {   value: "MARTINIQUE",    label: "MARTINIQUE"},
    {   value: "MAURITANIA",    label: "MAURITANIA"},
    {   value: "MAURITIUS", label: "MAURITIUS"},
    {   value: "MEXICO",    label: "MEXICO"},
    {   value: "MICRONESIA  (FED. STATES OF)",  label: "MICRONESIA  (FED. STATES OF)"},
    {   value: "MOLDOVA, REPUBLIC OF",  label: "MOLDOVA, REPUBLIC OF"},
    {   value: "MONACO",    label: "MONACO"},
    {   value: "MONGOLIA",  label: "MONGOLIA"},
    {   value: "MONTSERRAT",    label: "MONTSERRAT"},
    {   value: "MOROCCO",   label: "MOROCCO"},
    {   value: "MOZAMBIQUE",    label: "MOZAMBIQUE"},
    {   value: "MYANMAR",   label: "MYANMAR"},
    {   value: "NAMIBIA",   label: "NAMIBIA"},
    {   value: "NAURU", label: "NAURU"},
    {   value: "NEPAL", label: "NEPAL"},
    {   value: "NETHERLANDS",   label: "NETHERLANDS"},
    {   value: "NETHERLANDS ANTILLES",  label: "NETHERLANDS ANTILLES"},
    {   value: "NEW CALEDONIA", label: "NEW CALEDONIA"},
    {   value: "NEW ZEALAND",   label: "NEW ZEALAND"},
    {   value: "NICARAGUA", label: "NICARAGUA"},
    {   value: "NIGER", label: "NIGER"},
    {   value: "NIGERIA",   label: "NIGERIA"},
    {   value: "NIUE",  label: "NIUE"},
    {   value: "NORFOLK ISLAND",    label: "NORFOLK ISLAND"},
    {   value: "NORTHERN MARIANA ISLANDS",  label: "NORTHERN MARIANA ISLANDS"},
    {   value: "NORWAY",    label: "NORWAY"},
    {   value: "OMAN",  label: "OMAN"},
    {   value: "PAKISTAN",  label: "PAKISTAN"},
    {   value: "PALAU", label: "PALAU"},
    {   value: "PANAMA",    label: "PANAMA"},
    {   value: "PAPUA NEW GUINEA",  label: "PAPUA NEW GUINEA"},
    {   value: "PARAGUAY",  label: "PARAGUAY"},
    {   value: "PERU",  label: "PERU"},
    {   value: "PHILIPPINES",   label: "PHILIPPINES"},
    {   value: "PITCAIRN",  label: "PITCAIRN"},
    {   value: "POLAND",    label: "POLAND"},
    {   value: "PORTUGAL",  label: "PORTUGAL"},
    {   value: "PUERTO RICO",   label: "PUERTO RICO"},
    {   value: "QATAR", label: "QATAR"},
    {   value: "REUNION",   label: "REUNION"},
    {   value: "ROMANIA",   label: "ROMANIA"},
    {   value: "RUSSIAN FEDERATION",    label: "RUSSIAN FEDERATION"},
    {   value: "RWANDA",    label: "RWANDA"},
    {   value: "ST. HELENA",    label: "ST. HELENA"},
    {   value: "SAINT KITTS AND NEVIS", label: "SAINT KITTS AND NEVIS"},
    {   value: "SAINT LUCIA",   label: "SAINT LUCIA"},
    {   value: "ST. PIERRE AND MIQUELON",   label: "ST. PIERRE AND MIQUELON"},
    {   value: "SAINT VINCENT / GRENADINES",    label: "SAINT VINCENT / GRENADINES"},
    {   value: "SAMOA WESTERN", label: "SAMOA WESTERN"},
    {   value: "SAN MARINO",    label: "SAN MARINO"},
    {   value: "SAO TOME AND PRINCIPE", label: "SAO TOME AND PRINCIPE"},
    {   value: "SAUDI ARABIA",  label: "SAUDI ARABIA"},
    {   value: "SENEGAL",   label: "SENEGAL"},
    {   value: "SEYCHELLES",    label: "SEYCHELLES"},
    {   value: "SIERRA LEONE",  label: "SIERRA LEONE"},
    {   value: "SINGAPORE", label: "SINGAPORE"},
    {   value: "SLOVAKIA",  label: "SLOVAKIA"},
    {   value: "SLOVENIA",  label: "SLOVENIA"},
    {   value: "SOLOMON ISLANDS",   label: "SOLOMON ISLANDS"},
    {   value: "SOMALIA",   label: "SOMALIA"},
    {   value: "SOUTH AFRICA",  label: "SOUTH AFRICA"},
    {   value: "SPAIN", label: "SPAIN"},
    {   value: "SRI LANKA", label: "SRI LANKA"},
    {   value: "SUDAN", label: "SUDAN"},
    {   value: "SURINAME",  label: "SURINAME"},
    {   value: "SVALBARD / JAN MAYEN IS.",  label: "SVALBARD / JAN MAYEN IS."},
    {   value: "SWAZILAND", label: "SWAZILAND"},
    {   value: "SWEDEN",    label: "SWEDEN"},
    {   value: "SWITZERLAND",   label: "SWITZERLAND"},
    {   value: "SYRIAN ARAB REPUBLIC",  label: "SYRIAN ARAB REPUBLIC"},
    {   value: "TAIWAN, PROVINCE OF CHINA", label: "TAIWAN, PROVINCE OF CHINA"},
    {   value: "TAJIKISTAN",    label: "TAJIKISTAN"},
    {   value: "TANZANIA, UNITED REPUBLIC OF",  label: "TANZANIA, UNITED REPUBLIC OF"},
    {   value: "THAILAND",  label: "THAILAND"},
    {   value: "TOGO",  label: "TOGO"},
    {   value: "TOKELAU",   label: "TOKELAU"},
    {   value: "TONGA", label: "TONGA"},
    {   value: "TRINIDAD AND TOBAGO",   label: "TRINIDAD AND TOBAGO"},
    {   value: "TUNISIA",   label: "TUNISIA"},
    {   value: "TURKEY",    label: "TURKEY"},
    {   value: "TURKMENISTAN",  label: "TURKMENISTAN"},
    {   value: "TURKS AND CAICOS ISLANDS",  label: "TURKS AND CAICOS ISLANDS"},
    {   value: "TUVALU",    label: "TUVALU"},
    {   value: "UGANDA",    label: "UGANDA"},
    {   value: "UKRAINE",   label: "UKRAINE"},
    {   value: "UNITED ARAB EMIRATES",  label: "UNITED ARAB EMIRATES"},
    {   value: "UNITED KINGDOM",    label: "UNITED KINGDOM"},
    {   value: "UNITED STATES", label: "UNITED STATES"},
    {   value: "UNITED STATES MIN. OUTL. ISLS.",    label: "UNITED STATES MIN. OUTL. ISLS."},
    {   value: "URUGUAY",   label: "URUGUAY"},
    {   value: "UZBEKISTAN",    label: "UZBEKISTAN"},
    {   value: "VANUATU",   label: "VANUATU"},
    {   value: "VATICAN CITY STATE (HOLY SEE)", label: "VATICAN CITY STATE (HOLY SEE)"},
    {   value: "VENEZUELA", label: "VENEZUELA"},
    {   value: "VIET NAM",  label: "VIET NAM"},
    {   value: "VIRGIN ISLANDS (BRITISH)",  label: "VIRGIN ISLANDS (BRITISH)"},
    {   value: "VIRGIN ISLANDS (U.S.)", label: "VIRGIN ISLANDS (U.S.)"},
    {   value: "WALLIS AND FUTUNA ISLANDS", label: "WALLIS AND FUTUNA ISLANDS"},
    {   value: "WESTERN SAHARA",    label: "WESTERN SAHARA"},
    {   value: "YEMEN", label: "YEMEN"},
    {   value: "YUGOSLAVIA",    label: "YUGOSLAVIA"},
    {   value: "ZAIRE", label: "ZAIRE"},
    {   value: "ZAMBIA",    label: "ZAMBIA"},
    {   value: "ZIMBABWE",  label: "ZIMBABWE"},
    {   value: "EUROPEAN CURRENCY UNIT",    label: "EUROPEAN CURRENCY UNIT"},
    {   value: "SPECIAL DRAWING RIGHTS",    label: "SPECIAL DRAWING RIGHTS"}
]