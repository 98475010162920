import React from 'react';
import {BsInfoCircle} from 'react-icons/bs'

const Alert = ({errorMsg}) => {
  return (
      <div className={`flex text-sm items-center text-red-500`}>
        {/* <BsInfoCircle className='mx-2'/> */}
        <p>{errorMsg}</p>
        
    </div>
    )
};

export default Alert;
