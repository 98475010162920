import React from 'react'
import Footer from '../../Footer';
import Navbar from '../../Navbar'
import Sidebar from '../Sidebar'
import Nav from './Nav'
import Read from './Read';


const Teams = () => {
  return (
    <div>
      <div className='flex'>
        <div className='hidden md:block md:w-1/6 bg-[#394975] h-[49rem] text-white'>
          <Sidebar/>
        </div>
        <div className='w-full md:w-5/6 bg-[#ECF3FA]'>
         <Navbar/>
         <div className='bg-white mt-8 mx-10 pb-2'>
            <Nav/>
            <Read/>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default Teams