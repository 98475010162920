import React, {useState} from 'react'
import { Link, useNavigate } from "react-router-dom";
import {MdKeyboardArrowLeft} from 'react-icons/md'
import SmsCredit from '../SmsCredit';

const Sendsms = () => {
    const navigate = useNavigate()
    const [sms_type, setSmsType] = useState('single_sms')
    const [singleSmsIsChecked, setsingleSmsIsChecked] = useState(true)
    const [excelIsChecked, setExcelIsChecked] = useState(false)
  
    const handlePrepaidChange = (e) => {
    setSmsType('single_sms')
    setsingleSmsIsChecked(!singleSmsIsChecked)
    setExcelIsChecked(!excelIsChecked)

  }

  const handleSendExcelChange = (e) => {
    setSmsType('sendViaExcel')
    setsingleSmsIsChecked(!singleSmsIsChecked)
    setExcelIsChecked(!excelIsChecked)
  }

  const onNextButtonClicked = (e) => {
    e.preventDefault()

    if (singleSmsIsChecked == true) {
        return navigate('/bam/sms/singlesms',{replace: true})
    }else{
        return navigate('/bam/sendsms/excel',{replace: true})
    }

  }


  return (
    <div className=' bg-white mx-8 h-[30rem] my-4 font-roboto'>
            <SmsCredit activity='Send Sms'/>
            <p className='flex items-center h-12 ml-12 text-gray-500'>
                How would you like to send your sms?
            </p>
        <div className='flex items-center justify-center mx-8'>
            <div className="flex flex-col justify-center w-full border h-48">
                <div className="flex">
                <input
                    type="radio"
                    name="singleSms" 
                    value={sms_type === "singleSms"}
                    className="border-gray-300 mx-2"
                    onChange={handlePrepaidChange}
                    checked={singleSmsIsChecked}
                    />
                <label htmlFor="singleSms" className="mx-2">
                    I want to send SMS to 1 – 5 mobile phone numbers by manually typing in the numbers

                </label>      
                </div>
                <div className="flex">
                <input
                    type="radio"
                    name="sendViaExcel"
                    value={sms_type === "sendViaExcel"}
                    className="border-gray-300 border mx-2"
                    onChange={handleSendExcelChange}
                    checked={excelIsChecked}
                />
                <label htmlFor="sendViaExcel" className="mx-2 my-1">
                    I want to send Bulk SMS by uploading an Excel or CSV file with multiple mobile phone numbers

                </label>
                </div>                   
            </div>
           
        </div>
        <div className='flex justify-between w-full mx-8 my-2'>
            {/* <Link to='/dashboard' className='flex justify-center text-sm text-white bg-[#00B050] px-4 h-8 items-center'>
                <div className='flex mx-0 px-0'>
                    <MdKeyboardArrowLeft className=''/>
                    <MdKeyboardArrowLeft className='mr-2'/>
                </div>      
                <p>Back to my workspace</p>
            </Link> */}
            <p></p>
            <button onClick={onNextButtonClicked} className='flex justify-center text-white bg-[#00B050] w-24 mr-20 h-8 items-center'>Next</button>
        </div>
    </div>
  )
}

export default Sendsms