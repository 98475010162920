import React , {useState} from 'react'
import { IoPersonSharp } from 'react-icons/io5';
import axios from 'axios'
import { Link } from 'react-router-dom';

const Navbar = () => {
  //const token = localStorage.getItem("token");
  const [dropdown, setDropdown] = useState(false)

  const logout = async (e) => {
    e.preventDefault()
    setDropdown(false)
    // const token = localStorage.getItem("token");
    // const headers = {
    //      accept: 'application/json',
    //      Authorization: 'Bearer ' + token
    //  }
    //  axios.get("https://apis.mutani.co/api/permissions", {
    //     headers: headers
    // })
    // .then((response) => {
    //   const permissions = response.data 
    //   console.log(permissions)
    // })
    // .catch(function (error) {
    //     console.error(error);
    // });
    
  };
//   async function logoutFromApi(credentials) {
//     return fetch('https://apis.mutani.co/api/logout', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${token}`, 
//       },
//       body: JSON.stringify(credentials)
//     })
//       .then(data => data.json())
// }
  return (
    <div className='flex justify-between items-center bg-white mb-4 h-24'>
      <div className='flex justify-start ml-4 my-4 font-roboto text-[#06384f'>
        <Link to='/' className='ml-8'>
          My Workshop
        </Link>
        <Link to='/bam/sendsms/excel' className='mx-8'>
          Send SMS
        </Link>
        <Link>
          Generate Reports
        </Link>
        <Link className='mx-8'>
          Purchase Credit
        </Link>
        <Link>
          Get Help
        </Link>
      
      </div>
      <div className='flex justify-end mx-2'>
      <div className='flex items-center text-mutani'>
        <p className='mx-2 text-bold '>My Account</p>
        <button className="flex items-center mx-4" onClick={logout}>
          <IoPersonSharp className='text-2xl'/>
        </button>  
        {/* {
          dropdown ? <Dropdown/> : null
        }     */}
      </div>
    </div>
    </div>
  //   <nav className='flex justify-between items-center bg-mutani h-10'>
  //   <div className='justify-start'></div>
  //   <div className='flex justify-center items-center'>
  //     <p className='text-yellow-200'>Mutani MSP - Messaging Service Portal</p>
  //   </div>
  //   <div className='flex justify-end mx-2'>
  //     <div className='flex items-center text-white'>
  //       <p className='mx-2'>My Account</p>
  //       <button className="flex items-center" onClick={logout}>
  //           <img src="http://www.gravatar.com/avatar?d=mm" alt="avatar" className="w-6 h-6 rounded-full"/>
  //           <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"><path d="M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z" className="heroicon-ui"></path></svg>
  //       </button>  
  //       {/* {
  //         dropdown ? <Dropdown/> : null
  //       }     */}
  //     </div>
  //   </div>
  // </nav>  
  
  )
}

export default Navbar