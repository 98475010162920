import React from 'react'

const UnauthorizedPermissions = ({unassignedPermissions}) => {
  return (
    <tr>
        <td className='border'>
            <p className='flex w-full justify-center items-center text-center text-gray-500 h-10 text-xs'>
                { unassignedPermissions }
            </p>
        </td>
    </tr>   )
}

export default UnauthorizedPermissions