import React, { useEffect, useState } from "react"


const SmsCredit = ({activity}) => {
    const [currentBalance, setcurrentBalance] = useState('')
    const token = localStorage.getItem("token");

    async function getUserBalance() {
        return fetch(`https://apis.mutani.co/api/balance`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
    
          },
        })
          .then(data => data.json())
    }
    useEffect(() => {
        const makeReques = async () => {
            const response = await getUserBalance()

            if (response.current_balance) {
                setcurrentBalance(0)
            }
            setcurrentBalance(response.current_balance)
        }
        makeReques()
    }, [])

    

  return (
    <div className='flex justify-between items-center bg-white mb-4 border-b'>
        <p className='flex justify-start ml-10 my-4 font-bold'>{activity}</p>
        <div className='flex justify-end mr-10 my-4'>
            <div className='flex justify-center items-center mr-4 space-x-4'>
            <p >SMS Wallet Balance</p>
            <div className='bg-[#00B050] h-8 w-16 flex justify-center items-center rounded'>
                {currentBalance}
            </div>
            <button className='bg-[#ffc000] h-8 w-16 flex justify-center items-center rounded'>Top Up</button>
        </div>
        </div>
    </div>  
)
}

export default SmsCredit