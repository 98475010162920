import React, {useReducer} from 'react'
import PermissionContext from './permissionsContext'
import axios from 'axios';
import PermissionReducer from './permissionsReducer'
import { useNavigate } from "react-router-dom";

import {
    SET_LOADING,
    GET_ALL_PERMISSIONS,
    ADD_NEW_ROLE,
    SET_LOADING_OFF,
    ADD_NEW_PERMISSION,
    SEARCH_PERMISSION,
    PERMISSION_SEARCH,
    token
  } from '../types';

const PermissionState = ({children}) => {
  const navigate = useNavigate()

    const initialState = {
        allPermissions: [],
        newRole: [],
        newPermission: [],
        searchResults: [],
        foundPermissions: [],
        permissionLoading: false
      };

      const token = localStorage.getItem("token");

      const [state, dispatch] = useReducer(PermissionReducer, initialState);


      const searchPermission = (name) => {
        setLoading();
        const headers = {
             accept: 'application/json',
             Authorization: 'Bearer ' + token
         }
         axios.get(`https://apis.mutani.co/api/permissions/search/${name}`, {
            headers: headers
        })
        .then((response) => {
          const data = response.data.user
          // console.log(data)

          dispatch({
            type: PERMISSION_SEARCH,
            payload: data
          });
        })
        .catch(function (error) {
            console.error(error);
            dispatch({
              type: SET_LOADING_OFF
            });
        });
      }

      const getAllPermissionsFromDB = () => {
        setLoading();
        const headers = {
             accept: 'application/json',
             Authorization: 'Bearer ' + token
         }
         axios.get(`https://apis.mutani.co/api/permissions`, {
            headers: headers
        })
        .then((response) => {
          const data = response.data
          const { permissions } = data
    
          //console.log(roles)
    
          dispatch({
            type: GET_ALL_PERMISSIONS,
            payload: permissions
          });     
        })
        .catch(function (error) {
            console.error(error);
        });
      }

      const addNewRole = (credentials) => {
        setLoading();
        const headers = {
            //  accept: 'application/json',
             'Content-Type': 'application/json', 
             Authorization: 'Bearer ' + token,
            //  "Access-Control-Allow-Origin" : '*'
    
         }
         axios.post(`https://apis.mutani.co/api/roles`, 
         JSON.stringify(credentials),
         {
            headers: headers,
            // withCredentials: true
    
        })
        .then((response) => {
          const data = response.data

          if ('Success' in data) {
            navigate(0)
            
          }

          console.log(data)
          dispatch({
            type: ADD_NEW_ROLE,
            payload: data
          });
          // navigate(0)
        })
        .catch(function (error) {
            console.error(error);
            dispatch({
              type: SET_LOADING_OFF
            });
        });
      }

      const createPermission = (credentials) => {
        setLoading();

        const headers = {
            //  accept: 'application/json',
             'Content-Type': 'application/json', 
             Authorization: 'Bearer ' + token,
            //  "Access-Control-Allow-Origin" : '*'
    
         }
         axios.post(`https://apis.mutani.co/api/permissions`, 
         JSON.stringify(credentials),
         {
            headers: headers,
            // withCredentials: true
    
        })
        .then((response) => {
          const data = response.data
          console.log(data)
          dispatch({
            type: ADD_NEW_PERMISSION,
            payload: data
          });
          navigate(0)
        })
        .catch(function (error) {
            console.error(error);
            dispatch({
              type: SET_LOADING_OFF
            });
        });
      }

      const deletePermission = (id) => {
        setLoading();
        const headers = {
             accept: 'application/json',
             Authorization: 'Bearer ' + token
         }
         axios.delete(`https://apis.mutani.co/api/permissions/${id}`, {
            headers: headers
        })
        .then((response) => {
          const data = response.data
          if ('Success' in data) {
            navigate(0)
            
          }
    
          dispatch({
            type: SET_LOADING_OFF
          });
    
          navigate(0)          
        })
        .catch(function (error) {
            console.error(error);
        });
      }

      const  updatePermission = async (credentials, id) => {
        const headers = {
             'Content-Type': 'application/json', 
             Authorization: 'Bearer ' + token,    
         }
         axios.post(`https://apis.mutani.co/api/permissions/${id}`, 
         JSON.stringify(credentials),
         {
            headers: headers,
            // withCredentials: true
    
        })
        .then((response) => {
          const data = response.data
          if ('Success' in data) {
            navigate(0)
            
          }
    
          dispatch({
            type: SET_LOADING_OFF
          });
    
          navigate(0)          
        })
        .catch(function (error) {
            console.error(error);
            
        });
      }

      const setLoading = () => dispatch({ type: SET_LOADING });

      const setLoadingOff = () => dispatch({ type: SET_LOADING_OFF });


  return (
    <PermissionContext.Provider
        value={{
            allPermissions: state.allPermissions,
            permissionLoading: state.permissionLoading,
            newRole: state.newRole,
            foundPermissions: state.foundPermissions,
            searchResults: state.searchResults,
            getAllPermissionsFromDB,
            addNewRole,
            deletePermission,
            createPermission,
            updatePermission,
            searchPermission,
            setLoadingOff
        }}>
        {children}
    </PermissionContext.Provider>
  )
}

export default PermissionState