import React, {useState, useEffect} from 'react'
import Select from "react-select";

const EditSenderIds = ({cancelEditSenderIdForm, senderIds, updateSenderId}) => {
    const [email, setEmail] = useState(senderIds.email)
    const [sender_id, setSenderId] = useState(senderIds.sender_id)

    const handleSenderIdUpdate = async (e) => {
      e.preventDefault()

      await updateSenderId({email, sender_id}, senderIds.id)
  }


  return (
    <>
    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-6xl">
          <form  className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-center justify-center p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">
                Edit Sender ID
              </h3>
            </div>
            <div className="relative p-6 flex-auto">
              <div className=" text-slate-500 text-lg leading-relaxed h-[15rem] w-[50rem]">
                <div className="mt-6">
                        <label htmlFor="email" className="flex ml-[5rem]">
                            User Email <p className='text-red-500'> * </p> 
                        </label>
                        <div className="flex justify-center items-center mt-1">
                        <input
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="border-gray-300 border rounded w-4/5 h-10 mx-2"
                            placeholder='Role Name'
                        />
                    </div>
                </div>
                <div className="mt-6">
                        <label htmlFor="email" className="flex ml-[5rem]">
                            Sender ID Name <p className='text-red-500'> * </p> 
                        </label>
                        <div className="flex justify-center items-center mt-1">
                        <input
                            type="text"
                            value={sender_id}
                            onChange={(e) => setSenderId(e.target.value)}
                            className="border-gray-300 border rounded w-4/5 h-10 mx-2"
                            placeholder='Role Name'
                        />
                    </div>
                </div>
                <div className="flex items-center justify-between mx-16 p-6 ">
                </div>
            </div>
            </div>
            <div className="flex items-center justify-between p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                    className="bg-[#ffc000] active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 mx-8"
                    type="button"
                    onClick={cancelEditSenderIdForm}
                >
                Cancel
                </button>
              <button
                className="bg-emerald-500 mr-8  text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                type="button"
                // onClick={() => {
                //     updatePermission(permission.id)
                // }}

                onClick={handleSenderIdUpdate}
                >
                Update
                </button>
            </div>
          </form>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>  )
}

export default EditSenderIds