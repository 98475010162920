import React, { useState, useContext, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import Select from "react-select";
import GithubContext from '../../../context/user/userContext'
// import AdminRegisterError from '../../layout/AdminRegisterError'
import { Countries, colourOptions, Groups, Currencies } from "../types";

const AddUser = ({user_roles, handleCancle }) => {
    const githubContext = useContext(GithubContext);

    const {
      createUser,
      adminCreateUser,
      loading
  } = githubContext;

  useEffect(() => {
    formNewArrayFromUserPermissionContext()
    
  }, []);


    const formNewArrayFromUserPermissionContext = () =>{
      const arr = [];
      user_roles.map((role)=>{
        return arr.push({value: role.name, label: role.name});
      })
      setallRoleA(arr);
  }

  const [code, setCode] = useState("");
  const [allRoles, setallRoleA] = useState([""])
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [user_group, setGroup] = useState("");
  const [role, setRole] = useState([""]);
  const [sms_cost, setSmsCost] = useState("");
  const [billing_currency, setBillingCurrency] = useState("");
  const [payment_type, setPaymentType] = useState('prepaid')
  const [prepaidIsChecked, setPrepaidIsChecked] = useState(true)
  const [postpaidIsChecked, setPostpaidIsChecked] = useState(false)

  //const [postpaid, setPostpaid] = useState(false)

  const [error, setError] = useState('')

  // const checkError = () =>{
  //   if (first_name === "") {
  //     setError(true)
  //     setFirstNameErrorMsg('Firstname feild cannot be empty');
  //     setTimeout(() => {
  //       setError('')
  //     }, 5000);
  //   }else if(last_name === "") {
  //     setError(true)
  //     setLastNameErrorMsg('Lastname feild cannot be empty');
  //     setTimeout(() => {
  //       setError('')
  //     }, 5000);
  //   }

  // }

  const [firstNameErrorMsg, setFirstNameErrorMsg] = useState('')
  const [lastNameErrorMsg, setLastNameErrorMsg] = useState('')

  const handlePrepaidChange = (e) => {
    setPaymentType('prepaid')
    setPrepaidIsChecked(!prepaidIsChecked)
    setPostpaidIsChecked(!postpaidIsChecked)

  }

  const handlePostpaidChange = (e) => {
    setPaymentType('postpaid')
    setPrepaidIsChecked(!prepaidIsChecked)
    setPostpaidIsChecked(!postpaidIsChecked)
  }



  const handleSubmit = async (e) => {
    e.preventDefault();
    // const phone = `${code}${tel}`;
    const roles = [];

    const formated =  role.map((en) => {
      return roles.push(en.value);
    });


    const response = adminCreateUser({
      first_name,
      last_name,
      password,
      email,
      phone,
      roles,
      user_group,
      country,
      payment_type,
      billing_currency,
      sms_cost

    });
  };


  if (loading) return <div>Loading...</div>
  return (
    <>
    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-6xl">
          <form  onSubmit={handleSubmit} className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-center justify-center p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">
                Add New User
              </h3>
            </div>
            <div className="relative p-6 flex-auto">
              <div className="text-slate-500 text-lg leading-relaxed h-[34rem] w-[65rem] overflow-y-auto">
                <div className="">
              <div className="flex flex-col justify-center items-center mx-6">
                <div className="flex">
                  <div className="flex flex-col">
                    <label htmlFor="email" className="mx-2 my-1">
                      Email*
                    </label>
                    <input
                      type="text"
                      name="Email"
                      onChange={(e) => setEmail(e.target.value)}
                      className="border-gray-300 border rounded w-96 h-10 mx-2"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label htmlFor="password" className="flex mx-2 my-1">
                      Password<p className="text-red-600">*</p>
                    </label>
                    <input
                      type="password"
                      name="password"
                      onChange={(e) => setPassword(e.target.value)}
                      className="border-gray-300 border rounded w-96 h-10 mx-2"
                    />
                  </div>
                </div>
                <div className="flex my-1">
                  <div className="flex flex-col">
                    <label htmlFor="first_name" className="mx-2 my-1">
                      First name*
                    </label>
                    <input
                      type="text"
                      name="first_name"
                      onChange={(e) => setFirstName(e.target.value)}
                      className="border-gray-300 rounded border w-96 h-10 mx-2"
                    />
                  </div>
                  <div className="flex flex-col">
                    <label htmlFor="last_name" className="mx-2 my-1">
                      Last name*
                    </label>
                    <input
                      type="text"
                      name="last_name"
                      onChange={(e) => setLastName(e.target.value)}
                      className="border-gray-300 rounded border w-96 h-10 mx-2"
                    />
                  </div>
                </div>
                <div className="flex my-1 w-4/5 mx-12">
                  <div className="flex w-1/2 flex-col my-2">
                    <label htmlFor="country" className="w-full pl-3">
                      Country
                    </label>
                    <div className="flex flex-col w-full items-center justify-center">
                      <Select
                        // defaultValue={[Countries[2]]}
                        name="colors"
                        options={Countries}
                        onChange={(country) => setCountry(country.value)}
                        className="basic-multi-select w-full"
                        classNamePrefix="select"
                      />
                    </div>
                  </div>
                  <div className="flex w-1/2 flex-col mx-4">
                    <label htmlFor="email" className="mx-2 my-1">
                      Mobile Number
                    </label>
                    <div className="flex w-full">
                      <input
                        type="text"
                        name="code"
                        onChange={(e) => setPhone(e.target.value)}
                        minLength={12}
                        maxLength={12}
                        className="border-gray-300 rounded w-full mr-4 border h-10"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-full my-1">
                  <label htmlFor="email" className="mx-2 ml-24">
                    User Group
                  </label>
                  <div className="flex flex-col w-full items-center justify-center">
                    <Select
                      // defaultValue={[Groups[2]]}
                      name="user_group"
                      options={Groups}
                      onChange={(user_group) => setGroup(user_group.value)}
                      className="basic-multi-select w-4/5"
                      classNamePrefix="select"
                    />
                  </div>
                </div>
                <div className="flex flex-col w-full">
                  <label htmlFor="role" className="mx-2 ml-24 my-2">
                    Role(s)
                  </label>
                  <div className="flex flex-col w-full items-center justify-center">
                    <Select
                      // defaultValue={[colourOptions[2], colourOptions[3]]}
                      isMulti
                      options={allRoles}
                      value={role}
                      onChange={(role) => setRole(role)}
                      className="basic-multi-select w-4/5"
                      classNamePrefix="select"
                    />
                    
                  </div>
                </div>
                <div className="flex justify-center items-center mt-6 w-full px-24">
                  <div className="flex flex-col w-1/3">
                      <div className="flex">
                        <input
                            type="radio"
                            name="PaymentType"
                            value={payment_type === "PaymentType"}
                            className="border-gray-300 mx-2"
                            onChange={handlePrepaidChange}
                            checked={prepaidIsChecked}
                            />
                        <label htmlFor="PaymentType" className="mx-2">
                          Pre-paid Customer
                        </label>      
                      </div>
                      <div className="flex">
                        <input
                          type="radio"
                          name="postpaid"
                          value={payment_type === "postpaid"}
                          className="border-gray-300 border mx-2"
                          onChange={handlePostpaidChange}
                          checked={postpaidIsChecked}
                        />
                        <label htmlFor="postpaid" className="mx-2 my-1">
                          Post-paid Customer
                        </label>
                      </div>                   
                  </div>
                  <div className="flex w-1/3 flex-col ">
                    <label htmlFor="country" className="w-full items-center justify-center">
                      Billing Currencies
                    </label>
                    <div className="flex flex-col w-full items-center justify-center">
                      <Select
                        // defaultValue={[Currencies[52]]}
                        name="colors"
                        options={Currencies}
                        onChange={(billing_currency) => setBillingCurrency(billing_currency.value)}
                        className="basic-multi-select w-full"
                        classNamePrefix="select"
                      />
                    </div>
                  </div>
                  <div className="flex w-1/3 flex-col">
                    <label htmlFor="email" className="mx-2">
                      Cost Per SMS
                    </label>
                    <div className="flex">
                      <input
                        type="text"
                        name="code"
                        onChange={(e) => setSmsCost(e.target.value)}
                        className="border-gray-300 border w-60 h-10 mx-2"
                      />
                    </div>
                  </div>

                </div>
              </div>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-between p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                    className="bg-[#ffc000] active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 mx-8"
                    type="button"
                    onClick={handleCancle}
                >
                Cancel
                </button>
              <button
                className="bg-emerald-500 mr-8  text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={handleSubmit}
                >
                Add User
                </button>
            </div>
          </form>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>    )
}

export default AddUser