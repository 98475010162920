import React from 'react'

const Sidebar = () => {
  return (
    <div>
    <ul className='flex flex-col justify-center mt-20 align-center font-roboto'>
      <div className=''>
        <li className='border-b border-t border-gray-500 pl-8 py-1'>Billing & Payment</li>
        <li className='border-b pl-8 py-1 border-gray-500 bg-gray-500'>Add Funds</li>
        <li className='border-b pl-8 py-1 border-gray-500'>Payment History</li>
        <li className='border-b pl-8 py-1 border-gray-500'>Payment Methods</li>
        <li className='border-b pl-8 py-1 border-gray-500'>Low Balance Alert</li>
        <li className='border-b pl-8 py-1 border-gray-500'>Rsources</li>
        <li className='border-b pl-8 py-1 border-gray-500'>My Account</li>
      </div>
      
    </ul>
    
</div>
  )
}

export default Sidebar