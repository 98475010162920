import React, {useState, useEffect} from 'react'
import CreatableSelect from 'react-select/creatable';

const RequestSenderIds = ({handleCancle, sendSenderIdNotificationToAdmin, sender_id}) => {
    const [sender_ids, setSenderId] = useState("")
    const [selectedSenderIds, setSelectedSenderIds] = useState([""])


    const user = localStorage.getItem("user");
    const parseUser = JSON.parse(user);
    const email = parseUser.email
    
    useEffect(() => {
        formNewArrayFromUserSenderIds()
    }, [])
    


    const handleSubmit = (e) => {
        e.preventDefault();
        if (sender_ids === "") {
            console.log('fill')
            
        }
        let sender_idds = sender_ids.value

        sendSenderIdNotificationToAdmin(sender_idds, email)


    }

    const formNewArrayFromUserSenderIds = () =>{
        const arr = [];
        sender_id.map((role)=>{
          return arr.push({value: role.sender_id, label: role.sender_id});
        })
        setSelectedSenderIds(arr);
    }

  return (
    <>
    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-6xl">
          <form  
            onSubmit={handleSubmit} 
            className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
            >
            <div className="flex items-center justify-center p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">
                Request New Sender ID
              </h3>
            </div>
            <div className="relative p-6 flex-auto">
              <div className=" text-slate-500 text-lg leading-relaxed h-52 w-[54rem]">
              <div>
                <div className="">
                    <label htmlFor="email" className="flex ml-[4rem]">
                        Sender Id Name <p className='text-red-500'> * </p> 
                    </label>
                    <span className='text-red-500 text-xs mx-16 my-2'>
                        Any of the dropdown or something similar might caused your sender id to be rejected 
                        such sender ids had been taken by others.


                    </span>
                    <div className="flex justify-center items-center mt-2">
                        <CreatableSelect
                            options={selectedSenderIds}
                            placeholder="Type Sender ID"
                            isClearable
                            onChange={(sender_ids) => setSenderId(sender_ids)}
                            className="basic-multi-select w-[46rem]"
                            classNamePrefix="select"
                        />
                    </div>
                </div>
                
                <div className="flex items-center justify-between mx-16 p-6 ">
                </div>
            </div>
              </div>
            </div>
            <div className="flex items-center justify-between p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                    className="bg-[#ffc000] active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 mx-8"
                    type="button"
                    onClick={handleCancle}
                >
                Cancel
                </button>
              <button
                className="bg-emerald-500 mr-8  text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={handleSubmit}
                >
                Request Sender Id
                </button>
            </div>
          </form>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>      
    )
}

export default RequestSenderIds