import React, {useState, useEffect, useContext} from 'react'
import ReportContext from '../../../context/reports/reportContext'
import DlrReports from './DlrReports'
import { saveAs } from 'file-saver';
import ReactPaginate from 'react-paginate';
import {GrDocumentCsv, GrDocumentPdf} from 'react-icons/gr'
import {SiMicrosoftexcel} from 'react-icons/si'
import FileSaver from "file-saver";
import * as XLSX from 'xlsx';


import {BsFileEarmarkPdf} from 'react-icons/bs'

const PeriodReport = () => {
    const [dateFrom, setDateFrom] = useState('')
    const [dateTo, setDateTo] = useState('')
    const [pageCount, setPageCount] = useState('1')
    const [exam, setexam] = useState('')

    const reportContext = useContext(ReportContext);
    
    const { 
        totalSmsSubmittedByDate,
        submittedByDate,
        fetchNextPage,
        fetchPreviousPage
    } 
    = reportContext;

    const handleSubmit = async (e) => {
        e.preventDefault()
        totalSmsSubmittedByDate('JjCFe_n5TZWolDj', dateFrom, dateTo, pageCount)

        console.log(submittedByDate.next_page_url)

    }

    const nextPageOnClick = () => {
        fetchNextPage(submittedByDate.next_page_url)
        // console.log(users)

    }

    const PreviousBtnClick = () => {
        fetchPreviousPage(submittedByDate.prev_page_url)
        // console.log(users)

    }

    const handleDownloadClick = () => {
        // Serialize the data into a CSV string
        const csvString = submittedByDate.data.map(item => `${item.partner_id},${item.message_id}\n`).join('');
        
        // Convert the CSV string to a Blob object
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8' });
        
        // Save the Blob as a file using the FileSaver library
        saveAs(blob, 'data.csv');
      };

      const handleExcelDownload = () => {
        exportToCSV(submittedByDate.data,"reports",'91')
      };

    //   const handlePageClick = (event) => {
    //     const newOffset = event.selected * itemsPerPage % items.length;
    //     console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    //     setItemOffset(newOffset);
    //   };

    const handlePagination = (e) =>{
        totalSmsSubmittedByDate('JjCFe_n5TZWolDj', dateFrom, dateTo, e.target.innerHTML)
    }

    const renderKeys = (e) => {      
    //does not work    
    let arr = Array.from({length: submittedByDate.last_page}, (_, i) => i + 1)


    return arr.map((ar)=>(
        <>
        <div className='flex'>
            <p className='flex items-center justify-center cursor-pointer text-red-500 h-10 w-8 border text-center'>{ar}</p>
        </div>

        </>
        
    ))

    };

    const Heading = [
        {
            partner_id: "Partner",
            message_id: "Last Name",
        }
      ];

      const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

    const exportToCSV = (csvData, fileName, wscols) => {
        const ws = XLSX.utils.json_to_sheet(Heading, {
          header: ["partner_id", "message_id"],
          skipHeader: true,
          origin: 0 //ok
        });
        ws["!cols"] = wscols;
        XLSX.utils.sheet_add_json(ws, csvData, {
          header: ["partner_id", "message_id"],
          skipHeader: true,
          origin: -1 //ok
        });
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
      };
    


  return (

    <>
        <div className=' bg-white mx-2 md:h-64 '>
            <div className=' border-4 border-gray-100 mx-6'>
                <form onSubmit={handleSubmit} className="my-6">
                    <div className='flex'>
                        <div className="flex mt-2 ml-8 w-1/3 mr-4">
                            <label htmlFor="email" className="flex justify-center items-center">
                                From
                            </label>
                            <div className="flex justify-center items-center">
                                <input
                                    type="date"
                                    onChange={(e) => setDateFrom(e.target.value)}
                                    className="border-gray-300 border rounded h-10 ml-2 w-64"
                                    // value="2018-07-22"
                                    // min="2022-01-01" 
                                    // max="2018-12-31"
                                />
                            </div>
                        </div>
                        <div className="flex mt-2 w-1/3">
                            <label htmlFor="email" className="flex justify-center items-center">
                                To
                            </label>
                            <div className="flex justify-center items-center">
                                <input
                                    type="date"
                                    onChange={(e) => setDateTo(e.target.value)}
                                    className="border-gray-300 border rounded h-10 ml-2 w-64"
                                />
                            </div>
                        </div>
                        <button 
                            onClick={handleSubmit} 
                            className='flex justify-center text-sm text-white bg-[#CED6E0] w-32 rounded-lg h-10 items-center mt-2'>
                            Submit
                        </button>
                    </div>
                </form>
                <div className='flex justify-between mx-8'>
                    <div></div>
                    <div className='flex'>
                        <button onClick={handleDownloadClick} className='text-xl h-12'><GrDocumentCsv/></button>
                        <button onClick={handleExcelDownload} className='text-xl h-12 mx-2'><SiMicrosoftexcel/></button>
                        <button onClick={handleDownloadClick} className='text-xl h-12'><GrDocumentPdf/></button>
                    </div>

                </div>


                <div className="overflow-y-scroll h-80 mx-4 border-4 border-gray-100">
                    <table className="table border-collapse min-w-full h-90 px-2">
                        <thead>
                            <tr className='text-left uppercase'>
                                <th className="text-xs font-bold text-gray-700">
                                    SID
                                </th>
                                <th className="text-xs font-bold tracking-wider">
                                    Recipent
                                </th>
                                <th className="py-2 text-xs font-bold text-gray-700 tracking-wider">
                                    MESSAGE
                                </th>
                                <th className="py-2 text-xs font-bold text-gray-700 tracking-wider">
                                    SUB DATE
                                </th>
                                <th className="py-2 text-xs font-bold text-gray-700 tracking-wider">
                                    D-DATE
                                </th>
                                <th className="py-2 text-xs font-bold text-gray-700 tracking-wider">
                                    D-STATUS
                                </th>
                            </tr>
                        </thead>
                        <tbody className='text-left'>
                            {
                                submittedByDate.data
                                ?
                                submittedByDate.data.map((sub) => (
                                        <DlrReports 
                                            sub={sub} 
                                            key={sub.id} 
                                        />
                                    
                                    ))
                                :
                                <tr>
                                    <td colSpan="5" className="text-center">No Records Found.</td>
                                </tr> 
                            }
                        
                        </tbody>
                    </table>   
                </div>
                <div className='flex justify-between'>
                    <div className='mx-4'>
                    </div>

                    <div className='flex justify-end mx-4'>
                    <div className='overflow-y-scroll text-left ml-4'>
                        {
                            submittedByDate.data
                            &&
            
                            <button onClick={(e)=>{handlePagination(e)}} className='flex'>{renderKeys()}
                            </button>
                                
                        
                        }
                    
                    </div>

                    {/* <button className='h-12 mx-2 bg-green-200 w-24' onClick={nextPageOnClick}>Next</button>
                    <button className='h-12 mx-2 bg-green-200 w-24' onClick={PreviousBtnClick}>Prev</button> */}

                    </div>
                </div>
              
                
              
            </div>
        </div>
    </>
    
  )
}

export default PeriodReport