import React,{useContext, useEffect} from 'react'
import Sidebar from './Sidebar'
import Navbar from '../Navbar'
import PaymentType from './PaymentType'
import { useLocation } from 'react-router-dom';
import PaymentSuccess from './PaymentSuccess'
import PaymentHistory from './History/PaymentHistory';
import ReportContext from '../../context/reports/reportContext'
const Payment = () => {
    const location = useLocation();

    
  const reportContext = useContext(ReportContext);  

  const { 
    getPaymentHistory,
    payments,
  } 
  = reportContext;


  
  useEffect(() => {
    if (payments.length === 0) {
      getPaymentHistory()
    }
  }, [payments])



    let componentToRender;

    if (location.pathname === '/payment/success') {
      componentToRender = <PaymentSuccess />;
    } else if (location.pathname === '/payment') {
      componentToRender = <PaymentType />;
    }
    else{
        componentToRender = <PaymentHistory payments={payments}/>;
    }

  return (
    <div className='flex'>
    <div className='w-1/6 bg-[#394975] h-screen text-white'>
        <Sidebar/>
    </div>
    <div className='w-5/6 bg-[#ECF3FA]'>
        <Navbar/>
        {
            componentToRender
        }
    </div>

</div>  )
}

export default Payment