import React, {useState, useContext } from 'react'
import {BsPencilSquare, BsTrash} from 'react-icons/bs'
import UnauthorisedAccess from '../../pages/UnauthorisedAccess'
import Button from './Button'
import ConfirmDeletion from './ConfirmDeletion'
import EditRole from './EditRole'
import GithubContext from '../../../context/user/userContext'

const Roles = ({role, deleteRole, allPermissions, updateRole}) => {
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [isEditing, setIsEditing] = useState(false)

    const [unauthorisedUser, setUnauthorisedUser] = useState(false);

    const githubContext = useContext(GithubContext);
    const { getRole, getUsersRoleAlongWithPermissions, permissions  } = githubContext;
    
    const user = localStorage.getItem("user");
    const parseUser = JSON.parse(user);
    const id = parseUser.id

    const arr = [];

    const formNewArrayFromUserPermissionContext = () =>{
      permissions.map((ed)=>{
        return ed.permissions.map((edd)=>{
          return arr.push(edd.name)
  
        })
  
      })

    }
    formNewArrayFromUserPermissionContext()  

    const handleCancle = () => {
        setConfirmDelete(!confirmDelete);

    }

    const cancelEditRoleForm = () => {
        setIsEditing(false);

    }

    const deleteBtnClicked = () => {
        getRole(id);   
        getUsersRoleAlongWithPermissions()
        if (arr.includes('can_delete_roles')) {
            setConfirmDelete(true)                    
        }
        else{
            setUnauthorisedUser(!unauthorisedUser)        
        }

    }

    const EditBtnClicked = () => {
        getRole(id);   
        getUsersRoleAlongWithPermissions()
        if (arr.includes('can_edit_roles')) {
            setIsEditing(true)                    
        }
        else{
            setUnauthorisedUser(!unauthorisedUser)        
        }

    }

    const okayBtnClicked = () =>  {
        setUnauthorisedUser(!unauthorisedUser)        

    }

  return (
        <tr>
            <td className='text-left border-b border-t-2 text-sm text-gray-500'>{ role.name }</td>
            <td className='text-left border-b border-t-2 text-sm text-gray-500'>
                {/* {
                    role.permissions.map((permission)=>
                    (
                        <Permissions permission={permission} key={permission.id}/>
                    ))
                } */}
                <Button role={role} />
            </td>
            <td className="text-left border-t-2 px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <button
                    onClick={EditBtnClicked}
                    className="text-left flex text-green-500">
                    <BsPencilSquare className='mx-2'
                    />
                    <p>Edit</p>                                    
                </button>
                <button 
                    onClick={deleteBtnClicked}
                    className="flex justify-left items-left text-red-500">
                    <BsTrash className='mx-2'/>
                    <p>Delete</p>                                    
                </button>
            </td>
            <td>
            {
                confirmDelete ? <ConfirmDeletion text = "role" role={ role } deleteRole = {deleteRole} handleCancle={handleCancle}/> : null
            }

            </td>
            
            <td>
            {
                isEditing ? <EditRole 
                    text = "role" 
                    role={ role } 
                    cancelEditRoleForm={cancelEditRoleForm} 
                    allPermissions={allPermissions}
                    updateRole = {updateRole}
                    /> 
                    : null
            }

            </td>
            <td>
            {
                unauthorisedUser ? <UnauthorisedAccess okayBtnClicked={okayBtnClicked}/> : null
            }
            </td>

        </tr> 
    )
}

export default Roles