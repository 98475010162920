import React,{useContext, useEffect, useState} from 'react'
import {GrSettingsOption, GrFormAdd} from 'react-icons/gr'
import CollaborationContext from '../../../context/collaboration/collaborationContext'
import AddCollaborator from './AddCollaborator'
import Users from './Users'
import RoleContext from '../../../context/roles/roleContext'
import GithubContext from '../../../context/user/userContext'
import {IoPlayBackOutline} from 'react-icons/io5'
import UserPermissions from './UserPermissions'
import UnauthorizedPermissions from './UnauthorizedPermissions'


const ViewAllCollaborators = () => {
    const collaborationContext = useContext(CollaborationContext);
    const roleContext = useContext(RoleContext);
    const githubContext = useContext(GithubContext);

    const { 
        loading, 
        getSingleUser,
        adminPermissions,
        getAdminRoleAlongWithPermissions,
        getUsersRoleAlongWithPermissions,
        permissions,
        user_details,
        updateUser
    } = githubContext;

    const { 
        getAllRolesFromDB, 
        user_roles,
        } = roleContext;

    const { 
        collaborators, 
        loadingCollaborators,  
        getCollaborators,
        deleteCollaborator,
        updateCollaborator,
        setLoading
    } = collaborationContext;

    const [viewUsers, setviewUsers] = useState('')
    const [viewMyPermissions, setviewMyPermissions] = useState('none')
    const [displayCreateUserForm, setviewdisplayCreateUserForm] = useState(false)
    const [displayAddCollaboratorForm, setDisplayAddCollaboratorForm] = useState(false)

    const [isEditing, setIsEditing] = useState(false);
    const [groupFromContext, setGroupFromContext] = useState("")

    

    const userFromLocalStorage = localStorage.getItem("user");
    const parseUser = JSON.parse(userFromLocalStorage);
    const email = parseUser.email

    const goBackToViewUsers = (e) =>{
        e.preventDefault()
        setviewUsers('')
        setviewMyPermissions('none')

    }

    const viewRolesAndPermissions = (e) =>{
        e.preventDefault()
        setviewUsers('none')
        setviewMyPermissions('')

    }

    useEffect(() => {
        getCollaborators(email)
        getAllRolesFromDB()
        getUsersRoleAlongWithPermissions()
        getAdminRoleAlongWithPermissions()

      }, [email]);

      const arr = [];
    const adminPerm = [];



    const formNewArrayFromUserPermissionContext = () =>{
      permissions.map((ed)=>{
        ed.permissions.map((edd)=>{
          return arr.push(edd.name)
        })
    })}

    const formNewArrayFromAdminPermissionContext = () =>{
        adminPermissions.map((ed)=>{
            ed.permissions.map((edd)=>{
                return adminPerm.push(edd.name)
        })
    })}

    formNewArrayFromUserPermissionContext()
    formNewArrayFromAdminPermissionContext()

    const permissionNotHaving = adminPerm.filter(val => !arr.includes(val));


    


    const handleCancle = (e) => {
        setDisplayAddCollaboratorForm(false)
        // if (displayAddCollaboratorForm) {
        //     setDisplayAddCollaboratorForm(false)
            
        // }else{
        //     setviewdisplayCreateUserForm(false)
        // }
        
    }


    if (loadingCollaborators) return <div>Loading...</div>


  return (
<div className='block md:flex items-center justify-center mx-2'>
        <div className='flex flex-col w-full my-2 md:mx-2 h-[28rem]'>
            <div className='border-4 border-gray-300 h-[28rem]' style={{display: viewUsers}}>
                <div className='flex justify-between my-8 mr-16'>
                    <div className='flex justify-start'></div>
                    <div className='flex justify-end'>
                        <button 
                        onClick={viewRolesAndPermissions} 
                        className="flex justify-center items-center bg-gray-200 mx-2 h-10">
                            <GrSettingsOption className='mx-1'/>
                            <p className='px-1'>view roles and permissions</p>                                    
                        </button>
                        <button  
                        onClick={() => setDisplayAddCollaboratorForm(true)} 
                        className="flex justify-center items-center bg-[#00B050] mr-16 w-32 text-white h-10">
                            <GrFormAdd className='text-xl mx-1 bg-white'/>
                            <p className='px-1 text-xs'>Add User</p>                                    
                        </button> 
                        {/* {
                            groupFromContext === 'Admi' ? 
                                <button  onClick={createUser} className="flex justify-center items-center bg-[#00B050] mr-16 w-32 text-white h-10">
                                    <GrFormAdd className='text-xl mx-1 bg-white'/>
                                    <p className='px-1 text-xs'>Add Admin user</p>                                    
                                </button> 
                          :
                                <button  onClick={createCpmUser} className="flex justify-center items-center bg-[#00B050] mr-16 w-32 text-white h-10">
                                    <GrFormAdd className='text-xl mx-1 bg-white'/>
                                    <p className='px-1 text-xs'>Add CPM user</p>                                    
                                </button> 
                        } */}
                                       
                    </div>

                </div>
                <div className="overflow-y-scroll h-96 mx-4">
                    <table className="table border-collapse min-w-full h-90 px-2">
                    <thead>
                        <tr className='text-center'>
                            <th className="text-xs font-bold tracking-wider">
                                Customer ID
                            </th>
                            <th className="text-xs font-bold text-gray-700">
                                Email
                            </th>
                            <th className="py-2 text-xs font-bold text-gray-700 tracking-wider">
                                Full Name
                            </th>
                            <th className="py-2 text-xs font-bold text-gray-700 tracking-wider">
                                User Group
                            </th>
                            <th className="py-2 text-xs font-bold text-gray-700 tracking-wider">
                                Role(s)
                            </th>
                            <th className="py-2 text-xs font-bold text-gray-700 tracking-wider">
                                Wallet
                            </th>
                        </tr>
                    </thead>
                    <tbody className=''>
                        {
                            collaborators.length > 0
                            ?
                            collaborators.map((user) => (
                                    <Users 
                                    user={user} 
                                    key={user.id} 
                                    deleteCollaborator={deleteCollaborator}
                                    // getSingleUser={getSingleUser} 
                                    // handleEdit={handleEdit} 
                                    // deleteUser={deleteUser}
                                    user_roles={user_roles} 
                                    updateUser={updateCollaborator}
                                    />
                                
                                ))
                            :
                            <tr>
                                <td colSpan="5" className="text-center">No Collaborators Found.</td>
                            </tr> 
                        }
                    
                    </tbody>
                    </table>
                    <div>
                        {
                            displayAddCollaboratorForm &&
                            <AddCollaborator 
                                user_roles={user_roles} 
                                handleCancle={handleCancle}
                            /> 
                        }
                    </div>
                    {/* <div>
                        {
                            displayCpmCreateUserForm &&
                            <AddUserCpm 
                                user_roles={user_roles} 
                                handleCancle={handleCancle}

                            />
                        }
                    </div>
                    <div>
                        {
                            isEditing ? <UserEdit user={user} cancelEditForm={cancelEditForm} user_roles={user_roles} /> : null
                        }
                    </div> */}
                </div>
            </div>
            <div className='' style={{display: viewMyPermissions}}>
                <button onClick={goBackToViewUsers} className="flex justify-center items-center h-10">
                    <IoPlayBackOutline className='mx-1 text-xl'/>
                    <p className='px-1'>Back</p>                                    
                
                </button>
                <div className='border-4 border-gray-300'>
                    <div className='flex flex-col justify-center my-4 mr-16'>
                        <div className="flex justify-center items-center font-semibold">
                            <p className='px-1'>Hello, {user_details.first_name} {user_details.last_name}</p>                                    
                        </div>  
                        <div className="flex justify-center items-center h-10">
                            <p className='px-1'>Thses are your roles and associated permissions</p>                                    
                        </div>             
                    </div>
                    <div className='flex '>
                        <div className='flex flex-col w-1/2'>
                            <h3 className='flex justify-center items-center bg-[#E2F0D9] mx-4 h-12'>What you can access</h3>
                            <div className="overflow-y-scroll h-80 mx-4">
                                <table className="table border-collapse min-w-full h-80 px-2">
                                {
                                    permissions.length > 0 ?
                                    permissions.map((permission)=>(
                                        <UserPermissions permission={permission} key={permission.id} />
                                    
                                ))  : 
                                <tr>
                                    <td colSpan="5" className="text-center">No Users Found.</td>
                                </tr> 
                                }
                                </table>
                            </div>
                        </div>
                        <div className='flex flex-col w-1/2'>
                            <h3 className='flex justify-center items-center bg-red-300 mx-4 h-12'>What you can't access</h3>
                            <div className="overflow-y-scroll h-80 mx-4">
                                <table className="table border-collapse min-w-full h-80 px-2">
                                        <thead>

                                            {/* <tr className='border'>
                                                <th className="flex justify-center items-center bg-red-300 h-12">
                                                    What you can't access
                                                </th>
                                            </tr> */}
                                        </thead>
                                        <tbody className='overflow-y-scroll border'>
                                        {
                                                permissionNotHaving.length
                                                ?
                                                permissionNotHaving.map((unassignedPermissions, index) => (
                                                    <UnauthorizedPermissions unassignedPermissions={unassignedPermissions} key={index}/>
                                                ))
                                                :
                                                <tr>
                                                    <td colSpan="5" className="text-center">
                                                        <h3 className='font-semibold'>You have full access!</h3>
                                                        <p className='text-gray-400'>You can access all sections of the dashboard</p>
                                                    </td>
                                                </tr> 
                                            }
                                        
                                        </tbody>
                                </table>
                            </div>
                        </div>
                      

                    </div>
                </div>
              
            </div>

        </div>
    </div>  )
}

export default ViewAllCollaborators