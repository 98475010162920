import React from 'react'
import Navbar from '../Navbar'
import Sidebar from '../Sidebar'
import Footer from '../Footer'
import Nav from './Nav'
import ViewAllSenderIds from './ViewAllSenderIds'
const Index = () => {
  return (
    <div className='hidden md:block lg:block h-screen'>
          <div className='flex'>
            <div className='hidden md:block md:w-1/6 bg-[#394975] h-[48rem] text-white'>
              <Sidebar/>
            </div>
            <div className='w-full md:w-5/6 bg-[#ECF3FA]'>
                <Navbar/>
                <div className='bg-white mt-8 mx-10 pb-2'>
                    <Nav/>
                    <ViewAllSenderIds/>
                </div>
              
              
            </div>
          </div>
          <Footer/>

      </div>  )
}

export default Index