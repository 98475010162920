import React, {useEffect, useState} from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import Register from "./components/Register";
import Home from "./components/Bam/Home";
import SendBulkSms from "./components/Bam/SendBulkSms";
import Index from "./components/Bam/SendBulkExcel/Index";
import UserState from "./context/user/UserState";
import Homepage from "./components/usermanager/Homepage";
import Teams from "./components/usermanager/teams/Teams";
import RoleState from "./context/roles/RoleState";
import Roles from './components/usermanager/roles/Index'
import Permissions from './components/usermanager/permissions/Index'
import SenderIDs from './components/usermanager/senderids/Index'
import Collaboration from './components/usermanager/collaboration/Index'
import Csmanager from './components/usermanager/cs-manager/Index'
import SenderIds from './components/SenderIds/Index'
import Report from './components/Bird/Index'
import Dlr from './components/Bird/Dlrs/Dlr'
import PermissionState from "./context/permissions/PermissionState";
import CollaborationState from "./context/collaboration/CollaborationState";
import SenderIdState from "./context/senderids/SenderIdState";
import ReportState from "./context/reports/ReportState";
import ForgetPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import Info from "./components/Info";
import SuccessInfo from "./components/SuccessInfo";
import ProtectedRoute from "./ProtectedRoute";
import UserContext from './context/user/userContext'
import SendingType from "./components/Bam/SendingType";
import UploadType from "./components/Bam/UploadType";
import Single from "./components/Bam/SingleSms/Single";
import OperationSuccess from "./components/OperationSuccess";
import Payment from "./components/Payment/Payment";
import PaymentSuccess from "./components/Payment/Payment";
import PaymentHistory from "./components/Payment/Payment";
import Priceslist from './components/usermanager/Priceslist/Index'
function App() {
  const token = localStorage.getItem("token");
  const [permissions, setPermissions] = useState([])

 

  async function loginUser() {
    return fetch('http://127.0.0.1:8000/api/user/permissions', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,

      },
    })
      .then(data => data.json())
  }

  useEffect(() => {
    const  makeReq= async ()=> {
      const response = await loginUser();       
      setPermissions(response.permissions)

    }
    if (permissions.length == 0) {
      makeReq();
    }    


  }, [permissions])

  

  return (
    <ReportState>
    <SenderIdState>
    <CollaborationState>
       <PermissionState>
        <RoleState>
          <UserState>
              <Routes>
                <Route path='/login' element={ <Login/>}/>
                <Route path='/' element={<Register/>}/>
                <Route path='/signup' element={<Register/>}/>
                <Route path='/forget-password' element={ <ForgetPassword/>}/>
                <Route path='/password_reset' element={ <Info/>}/>
                <Route path='/reset-success' element={ <SuccessInfo/>}/>
                <Route path='/reset-password/:email/:token' element={ <ResetPassword/>}/>

                <Route element={<ProtectedRoute isAllowed={!!token}/>}>
                  <Route path='/dashboard' element={<Dashboard />}/>
                  <Route path='/senderid' element={ <SenderIds/>}/>
                  <Route path='/usermanager' element={ <Homepage/>}/>
                  <Route path='/report' element={ <Report/>}/>
                  <Route path='/report/dlr' element={ <Dlr/>}/>
                  <Route path='/usermanager/csmanager' element={ <Csmanager/>}/>
                  <Route path='/bam' element={<Home />}/>
                  <Route path='/bam/sendsms/excel'element={<Index/>}/>
                  <Route path='/bam/sendsms' element={<SendBulkSms />}/>
                  <Route path='/bam/sms' element={<UploadType />}/>
                  <Route path='/bam/sms/singlesms' element={<Single />}/>
                  <Route path='/operation/success' element={ <OperationSuccess/>}/>

                  <Route path='/payment' element={ <Payment/>}/>
                  <Route path='/payment/success' element={ <PaymentSuccess/>}/>
                  <Route path='/payment/history' element={ <PaymentHistory/>}/>



              </Route>
              <Route
                path="/usermanager/collaboration"
                element={
                  <ProtectedRoute
                    redirectPath="/dashboard"
                    isAllowed={
                      !!token && permissions.includes('can_read_collaborators')
                    }
                  >
                    <Collaboration />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/usermanager/team"
                element={
                  <ProtectedRoute
                    redirectPath="/dashboard"
                    isAllowed={
                      !!token && permissions.includes('can_read_users')
                    }
                  >
                    <Teams />
                  </ProtectedRoute>
                }
              />
               <Route
                path="/usermanager/roles"
                element={
                  <ProtectedRoute
                    redirectPath="/dashboard"
                    isAllowed={
                      !!token && permissions.includes('can_read_roles')
                    }
                  >
                    <Roles />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/usermanager/permissions"
                element={
                  <ProtectedRoute
                    redirectPath="/dashboard"
                    isAllowed={
                      !!token && permissions.includes('can_read_permissions')
                    }
                  >
                    <Permissions />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/usermanager/senderids"
                element={
                  <ProtectedRoute
                    redirectPath="/dashboard"
                    isAllowed={
                      !!token && permissions.includes('can_read_sender_id')
                    }
                  >
                    <SenderIDs />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/usermanager/pricelist"
                element={
                  <ProtectedRoute
                    redirectPath="/dashboard"
                    isAllowed={
                      !!token && permissions.includes('can_read_users')
                    }
                  >
                    <Priceslist />
                  </ProtectedRoute>
                }
              />
          </Routes>
        </UserState>
      </RoleState>
    </PermissionState>
    </CollaborationState>
    </SenderIdState>
    </ReportState>

  );
}

export default App;