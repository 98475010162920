import React, {useEffect, useState, useContext} from 'react'
import Navbar from '../Navbar'
import Sidebar from './Sidebar'
import Navigations from './Navigations'
import  Profile  from './Profile'
import GithubContext from '../../context/user/userContext';
import Footer from '../Footer'



const Homepage = () => {
  const githubContext = useContext(GithubContext);
  const { getRole, loading, getUsersRoleAlongWithPermissions, permissions  } = githubContext;
  
  const user = localStorage.getItem("user");
  const parseUser = JSON.parse(user);
  const id = parseUser.id


  const currentUserPermission = [];

  const formNewArrayFromUserPermissionContext = () =>{

    permissions.map((ed)=>{
      ed.permissions.map((edd)=>{
        return currentUserPermission.push(edd.name)

      })
    })

  }
  formNewArrayFromUserPermissionContext()  

  useEffect(() => {
      getRole(id);   
      getUsersRoleAlongWithPermissions()

  }, [id]);

  // if (loading) return <div>Loading</div>

  return (
    <>
      <div className='flex font-roboto'>
        <div className='hidden md:block md:w-1/6 bg-[#394975] h-[49rem] text-white'>
          <Sidebar 
            currentUserPermission={currentUserPermission} 
            loading={loading}
          />
        </div>
        <div className='w-full md:w-5/6 bg-[#ECF3FA]'>
          <Navbar/>          
          <div className='bg-white mt-8 mx-10 pb-2'>
            <Navigations 
              currentUserPermission={currentUserPermission}
            />
          <Profile/>
          </div>
         
        </div>
      </div>
      <Footer/>
    </>
  )
}

export default Homepage