import React from 'react'
import { Link } from 'react-router-dom'

const Sidebar = () => {
  return (
    <div>
        <ul className='flex flex-col justify-center mt-20 align-center font-roboto'>
          <div className=''>
            <Link to="/dashboard">
              <li className='border-b border-t border-gray-500 pl-8 py-1  bg-gray-500 '>My Workspace</li>
            </Link>
            <Link to="/bam/sms">
              <li className='border-b border-t border-gray-500 pl-8 py-1'>Send SMS</li>
            </Link>
            <li className='border-b pl-8 py-1 border-gray-500'>View Reports</li>
            <Link to='/payment'>
              <li className='border-b pl-8 py-1 border-gray-500'>Add Funds</li>

            </Link>
            <li className='border-b pl-8 py-1 border-gray-500'>Get Help</li>
          </div>
        </ul>
        <ul className='flex flex-col justify-center align-center font-roboto'>
          <div className=''>
            <li className='border-b border-t border-gray-500 pl-8 py-1  bg-gray-500 '>Account Settings</li>
            <Link to="/usermanager">
              <li className='border-b border-t border-gray-500 pl-8 py-1'>Account Details</li>
            </Link>
            <Link to="/senderid">
              <li className='border-b pl-8 py-1 border-gray-500'>Sender IDs</li>
            </Link>      
          </div>
          
        </ul>
        
    </div>
  )
}

export default Sidebar