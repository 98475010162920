import { upload } from '@testing-library/user-event/dist/upload';
import React, {useState} from 'react'
import {FaTimes} from 'react-icons/fa'
import { read, utils } from 'xlsx';

const UploadSuccessful = ({gotoCancelSuccessModal}) => {


   
  return (
    <>
    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-auto my-6 mx-auto max-w-3xl">
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
          <div className="flex items-start justify-between p-5 border-b border-solid border-slate-300 rounded-t">
            <div></div>
            <h3 className="text-2xl justify-center text-gray-500 font-semibold">
                Confirmation of Price List Override

            </h3>
            <div 
              onClick={gotoCancelSuccessModal} 
              className="flex items-center justify-center h-8 hover:cursor-pointer text-3xl font-semibold">
              <FaTimes className='flex justify-center items-center fill-gray-500 text-xl'/>
            </div>
          </div>
          <div className="relative p-6 flex-auto">
            <div className="flex flex-col justify-center items-center my-4 text-slate-500 text-lg leading-relaxed h-24 w-[30rem]">
                <p className="text-slate-500 text-lg leading-relaxed">
                    You have successfully updated the
                </p>
                <p className="text-slate-500 text-lg leading-relaxed">
                    Global Standard Pricing List on <span className='text-blue-500'>Time & Date</span> 
                </p>
                <div>                
                </div>
            </div>
        </div>
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black">
    </div>
    </>  
    )
}

export default UploadSuccessful