import React, {useEffect, useState, useContext} from 'react';
import { Link } from 'react-router-dom'
import GithubContext from '../../../context/user/UserState';



const Navigations = () => {
  return (
    <>
      <div className='flex justify-between bg-white py-2  border-b'>
      <div className="justify-start h-8 ml-6 mb-2 py-4 text-mutani text-xl font-bold">
            User Management
          </div>
          <div className='flex justify-end mr-12 my-4'>
            <div className='flex justify-center items-center mr-4 space-x-4'>
              <p className='text-[#757575]'>SMS Wallet Balance</p>
              <span className='bg-[#00B050] h-8 w-16 flex justify-center items-center rounded'>655</span>
              <button className='bg-[#ffc000] h-8 w-16 flex justify-center items-center rounded'>Top Up</button>
            </div>
          </div>
      </div>
      <nav className=''>

      <ul className='flex justify-start items-center my-2'>
          <li className='flex justify-center items-center mx-4 h-16 w-16'><Link to="/usermanager">Profile</Link></li>
          <li className='flex justify-center items-center mx-2 h-12 w-32 bg-[#ECF3FA]'><Link to="/usermanager">Collaboration​</Link></li>
          <li className='flex justify-center items-center mx-2 h-16 w-28'><Link to="/usermanager">Preferences</Link></li>
          <li className='flex justify-center items-center mx-2 h-16 w-16'><Link to="/usermanager">Apps</Link></li>
          <li className='flex justify-center items-center mx-2 h-16 w-32'><Link to="/usermanager">Payment & Billing​</Link></li>
          <li className='flex justify-center items-center mx-2 h-16 w-18'><Link to="/usermanager">API Keys​</Link></li>
          <li className='flex justify-center items-center mx-2 h-16 w-40'><Link to="/usermanager">Customer Success​</Link></li>
          <li className='flex justify-center items-center mx-2 h-16 w-32'><Link to="/usermanager">Terms & Privacy​</Link></li>

      </ul>
      </nav>
    </>
  )
}

export default Navigations