import React, {useState} from 'react'
import {BsPencilSquare, BsTrash} from 'react-icons/bs'
import EditSenderIds from './EditSenderIds';

const SenderIds = ({senderIds, updateSenderId, user_mails}) => {
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [isEditing, setIsEditing] = useState(false);
    const [unauthorisedUser, setUnauthorisedUser] = useState(false);

    const EditBtnClicked = () => {
        // getRole(id);   
        setIsEditing(true)                    

        // getUsersRoleAlongWithPermissions()
        // if (arr.includes('can_edit_permissions')) {
        //     setIsEditing(true)                    
        // }
        // else{
        //     setUnauthorisedUser(!unauthorisedUser)        
        // }
    }

    const cancelEditSenderIdForm = () => {
        // getRole(id);   
        setIsEditing(false)                    

        // getUsersRoleAlongWithPermissions()
        // if (arr.includes('can_edit_permissions')) {
        //     setIsEditing(true)                    
        // }
        // else{
        //     setUnauthorisedUser(!unauthorisedUser)        
        // }
    }

  return (
    <tr>
            <td className='text-center border-b border-t-2 text-sm text-gray-500'>
                <p className='text-center text-gray-500 text-sm py-2'>{senderIds.email}</p>
            </td>
            <td className='text-center border-b border-t-2 text-sm text-gray-500'>
                <p className='text-center text-gray-500 text-sm py-2'>{senderIds.sender_id}</p>
            </td>
            <td className="text-center border-t-2 px-5 py-3 border-b border-gray-200 bg-white text-sm">
                <button
                    onClick={EditBtnClicked}
                    className="text-center flex text-green-500">
                    <BsPencilSquare className='mx-2'/>
                    <p>Edit</p>                                    
                </button>
                <button 
                    // onClick={deleteBtnClicked} 
                    className="flex justify-center items-center text-red-500">
                    <BsTrash className='mx-2'/>
                    <p>Delete</p>                                    
                </button>
            </td>
            {/* <td>
            {
                confirmDelete ? <ConfirmDeletion text = "permission" permission={ permission } deletePermission = {deletePermission} handleCancle={handleCancle}/> : null
            }

            </td> */}
            

            <td>
            {
                isEditing ? <EditSenderIds 
                senderIds={senderIds} 
                cancelEditSenderIdForm={cancelEditSenderIdForm} 
                updateSenderId={updateSenderId}
                user_mails={user_mails}
                /> : null
            }
            </td>

            {/* <td>
            {
                unauthorisedUser ? <UnauthorisedAccess okayBtnClicked={okayBtnClicked}/> : null
            }
            </td> */}

        </tr>
  )
}

export default SenderIds