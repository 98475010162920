import {
    GET_ALL_SENDER_IDS,
    GET_ALL_USER_MAILS,
    ADD_SENDER_IDS,
    SET_LOADING,
    SET_SENDER_IDS_LOADING_OFF,
    SEARCH_SENDER_ID,
    GET_USER_SENDER_ID_BY_EMAIL
  } from '../types';
  
  
  
  export default (state, action) => {
    switch (action.type) {   
      case GET_ALL_SENDER_IDS: {
        return {
          ...state,
          sender_id: action.payload,
          loadSenderIds: false
        };
      }
  
      case ADD_SENDER_IDS: {
        return {
          ...state,
          new_sender_id: action.payload,
          loadSenderIds: false
        };
      }

      case GET_ALL_USER_MAILS: {
        return {
          ...state,
          user_mails: action.payload,
          loadSenderIds: false
        };
      }

      case GET_USER_SENDER_ID_BY_EMAIL: {
        return {
          ...state,
          userSenderIds: action.payload,
          loadSenderIds: false
        };
      }

      case SEARCH_SENDER_ID: {
        return {
          ...state,
          foundSenderIds: action.payload,
          loadSenderIds: false
        };
      }
  
      case SET_LOADING:
        return {
          ...state,
          loadSenderIds: true
        };
        case SET_SENDER_IDS_LOADING_OFF:
        return {
          ...state,
          loadSenderIds: false
        };
      default:
        return state;
    }
  };
  