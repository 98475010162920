import React, { useReducer } from 'react';
import axios from 'axios';
import RoleContext from './roleContext';
import RoleReducer from './roleReducer';
import { useNavigate } from 'react-router-dom';

import {
  SET_LOADING,
  GET_ALL_ROLES,
  GET_ROLE_PERMISSION_BY_ID,
  SET_ROLES_LOADING_OFF,
  SEARCH_ROLE,
  token
} from '../types';

const RoleState = ({children}) => {
  
  const navigate = useNavigate()
  const initialState = {
    user_roles: [],
    role_permission: [],
    foundRole: [],
    loadding: false
  };

  const [state, dispatch] = useReducer(RoleReducer, initialState);
  
  const token = localStorage.getItem("token");

  const searchRole = (name) => {
    setLoading();
    const headers = {
         accept: 'application/json',
         Authorization: 'Bearer ' + token
     }
     axios.get(`https://apis.mutani.co/api/roles/search/${name}`, {
        headers: headers
    })
    .then((response) => {
      const data = response.data.user
      // const { email, first_name, last_name, phone, country, user_group} = data
      dispatch({
        type: SEARCH_ROLE,
        payload: data

        
        // payload: {email,first_name, last_name, phone, country, user_group}
      });
    })
    .catch(function (error) {
        console.error(error);
        dispatch({
          type: SET_ROLES_LOADING_OFF
        });
    });
  }

  const getAllRolesFromDB = () => {
    setLoading();
    const headers = {
         accept: 'application/json',
         Authorization: 'Bearer ' + token
     }
     axios.get(`https://apis.mutani.co/api/roles`, {
        headers: headers
    })
    .then((response) => {
      const data = response.data
      const { roles } = data

      //console.log(roles)

      dispatch({
        type: GET_ALL_ROLES,
        payload: roles
      });     
    })
    .catch(function (error) {
        console.error(error);
    });
  }

  const getRolesPermissionsById = (id) => {
    setLoading();
    const headers = {
         accept: 'application/json',
         Authorization: 'Bearer ' + token
     }
     axios.get(`https://apis.mutani.co/api/roles/${id}`, {
        headers: headers
    })
    .then((response) => {
      const data = response.data
      const { role } = data

      console.log(data)

      dispatch({
        type: GET_ROLE_PERMISSION_BY_ID,
        payload: role
      });     
    })
    .catch(function (error) {
        console.error(error);
    });
  }

  const deleteRole = (id) => {
    setLoading();
    const headers = {
         accept: 'application/json',
         Authorization: 'Bearer ' + token
     }
     axios.delete(`https://apis.mutani.co/api/roles/${id}`, {
        headers: headers
    })
    .then((response) => {
      const data = response.data
      if ('Success' in data) {
        navigate(0)
        
      }

      dispatch({
        type: SET_ROLES_LOADING_OFF
      });

      navigate(0)


      console.log(data)
      
    })
    .catch(function (error) {
        console.error(error);
    });
  }

  const  updateRole = async (credentials, id) => {
    const headers = {
         'Content-Type': 'application/json', 
         Authorization: 'Bearer ' + token,    
     }
     axios.post(`https://apis.mutani.co/api/roles/${id}`, 
     JSON.stringify(credentials),
     {
        headers: headers,
        // withCredentials: true

    })
    .then((response) => {
      const data = response.data
      if ('Success' in data) {
        navigate(0)
        
      }

      dispatch({
        type: SET_ROLES_LOADING_OFF
      });

      navigate(0)          
    })
    .catch(function (error) {
        console.error(error);
        dispatch({
          type: SET_ROLES_LOADING_OFF
        });        
    });
  }

  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <RoleContext.Provider
    value={{
      role_permission: state.role_permission,
      user_roles: state.user_roles,
      loadding: state.loadding,
      foundRole: state.foundRole,
      getAllRolesFromDB,
      getRolesPermissionsById,
      deleteRole,
      updateRole,
      searchRole
    }}
  >
    {children}
  </RoleContext.Provider>  )
}

export default RoleState;
