import {
    GET_ALL_COLLABORATORS,
    SET_COLLABORATION_LOADING,
    SET_COLLABORATION_LOADING_OFF
    
    
  } from '../types';
  
  export default (state, action) => {
    switch (action.type) {   
      case GET_ALL_COLLABORATORS: {
        return {
          ...state,
          collaborators: action.payload,
          loadingCollaborators: false
        };
      }
      case SET_COLLABORATION_LOADING:
        return {
          ...state,
          loadingCollaborators: true
        };
      case SET_COLLABORATION_LOADING_OFF: {
        return {
          ...state,
          loadingCollaborators: false
  
        };
      }
      default:
        return state;
    }
  };
  