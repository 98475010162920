import React,{useContext, useEffect, useState } from 'react'
import SenderIdContext from '../../../context/senderids/senderidContext'
import {GrFormAdd} from 'react-icons/gr'
import SenderIds from './SenderIds';
import AddSenderId from './AddSenderId';
import Search from './Search';

const ViewAllSenderIds = () => {
    const senderIdContext = useContext(SenderIdContext);

    const [viewSenderId, setViewSenderId] = useState("")
    const [displayAddSenderIdForm, setDisplayAddSenderIdForm] = useState(false)
    const [inputSenderId, setInputSenderId] = useState("")
    const [displaySenderIdsTable, setDisplaySenderIdsTable] = useState("")
    const [displayViewSearchResults, setViewSearchResults] = useState('none')
    const [displayAddSenderBtn, setDisplayAddSenderBtn] = useState("")
    const [displayViewAllSenderIdsBtn, setdisplayViewAllSenderIdsBtn] = useState("none")

    const { 
        user_mails,
        getSenderIds, 
        addSenderIdToUser,
        updateSenderId,
        getAllUsersMails,
        foundSenderIds,
        sender_id,
        addSender,
        searchSenderIds,
        sendReviewSenderIdResponseToUser,
        sendRejectSenderIdResponseToUser

        } = senderIdContext;


    useEffect(() => {
        getSenderIds()
        getAllUsersMails()
    
        },[])


        const addSenderId = () => {
            setViewSenderId("none")
            setDisplayAddSenderIdForm(true)
    
        }

        const handleCancle = () => {
            setViewSenderId("")
            setDisplayAddSenderIdForm(false)
    
        }

        const onSenderIdChange = (e) => {setInputSenderId(e.target.value)}

        const onSearchButtonClick = e =>{
            e.preventDefault();
            if (inputSenderId === '') {
                console.log('Please enter something');
            } else {
                searchSenderIds(inputSenderId);
                setDisplaySenderIdsTable('none')
                setViewSearchResults('')
                // setDisplaySenderIdsTable('')
                setDisplayAddSenderBtn('none')
                // setViewSearchResults("")
                // setInputSenderId('');
                setdisplayViewAllSenderIdsBtn('')
            }
        
        }

        const goBackToViewUsersTable = () =>
        {
            setDisplaySenderIdsTable('')
            setDisplayAddSenderBtn('')
            setViewSearchResults("none")
            setdisplayViewAllSenderIdsBtn('none')
        }

        // console.log(sender_id)


  return (
<div className='block md:flex items-center justify-center mx-2'>
      <div className='flex flex-col w-full my-2 md:mx-2 h-[28rem]'>
          <div className='border-4 border-gray-300 h-[28rem]'>
              <div 
              style={{ display: viewSenderId }}
              >
                  <div className='flex justify-between my-8 mr-16'>
                      <div className='flex justify-start'>
                      <Search 
                          searchSenderIds={searchSenderIds} 
                          onSearchButtonClick = {onSearchButtonClick}
                          inputSenderId = {inputSenderId}
                          onSenderIdChange = {onSenderIdChange}
                        />
                      </div>
                      <div className='flex justify-end' style={{display : displayAddSenderBtn}}>
                          <button 
                          onClick={addSenderId} 
                          className="flex justify-center items-center bg-[#00B050] mr-16 w-40 text-white h-10">
                              <GrFormAdd className='text-xl mx-1 bg-white'/>
                              <p className='px-1 text-xs'>Add Sender IDs</p>                                    
                          </button>                
                      </div>
                      <div className='flex justify-end' style={{display : displayViewAllSenderIdsBtn}}>
                          <button onClick={goBackToViewUsersTable}                           className="flex justify-center items-center bg-[#00B050] mr-16 w-40 text-white h-10">
                              <GrFormAdd className='text-xl mx-1 bg-white'/>
                              <p className='px-1 text-xs'>View All Senders IDs</p>                                    
                          </button>                
                      </div>

                  </div>
                  {/* <p className='flex justify-center items-center mb-2 font-bold tracking-wider text-lg'>Sender IDs</p> */}
                  <div style={{display: displaySenderIdsTable}} 
                    className="overflow-y-scroll h-80 mx-4">
                      <table className="table border-collapse min-w-full h-80 px-2">
                        <thead className=''>
                            <tr className='text-center'>
                                <th className="flexfont-bold tracking-wider text-lg">
                                    Email
                                </th>
                                <th className="flexfont-bold tracking-wider text-lg">
                                    Sender IDs
                                </th>
        
                            </tr>
                        </thead>
                        <tbody className=''>
                        {
                                sender_id.length > 0
                                ?
                                sender_id.map((senderIds) => (
                                      <SenderIds 
                                        key={senderIds.id} 
                                        senderIds={senderIds} 
                                        user_mails={user_mails}
                                        // deletePermission={deletePermission} 
                                        updateSenderId={updateSenderId}
                                      />
                                ))
                                :
                                <tr>
                                    <td colSpan="5" className="text-center">No SenderIDs Found.</td>
                                </tr> 
                        }
                        
                        </tbody>
                      </table>
                      
                  </div>
                  <div style={{display: displayViewSearchResults}} className="overflow-y-scroll h-80 mx-4">
                      <table className="table border-collapse min-w-full h-80 px-2">
                        <thead className=''>
                            <tr className='text-center'>
                                <th className="flexfont-bold tracking-wider text-lg">
                                    Email
                                </th>
                                <th className="flexfont-bold tracking-wider text-lg">
                                    Sender IDs
                                </th>
        
                            </tr>
                        </thead>
                        <tbody className=''>
                        {
                                foundSenderIds.length > 0
                                ?
                                foundSenderIds.map((senderIds) => (
                                      <SenderIds 
                                        key={senderIds.id} 
                                        senderIds={senderIds} 
                                        user_mails={user_mails}
                                        // deletePermission={deletePermission} 
                                        updateSenderId={updateSenderId}
                                      />
                                ))
                                :
                                <tr>
                                    <td colSpan="5" className="text-center">No SenderIDs Found.</td>
                                </tr> 
                        }
                        
                        </tbody>
                      </table>
                      
                  </div>
              </div>
              {
                  displayAddSenderIdForm ? 
                  <AddSenderId 
                  sendReviewSenderIdResponseToUser={sendReviewSenderIdResponseToUser}
                  sendRejectSenderIdResponseToUser={sendRejectSenderIdResponseToUser}
                  user_mails={user_mails}
                  addSenderIdToUser = {addSenderIdToUser} 
                  addSender={addSender}
                  handleCancle={handleCancle}/> : null
              }
             
            
          </div>
        
      </div>
    </div>   )
}

export default ViewAllSenderIds