import React from 'react'

const ShowPermissions = ({role_permission, hideViewPermission}) => {
  return (
    <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-6xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-center justify-center p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    Permissions
                  </h3>
                </div>
                <div className="relative p-6 flex-auto">
                    
                  <div className="my-4 text-slate-500 text-lg leading-relaxed h-80 w-[30rem] overflow-y-auto">
                    {
                        role_permission.length > 0 ?
                            role_permission.map((permission, index)=>
                            (
                                <p key={index + 1} className='border-b mx-10 my-1 text-center text-gray-500 text-xs py-2'>{permission}</p>

                            )) :
                        <p>No Permissions Found</p>
                    }
                  </div>
                </div>
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="bg-[#ffc000] active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={hideViewPermission}>
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
    )
}

export default ShowPermissions