import React from 'react'

const DlrReports = ({sub}) => {
  return (
    <tr key={sub.id}>
    <td className='text-left border-b border-t-2 text-sm text-gray-500'>{ sub.sender }</td>
    <td className='text-left border-b border-t-2 text-sm text-gray-500'>{ sub.msisdn }</td>
    <td className='text-left border-b border-t-2 text-sm text-gray-500'>{ sub.message }</td>
    <td className='text-left border-b border-t-2 text-sm text-gray-500'>{ sub.inserted_at }</td>
    <td className='text-left border-b border-t-2 text-sm text-gray-500'>{ sub.updated_at }</td>
    <td className='text-left border-b border-t-2 text-sm text-gray-500'>{ sub.status }</td>

  
    {/* <td>
    {
        confirmDelete ? <ConfirmDeletion text = "sub" sub={ sub } deletesub = {deletesub} handleCancle={handleCancle}/> : null
    }
    </td>
    <td>
    {
        isEditing ? 
        <subEdit 
            sub={sub} 
            cancelEditForm={cancelEditForm} 
            sub_roles={sub_roles} 
            updatesub={updatesub}
        /> : null
    }
        
    </td> */}
    

</tr>   )
}

export default DlrReports