import React from "react";
import { Link } from "react-router-dom";

const Dropdown = ({logout, email, name, customer_id, phone}) => {
  return (
    <>
        <header className="text-black bg-white shadow-md mt-8">
            {/* <nav className="container mx-auto px-4 flex items-center justify-between py-4"> */}
                <div className="relative">
                    <ul className="absolute font-normal bg-white shadow overflow-hidden rounded w-72 border mt-2 py-1 right-0 z-20"
                    >
                        <div className="flex flex-col  hover:bg-gray-200 px-3 py-3 border-b border-gray-200 pl-4">
                            <p className="">
                                <span className="">{name}</span>
                            </p>
                            <p className=" mt-2">
                                <span className="">Customer #: {customer_id}</span>
                            </p>
                        </div>
                        <div className="flex flex-col  hover:bg-gray-200 px-3 py-3 border-b border-gray-200 pl-4">
                            <p className="text-gray-500">REGISTERED DETAILS</p>
                            <p className="">
                                <span className="">Mobile #: {phone}</span>
                            </p>
                            <p className="">
                                <span className="">Email: {email}</span>
                            </p>
                        </div>
                      
                    
                        <li>
                            <Link to="#" className="flex items-center px-3 py-3 hover:bg-gray-200">
                                <span className="ml-2">My Apps</span>
                            </Link>
                        </li>
                        <li className="border-b border-gray-400">
                            <Link to="#" className="flex items-center px-3 py-3 hover:bg-gray-200">
                                <span className="ml-2">Account Settings</span>
                            </Link>
                        </li>
                        <li onClick={logout}>
                            <Link to="#" className="flex items-center px-3 py-3 hover:bg-gray-200">
                                <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="text-gray-600"><path d="M0 0h24v24H0z" fill="none"></path><path d="M10.09 15.59L11.5 17l5-5-5-5-1.41 1.41L12.67 11H3v2h9.67l-2.58 2.59zM19 3H5c-1.11 0-2 .9-2 2v4h2V5h14v14H5v-4H3v4c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path></svg><span className="ml-2">Logout</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            {/* </nav> */}
        </header>
    </>
  );
};

export default Dropdown
