import React from 'react'

const UserSenderIds = ({userSenderId, index}) => {
  return (
    <tr className='h-10'>
            <td className='text-center border-b border-t text-sm text-gray-500'>
                <p className='text-center text-gray-500 text-sm py-2'>{index + 1}</p>
            </td>
            <td className='text-center border-b border-t text-sm text-gray-500'>
                <p className='text-center text-gray-500 text-sm py-2'>{userSenderId.sender_id}</p>
            </td>
    </tr>
  )
}

export default UserSenderIds