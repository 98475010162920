import React, {useEffect, useState, useContext} from 'react';
import { Link } from 'react-router-dom'
import GithubContext from '../../../context/user/UserState';



const Navigations = () => {
  return (
    <nav className='border-b my-4'>
        <ul className='flex justify-start items-center my-8 w-full'>
            <Link to="/usermanager">
                <li className='flex justify-center items-center mx-2 h-16 w-32 px-4'>Profile</li>
              </Link>            
            <li className='flex justify-center items-center h-16 w-32 px-4'><Link to="#">Collaboration</Link></li>
            <li className='flex justify-center items-center h-16 w-32 px-4'><Link to="#">Preferences</Link></li>
            <li className='flex justify-center items-center h-16 w-28 '><Link to="#">Apps</Link></li>
            <li className='flex justify-center items-center h-16 w-44 px-4'><Link to="#">Payments & Biling</Link></li>
            <li className='flex justify-center items-center h-16 w-32 px-4'><Link to="#">API Keys</Link></li>
            <li className='flex justify-center items-center h-16 w-42 px-4  bg-[#f2f7fc]'><Link to="#">Customer Success</Link></li>
            <li className='flex justify-center items-center h-16 w-42 px-4'><Link to="#">Terms & Privacy</Link></li>

        </ul>
    </nav>
  )
}

export default Navigations