import React from 'react'
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { Link } from "react-router-dom";
import SendingType from './SendingType';

const Crumbs = () => {
  return (
    <div className='flex flex-col mx-6 py-2'>
        <SendingType/>
    </div>
  )
}

export default Crumbs