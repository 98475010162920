import React from 'react'
import {FaTimes} from 'react-icons/fa'

const Card = ({cancelCardPaymentModel, handleSubmit}) => {
  return (
    <>
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto my-6 mx-auto max-w-3xl">
        {/*content*/}
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex items-start justify-between p-5 border-b border-solid border-slate-300 rounded-t">
            <div></div>
            <h3 className="text-2xl justify-center text-gray-500 font-semibold">
              Card Payment
            </h3>
            <div onClick={cancelCardPaymentModel} className="flex items-center justify-center h-8 hover:cursor-pointer text-3xl font-semibold">
              <FaTimes className='flex justify-center items-center fill-gray-500 text-xl'/>
            </div>
          </div>
          {/*body*/}
          <div className="relative p-6 flex-auto">
            <form onSubmit={handleSubmit}>
                <div className="flex flex-col justify-center items-center mx-6">
                    <div className="flex flex-col">
                        <label htmlFor="first_name" className="mx-2 my-1">
                        Card Number*
                        </label>
                        <input
                        type="text"
                        name="first_name"
                        // value={first_name}
                        // onChange={(e) => setFirstName(e.target.value)}
                        className="border-gray-300 rounded border w-96 h-10 mx-2"
                        />
                    </div>
                    <div className="flex flex-col">
                        <label htmlFor="first_name" className="mx-2 my-1">
                        Expiration Date (MM/YY)
                        </label>
                        <input
                        type="text"
                        name="first_name"
                        // value={first_name}
                        // onChange={(e) => setFirstName(e.target.value)}
                        className="border-gray-300 rounded border w-96 h-10 mx-2"
                        />
                    </div>
                    <div className="flex flex-col">
                        <label htmlFor="first_name" className="mx-2 my-1">
                        CVV (3 digits)
                        </label>
                        <input
                        type="text"
                        name="first_name"
                        // value={first_name}
                        // onChange={(e) => setFirstName(e.target.value)}
                        className="border-gray-300 rounded border w-96 h-10 mx-2"
                        />
                    </div>
                </div>
            </form>
          </div>
          <div className="flex flex-col items-center justify-center p-6 border-t border-solid border-slate-300 rounded-b">
            <div className="flex flex-col">
                <label htmlFor="first_name" className="mx-2 my-1 font-bold">
                Enter Amount
                </label>
                <input
                type="text"
                name="first_name"
                // value={first_name}
                // onChange={(e) => setFirstName(e.target.value)}
                className="border-gray-300 rounded border w-96 h-10 mx-2"
                />
            </div>
            <button
              className="bg-[#00B050] text-white w-96 font-bold uppercase text-sm my-4 px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={handleSubmit}
            >
              Add Funds
            </button>
            
          </div>
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
</>  )
}

export default Card