import React from 'react'

const Button = ({text, clickToMakeButtonActive, color, activateBtn}) => {
  return (
    <div className='flex items-center text-black '>
        <button 
          disabled={activateBtn} 
          className='flex items-center justify-center border space-x-0 w-48 h-8 text-sm text-black' 
          onClick={clickToMakeButtonActive} 
          style={{backgroundColor: color}}>
          {text}
        </button>

    </div>
  )
}

export default Button