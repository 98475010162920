import React, {useReducer} from 'react'
import senderidsReducer from './senderidsReducer';
import SenderIdContext from './senderidContext'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import {
    GET_ALL_SENDER_IDS,
    ADD_SENDER_IDS,
    GET_ALL_USER_MAILS,
    SET_LOADING,
    SET_SENDER_IDS_LOADING_OFF,
    SEARCH_SENDER_ID,
    GET_USER_SENDER_ID_BY_EMAIL,
    // user_token
  } from '../types';


const SenderIdState = ({children}) => {
    const navigate = useNavigate()

    const initialState = {
        sender_id: [],
        user_mails: [],
        new_sender_id: [],
        foundSenderIds: [],
        userSenderIds: [],
        loadSenderIds: false
      };

    const [state, dispatch] = useReducer(senderidsReducer, initialState);

    const token = localStorage.getItem("token");

    const sendSenderIdNotificationToAdmin = (sender_id, email) => {
      setLoading();
      const headers = {
           accept: 'application/json',
           Authorization: 'Bearer ' + token
       }
       axios.get(`https://apis.mutani.co/api/send/notication/${sender_id}/${email}`, {
          headers: headers
      })
      .then((response) => {
        const data = response.data

        if ('success' in data) {
          navigate(0)
          
        }
      })
      .catch(function (error) {
          console.error(error);
          dispatch({
            type: SET_SENDER_IDS_LOADING_OFF
          }); 
      });
    }

    const sendReviewSenderIdResponseToUser = (sender_id, email) => {
      setLoading();
      const headers = {
           accept: 'application/json',
           Authorization: 'Bearer ' + token
       }
       axios.get(`https://apis.mutani.co/api/review/notication/${sender_id}/${email}`, {
          headers: headers
      })
      .then((response) => {
        const data = response.data

        if ('success' in data) {
          navigate(0)
          
        }
      })
      .catch(function (error) {
          console.error(error);
          dispatch({
            type: SET_SENDER_IDS_LOADING_OFF
          }); 
      });
    }

    const sendRejectSenderIdResponseToUser = (sender_id, email) => {
      setLoading();
      const headers = {
           accept: 'application/json',
           Authorization: 'Bearer ' + token
       }
       axios.get(`https://apis.mutani.co/api/send/reject/${sender_id}/${email}`, {
          headers: headers
      })
      .then((response) => {
        const data = response.data

        if ('success' in data) {
          navigate(0)
          
        }
      })
      .catch(function (error) {
          console.error(error);
          dispatch({
            type: SET_SENDER_IDS_LOADING_OFF
          }); 
      });
    }
    
    const searchSenderIds = (sender_id) => {
      setLoading();
      const headers = {
           accept: 'application/json',
           Authorization: 'Bearer ' + token
       }
       axios.get(`https://apis.mutani.co/api/senderid/search/${sender_id}`, {
          headers: headers
      })
      .then((response) => {
        const data = response.data.user
        console.log(data)

        dispatch({
          type: SEARCH_SENDER_ID,
          payload: data
        });
      })
      .catch(function (error) {
          console.error(error);
          dispatch({
            type: SET_SENDER_IDS_LOADING_OFF
          }); 
      });
    }

    const getUserSenderIdByUserEmail = (email) => {
      setLoading();
      const headers = {
          accept: 'application/json',
           Authorization: 'Bearer ' + token
      }
      axios.get(`https://apis.mutani.co/api/senderid/${email}`, {
          headers: headers
      })
      .then((response) => {
        const data = response.data.sender_id
      
        dispatch({
          type: GET_USER_SENDER_ID_BY_EMAIL,
          payload: data
        });     
      })
      .catch(function (error) {
          console.error(error);
      });
  }
    const getSenderIds = () => {
          setLoading();
          const headers = {
              accept: 'application/json',
               Authorization: 'Bearer ' + token
          }
          axios.get(`https://apis.mutani.co/api/senderid`, {
              headers: headers
          })
          .then((response) => {
            const data = response.data.sender_id
          //   console.log(data)
          
            dispatch({
              type: GET_ALL_SENDER_IDS,
              payload: data
            });     
          })
          .catch(function (error) {
              console.error(error);
          });
      }


      const getAllUsersMails = () => {
        setLoading();
        const headers = {
            accept: 'application/json',
             Authorization: 'Bearer ' + token
        }
        axios.get(`https://apis.mutani.co/api/users/mails`, {
            headers: headers
        })
        .then((response) => {
          const data = response.data.user
        //   console.log(data)
        
          dispatch({
            type: GET_ALL_USER_MAILS,
            payload: data
          });     
        })
        .catch(function (error) {
            console.error(error);
        });
    }

      const addSenderIdToUser = (credentials) => {
        setLoading();
        const headers = {
             'Content-Type': 'application/json', 
             Authorization: 'Bearer ' + token,    
         }
         axios.post(`https://apis.mutani.co/api/senderid/create`, 
         JSON.stringify(credentials),
         {
            headers: headers,
    
        })
        .then((response) => {
          const data = response.data

          if ('Success' in data) {
            navigate(0)
            
          }

          console.log(data)
          dispatch({
            type: ADD_SENDER_IDS,
            payload: data
          });
          // navigate(0)
        })
        .catch(function (error) {
            console.error(error);
            dispatch({
              type: SET_SENDER_IDS_LOADING_OFF
            });
        });
      }

      const addSender = (credentials) => {
        setLoading();
        const headers = {
             'Content-Type': 'application/json', 
             Authorization: 'Bearer ' + token,    
         }
         axios.post(`https://apis.mutani.co/api/senderid/create`, 
         JSON.stringify(credentials),
         {
            headers: headers,
    
        })
        .then((response) => {
          const data = response.data

          if ('Success' in data) {
            dispatch({
              type: SET_SENDER_IDS_LOADING_OFF
            });            
          }

          dispatch({
            type: ADD_SENDER_IDS,
            payload: data
          });
          navigate(0)
        })
        .catch(function (error) {
            console.error(error);
            dispatch({
              type: SET_SENDER_IDS_LOADING_OFF
            });
        });
      }

      const  updateSenderId = async (credentials, id) => {
        const headers = {
             'Content-Type': 'application/json', 
             Authorization: 'Bearer ' + token,    
         }
         axios.post(`https://apis.mutani.co/api/senderid/${id}`, 
         JSON.stringify(credentials),
         {
            headers: headers,
            // withCredentials: true
    
        })
        .then((response) => {
          const data = response.data
          if ('Success' in data) {
            navigate(0)
            
          }
    
          dispatch({
            type: SET_SENDER_IDS_LOADING_OFF
          });
    
          navigate(0)          
        })
        .catch(function (error) {
            console.error(error);
            dispatch({
              type: SET_SENDER_IDS_LOADING_OFF
            });        
        });
      }

      const deleteSenderId = (id) => {
        setLoading();
        const headers = {
             accept: 'application/json',
             Authorization: 'Bearer ' + token
         }
         axios.delete(`https://apis.mutani.co/api/senderid/delete/${id}`, {
            headers: headers
        })
        .then((response) => {
          const data = response.data
          if ('Success' in data) {
            navigate(0)
            
          }
          dispatch({
            type: SET_SENDER_IDS_LOADING_OFF
          });
    
          navigate(0)          
        })
        .catch(function (error) {
            console.error(error);
        });
      }
    


    const setLoading = () => dispatch({ type: SET_LOADING });


  return (
    <SenderIdContext.Provider
        value={{
            sender_id: state.sender_id,
            new_sender_id: state.new_sender_id,
            userSenderIds: state.userSenderIds,
            loadSenderIds: state.loadSenderIds,
            user_mails: state.user_mails,
            foundSenderIds: state.foundSenderIds,
            getSenderIds,
            addSenderIdToUser,
            updateSenderId,
            getAllUsersMails,
            searchSenderIds,
            getUserSenderIdByUserEmail,
            addSender,
            deleteSenderId,
            sendSenderIdNotificationToAdmin,
            sendReviewSenderIdResponseToUser,
            sendRejectSenderIdResponseToUser
        }}
    >
        {children}
    </SenderIdContext.Provider>  
  
  )
}

export default SenderIdState