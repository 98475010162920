import { 
  FETCH_TOTAL_DELIVERED_SMS_FROM_API,
  FETCH_TOTAL_UNDELIVERED_SMS_FROM_API,
  TOTAL_SUBMITTED,
  FETCH_USER_PAYMENT_HISTORY,
  TOTAL_SUBMITTED_BY_DATE,
  SET_LOADING
} 
  from '../types';

export default (state, action) => {
  switch (action.type) {
    case FETCH_TOTAL_DELIVERED_SMS_FROM_API: {
        return {
          ...state,
          delivered: action.payload,
          loadSloadReportsenderIds: false
        };
    }
    case FETCH_TOTAL_UNDELIVERED_SMS_FROM_API: {
      return {
        ...state,
        undelivered: action.payload,
        loadSloadReportsenderIds: false
      };
      
    }
    case FETCH_USER_PAYMENT_HISTORY: {
      return {
        ...state,
        payments: action.payload,
        loadSloadReportsenderIds: false
      };
      
    }
    case TOTAL_SUBMITTED: {
      return {
        ...state,
        submitted: action.payload,
        loadSloadReportsenderIds: false
      };
      
    }
    case TOTAL_SUBMITTED_BY_DATE: {
      return {
        ...state,
        submittedByDate: action.payload,
        loadSloadReportsenderIds: false
      };
      
    }

    case SET_LOADING:
      return {
        ...state,
        loadSloadReportsenderIds: true
      }
    
    default:
      return state;
  }
};
