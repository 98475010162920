import React, { useReducer } from 'react';
import ReportContext from './reportContext';
import ReportReducer from './reportReducer';
import { 
        FETCH_TOTAL_DELIVERED_SMS_FROM_API, 
        FETCH_USER_PAYMENT_HISTORY,
        FETCH_TOTAL_UNDELIVERED_SMS_FROM_API,
        SET_LOADING,
        TOTAL_SUBMITTED,
        TOTAL_SUBMITTED_BY_DATE
      } from '../types';
import axios from 'axios'

const ReportState = props => {
  const initialState = {
    payments : [],
    delivered: [],
    undelivered: [],
    submitted: [],
    submittedByDate: [],
    loadReports: false
  };
  const token = localStorage.getItem("token");

  const [state, dispatch] = useReducer(ReportReducer, initialState);

  const getPaymentHistory = () => {
    const headers = {
         accept: 'application/json',
         Authorization: 'Bearer ' + token

     }
     axios.get(`https://apis.mutani.co/api/transactions`, {
        headers: headers
    })
    .then((response) => {
      const data = response.data.payments
      dispatch({
        type: FETCH_USER_PAYMENT_HISTORY,
        payload: data
      });

    })
    .catch(function (error) {
        console.error(error);
    });
  }


  const totalSmsDelivered = () => {
    const headers = {
         accept: 'application/json',
     }
     axios.get(`http://127.0.0.1:8010/api/reports/delived/`, {
        headers: headers
    })
    .then((response) => {
      const data = response.data.Delivered
      dispatch({
        type: FETCH_TOTAL_DELIVERED_SMS_FROM_API,
        payload: data
      });

    })
    .catch(function (error) {
        console.error(error);
    });
  }

  const totalSmsUnDelivered = () => {
    const headers = {
         accept: 'application/json',
     }
     axios.get(`http://127.0.0.1:8010/api/reports/undelivered/`, {
        headers: headers
    })
    .then((response) => {
      const data = response.data.UnDelivered
      dispatch({
        type: FETCH_TOTAL_UNDELIVERED_SMS_FROM_API,
        payload: data
      });

    })
    .catch(function (error) {
        console.error(error);
    });
  }

  const totalSmsSubmittedByDate = (partnerId, startDate, endDate, pageCount) => {
    const headers = {
         accept: 'application/json',
     }
     axios.get(`http://127.0.0.1:8057/api/date/${partnerId}/${startDate}/${endDate}/5000?page=${pageCount}`, {
        headers: headers
    })
    .then((response) => {
      const data = response.data.reports
      dispatch({
        type: TOTAL_SUBMITTED_BY_DATE,
        payload: data
      });

    })
    .catch(function (error) {
        console.error(error);
    });
  }

  const fetchNextPage = (link) => {
    const headers = {
         accept: 'application/json',
     }
     axios.get(link, {
        headers: headers
    })
    .then((response) => {
      const data = response.data.reports
      dispatch({
        type: TOTAL_SUBMITTED_BY_DATE,
        payload: data
      });

    })
    .catch(function (error) {
        console.error(error);
    });
  }

  const fetchPreviousPage = (link) => {
    const headers = {
         accept: 'application/json',
     }
     axios.get(link, {
        headers: headers
    })
    .then((response) => {
      const data = response.data.submitted
      dispatch({
        type: TOTAL_SUBMITTED_BY_DATE,
        payload: data
      });

    })
    .catch(function (error) {
        console.error(error);
    });
  }

  

  const totalSubmitted = (email) => {
    const headers = {
         accept: 'application/json',
         Authorization: 'Bearer ' + token

     }
     axios.get(`https://apis.mutani.co/api/sms/${email}`, {
        headers: headers
    })
    .then((response) => {
      const data = response.data.submitted
      dispatch({
        type: TOTAL_SUBMITTED,
        payload: data
      });

    })
    .catch(function (error) {
        console.error(error);
    });
  }

  const setLoading = () => {dispatch({type: SET_LOADING})}

  return (
    <ReportContext.Provider
    value={{
        delivered: state.delivered,
        undelivered: state.undelivered,
        payments: state.payments,
        submitted: state.submitted,
        submittedByDate: state.submittedByDate,
        totalSmsUnDelivered,
        totalSmsDelivered,
        totalSubmitted,
        totalSmsSubmittedByDate,
        fetchNextPage,
        fetchPreviousPage,
        getPaymentHistory

      }}
      >
      {props.children}

    </ReportContext.Provider>
   
  );
};

export default ReportState;

