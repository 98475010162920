import React, {useEffect, useState, useContext} from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../../Navbar'
import ViewAllCollaborators from './ViewAllCollaborators'
import Nav from './Navigations'
import GithubContext from '../../../context/user/UserState'

const Index = () => {

  return (
      <div className='flex'>
        <div className='hidden md:block md:w-1/6 bg-[#394975] h-[49rem] text-white'>
        <Sidebar 
        />
        </div>
        <div className='w-full md:w-5/6 bg-[#ECF3FA]'>
         <Navbar/>
         <div className='bg-white mt-8 mx-10 pb-2'>
          <Nav/>
          <ViewAllCollaborators/>
          </div>
         
        </div>
      </div>
   )
}

export default Index