import {
  GET_ALL_ROLES,
  GET_ROLE_PERMISSION_BY_ID,
  SET_LOADING,
  SET_ROLES_LOADING_OFF,
  SEARCH_ROLE
} from '../types';



export default (state, action) => {
  switch (action.type) {   
    case GET_ALL_ROLES: {
      return {
        ...state,
        user_roles: action.payload,
        loadding: false
      };
    }

    case GET_ROLE_PERMISSION_BY_ID: {
      return {
        ...state,
        role_permission: action.payload,
        loadding: false
      };
    }
    case SEARCH_ROLE: {
      return {
        ...state,
        foundRole: action.payload,
        loadding: false
      };
    }

    case SET_LOADING:
      return {
        ...state,
        loadding: true
      };
      case SET_ROLES_LOADING_OFF:
      return {
        ...state,
        loadding: false
      };
    default:
      return state;
  }
};
