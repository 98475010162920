import React from 'react'
import {FaTimes} from 'react-icons/fa'

const ExtractedFile = ({goUploadFileConfirmationModal, cancelExtractedUploadFileModal, data, cols, handleSubmit }) => {
  
   
    return (
    <>
    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-auto my-6 mx-auto max-w-6xl">
        {/*content*/}
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none w-full">
          {/*header*/}
          <div className="flex items-start justify-between p-5 border-b border-solid border-slate-300 rounded-t h-16">
            <div></div>
            <h3 className="text-2xl justify-center text-gray-500 font-semibold">
              Pricelist
            </h3>
            <div 
              onClick={cancelExtractedUploadFileModal} 
              className="flex items-center justify-center h-8 hover:cursor-pointer text-3xl font-semibold">
              <FaTimes className='flex justify-center items-center fill-gray-500 text-xl'/>
            </div>
          </div>
          {/*body*/}
          <div className="relative flex-auto">
            <>
                <div className="">
                    <div className="overflow-scroll h-72 w-full px-4">
                        <table className="table border-collapse min-w-full leading-normal">
                        <thead>
                            <tr className='border'>
                            
                            </tr>
                        </thead>
                        <tbody className='overflow-y-scroll border'>
                        {
                            data.map((r, i) => (
                                <tr key={i}>
                                    {cols.map(c => (
                                    <td className='border text-sm h-10' key={c.key}>{r[c.key]}</td>
                                    ))}
                                </tr>
                                ))
                            }
                        
                        </tbody>
                        </table>
                    </div>
                </div>
            </>
          </div>
          <div className="flex flex-col items-center justify-center p-6 border-t border-solid border-slate-300 rounded-b">
            <button
              className="bg-[#00B050] text-white w-96 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              onClick={goUploadFileConfirmationModal}
            >
              Upload Excel
            </button>
          </div>
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>   
 )
}

export default ExtractedFile