import React, {useState} from 'react'
import TwentyFourHrsRrp from './TwentyFourHrsRrp'
import PeriodReport from './PeriodReport'
import SmsCredit from '../../SmsCredit'

const Nav = () => {
    const [showReportOverPeriod, setShoeReportOverPeriod] = useState('none')
    const [show24HrsReport, setShow24HrsReport] = useState('')    
    const [nav24Color, setNav24Color] = useState('#ECF3FA')
    const [navPeriodColor, setNavPeriodColor] = useState('')
    const [navCompColor, setNavCompColor] = useState('')


    
    const display24HrsReport = () =>{
        setShow24HrsReport('')
        setShoeReportOverPeriod('none');
        setNav24Color('#ECF3FA')
        setNavPeriodColor('')
    }

    const hide24HrsReport = () =>{
        setShow24HrsReport('none')
        setShoeReportOverPeriod('');
        setNav24Color('')
        setNavPeriodColor('#ECF3FA')
    }
  return (
    <div>
          <div className=' bg-white mx-8 h-[44rem] mb-4'>
            <div className='flex justify-between items-center bg-white mb-4 border-b'>
              <div>
                <p className='flex justify-start ml-10 my-4 '>BiRD</p>
                <p className='flex justify-start ml-10 my-4 text-sm'>Business Insight & Reporting Dashboard</p>

              </div>
                <SmsCredit/>
            </div>
            <div>
                <ul className='flex justify-between space-x-5 w-full h-10'>
                    <li onClick={display24HrsReport}  className="flex hover:cursor-pointer justify-center w-1/3 items-center text-center  ml-16" style={{backgroundColor: nav24Color}}>24-hr Report</li>
                    <li onClick={hide24HrsReport} className='flex hover:cursor-pointer justify-center w-1/3 items-center text-center' style={{backgroundColor: navPeriodColor}}>Report Over A Period</li>
                    <li className='flex justify-center w-1/3 items-center text-center' style={{backgroundColor: navCompColor}}>Comparism Report</li>
                </ul>
          </div>
          <div className='my-8' style={{display: show24HrsReport}}>
            <TwentyFourHrsRrp/>

          </div>

          <div className='my-8' style={{display: showReportOverPeriod}}>
            <PeriodReport/>

          </div>
        </div> 
    </div>
  )
}

export default Nav