import React from 'react'

const Managers = () => {
  return (
    <div className='flex items-center justify-center border-4 border-gray-300 mx-40 h-[35rem]'>
        <div className='flex mx-10'>
            <div className='flex flex-col w-1/2 shadow-lg h-[33rem] mr-4 bg-[#f2f7fc] rounded-xl'>
                <div className='flex justify-center items-center flex-col h-2/5'>
                    <img
                        src={process.env.PUBLIC_URL + `/images/jamal.png`}
                        alt=''
                        className='h-32 w-32 rounded-full'
                        id='co_founder'
                    />
                    <p className='mt-2'>Jamal D. Sakara Hamidu</p>
                    <p className='text-sm'>Co-Founder Mutani</p>

                </div>
                <div className='flex flex-col border h-2/3 justify-center items-center '>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae id, eos ratione nobis in iure totam incidunt quae nam ut saepe officiis? Ratione beatae ipsum cumque? Tenetur eos veniam voluptatibus!

                </div>

            </div>
            <div className='flex flex-col w-1/2 shadow-lg h-[33rem] ml-4 bg-[#f2f7fc] rounded-xl'>
                <div className='flex justify-center items-center flex-col h-2/5'>
                    <img
                        src={process.env.PUBLIC_URL + `/images/sherriff.png`}
                        alt=''
                        className='h-32 w-32 rounded-full'
                        id='cs_manager'
                    />
                    <p className='mt-2'> Hassan-Sheriff Deen Abdulai​</p>
                    <p className='text-sm'>Customer Success Manager (CSM)​</p>

                </div>
                <div className='flex flex-col border h-2/3 justify-center items-center'>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae id, eos ratione nobis in iure totam incidunt quae nam ut saepe officiis? Ratione beatae ipsum cumque? Tenetur eos veniam voluptatibus!

                </div>

            </div>

        </div>
      
    </div>
  )
}

export default Managers