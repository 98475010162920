import React from 'react'
import {FaTimes} from 'react-icons/fa'

const PendingPayment = ({cancelPendingPayment}) => {
  return (
    <>
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto my-6 mx-auto max-w-3xl">
        {/*content*/}
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex items-start justify-between p-5 border-b border-solid border-slate-300 rounded-t">
            <div></div>
            <h3 className="text-2xl justify-center text-gray-500 font-semibold">
              Make Payment
            </h3>
            <div 
              onClick={cancelPendingPayment} 
              className="flex items-center justify-center h-8 hover:cursor-pointer text-3xl font-semibold">
              <FaTimes className='flex justify-center items-center fill-gray-500 text-xl'/>
            </div>
          </div>
          {/*body*/}
          <div className="relative p-6 flex-auto">
          <h3 className='flex justify-center items-center w-full font-bold text-lg mt-2 text-red-700'>Action required:</h3>
          <div className="flex flex-col justify-center items-center my-2 text-sm">
              <p>A new browser window has been opened for you </p>
              <p>to complete your payment on our payment </p>
              <p> gateway provider (<a className='text-[#00B0F0]' href="https://paystack.com/">Paystack</a>  - a <a className='text-[#00B0F0]' href="https://paystack.com/">Stripe</a> Company) </p>
              <p>to complete your payment.</p>
          </div>
          <div className="flex flex-col justify-center items-center mx-6 w-96 h-80">
            <img
                src={process.env.PUBLIC_URL + `/images/pending.png`}
                alt=''
                className='h-40 w-40'
                id='co_founder'
            /> 
            <div className="flex flex-col justify-center items-center my-4 text-xs text-[#00B0F0]">
              <p className=''>Come back to this page when you’ve completed</p>
              <p>the payment on our payment gateway</p>
            </div>
          </div>
         
       
             
          </div>
          
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>    
    )
}

export default PendingPayment