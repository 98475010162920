import React from 'react'

const Payments = ({history, index}) => {
  return (
    <tr className='h-10'>
        <td className='text-center border-b border-t text-sm text-gray-500'>
            <p className='text-center text-gray-500 text-sm py-2'>{history.reference}</p>
        </td>
        <td className='text-center border-b border-t text-sm text-gray-500'>
            <p className='text-center text-gray-500 text-sm py-2'>{history.paid_at}</p>
        </td>
        <td className='text-center border-b border-t text-sm text-gray-500'>
            <p className='text-center text-gray-500 text-sm py-2'>{history.amount}</p>
        </td>
        <td className='text-center border-b border-t text-sm text-gray-500'>
            <p className='text-center text-gray-500 text-sm py-2'>Funds Added</p>
        </td>
        <td className='text-center border-b border-t text-sm text-gray-500'>
            <p className='text-center text-gray-500 text-sm py-2'>{history.current_amount}</p>
        </td>
        <td className='text-center border-b border-t text-sm text-gray-500'>
            <p className='text-center text-gray-500 text-sm py-2'>{history.channel}</p>
        </td>
    </tr>  
)
}

export default Payments