import React, { useReducer } from 'react';
import axios from 'axios';
import UserContext from './userContext';
import UserReducer from './userReducer';
import { useNavigate } from "react-router-dom";


import {
  GET_ROLE,
  GET_ROLE_ONLY,
  GET_ALL_USERS,
  GET_SINGLE_USER,
  SET_LOADING,
  GET_USER_PERMISSIONS_AND_ROLES,
  GET_LOGGED_IN_USER_PERMISSIONS,
  GET_ADMIN_PERMISSIONS_AND_ROLES,
  ADMIN_CREATE_USER,
  CPM_CREATE_USER,
  DELETE_USER,
  SEARCH_USER,
  CHANGE_PASSWORD
  // token

  
} from '../types';


const UserState = props => {
  const navigate = useNavigate()

  const initialState = {
    user_details: [],
    roles: [],
    user: [],
    cpm: [],
    pass_change: {},
    permissions: [],
    loggedInUserPermissions: [],
    adminPermissions : [],
    users : [],
    createUser : [],
    foundUsers : [],
    loading: false
  };



  
  const [state, dispatch] = useReducer(UserReducer, initialState);
  
  const token = localStorage.getItem("token");

  const searchUser = (email) => {
    setLoading();
    const headers = {
         accept: 'application/json',
         Authorization: 'Bearer ' + token
     }
     axios.get(`https://apis.mutani.co/api/users/search/${email}`, {
        headers: headers
    })
    .then((response) => {
      const data = response.data.user
      // const { email, first_name, last_name, phone, country, user_group} = data
      dispatch({
        type: SEARCH_USER,
        payload: data

        
        // payload: {email,first_name, last_name, phone, country, user_group}
      });


     
    })
    .catch(function (error) {
        console.error(error);
        dispatch({
          type: DELETE_USER
        });
    });
  }

  const getUserRole = (id) => {
    setLoading();
    const headers = {
         accept: 'application/json',
         Authorization: 'Bearer ' + token
     }
     axios.get(`https://apis.mutani.co/api/users/${id}`, {
        headers: headers
    })
    .then((response) => {
      const data = response.data.user
      const { email, first_name, last_name, phone, country, user_group} = data
      dispatch({
        type: GET_ROLE,
        payload: {email,first_name, last_name, phone, country, user_group}
      });


     
    })
    .catch(function (error) {
        console.error(error);
    });
  }

  const getSingleUser = (id) => {
    setLoading();
    const headers = {
         accept: 'application/json',
         Authorization: 'Bearer ' + token
     }
     axios.get(`https://apis.mutani.co/api/user/${id}`, {
        headers: headers
    })
    .then((response) => {
      const data = response.data.user
      const { 
          first_name, 
          last_name, 
          phone, 
          country, 
          user_group,
          customer_id,
          email,
          payment_type,
          billing_currency,
          sms_cost,
          roles
      } = data

      dispatch({
        type: GET_SINGLE_USER,
        payload: {
          first_name, 
          last_name, 
          phone, 
          country, 
          user_group,
          customer_id,
          email,
          payment_type,
          billing_currency,
          sms_cost,
          roles
        }
      });
     
    })
    .catch(function (error) {
        console.error(error);
    });
  }

  const getCurrentLoginUserPermissions = () => {
    setLoading();
    const headers = {
         accept: 'application/json',
         Authorization: 'Bearer ' + token
     }
     axios.get(`https://apis.mutani.co/api/user/permissions`, {
        headers: headers
    })
    .then((response) => {
      const data = response.data.permissions
      dispatch({
        type: GET_LOGGED_IN_USER_PERMISSIONS,
        payload: data
      });
     
    })
    .catch(function (error) {
        console.error(error);
    });
  }


  const  updateUser = async (credentials, id) => {
    const headers = {
         'Content-Type': 'application/json', 
         Authorization: 'Bearer ' + token,    
     }
     axios.post(`https://apis.mutani.co/api/users/${id}`, 
     JSON.stringify(credentials),
     {
        headers: headers,
        // withCredentials: true

    })
    .then((response) => {
      const data = response.data
      if ('Success' in data) {
        navigate(0)
        
      }

      dispatch({
        type: DELETE_USER
      });

      navigate(0)          
    })
    .catch(function (error) {
        console.error(error);
        dispatch({
          type: DELETE_USER
        });
        
    });
  }


  const getRole = (id) => {
    setLoading();
    //const token = "3|ujg9hRXDrKL7AFdN8ms0wGmX8D0REfHflbuXDumZ";
    const headers = {
         accept: 'application/json',
         Authorization: 'Bearer ' + token
     }
     axios.get(`https://apis.mutani.co/api/users/${id}`, {
        headers: headers
    })
    .then((response) => {
      const data = response.data.user
      const { roles } = data
      dispatch({
        type: GET_ROLE_ONLY,
        payload: roles
      });
    })
    .catch(function (error) {
        console.error(error);
    });
  }

  const getAllUsers = () => {
    setLoading();
    const headers = {
         accept: 'application/json',
         Authorization: 'Bearer ' + token
     }
     axios.get(`https://apis.mutani.co/api/users`, {
        headers: headers
    })
    .then((response) => {
      const data = response.data.user
      dispatch({
        type: GET_ALL_USERS,
        payload: data
      });
    })
    .catch(function (error) {
        console.error(error);
    });
  }

  const deleteUser = (id) => {
    setLoading();
    //const token = "3|ujg9hRXDrKL7AFdN8ms0wGmX8D0REfHflbuXDumZ";
    const headers = {
         accept: 'application/json',
         Authorization: 'Bearer ' + token
     }
     axios.delete(`https://apis.mutani.co/api/users/${id}`, {
        headers: headers
    })
    .then((response) => {
      const data = response.data
      if ('Success' in data) {
        navigate(0)
        
      }

      dispatch({
        type: DELETE_USER
      });

      navigate(0)


      console.log(data)
      
    })
    .catch(function (error) {
        console.error(error);
    });
  }

  const getUsersRoleAlongWithPermissions = () => {
    setLoading();
    const headers = {
         accept: 'application/json',
         Authorization: 'Bearer ' + token
     }
     axios.get(`https://apis.mutani.co/api/users/permissions`, {
        headers: headers
    })
    .then((response) => {
      const data = response.data.user.roles
      // const { roles } = data

      dispatch({
        type: GET_USER_PERMISSIONS_AND_ROLES,
        payload: data
      });

      // console.log(data)
     
    })
    .catch(function (error) {
        console.error(error);
    });
  }

  const getAdminRoleAlongWithPermissions = () => {
    setLoading();
    const headers = {
         accept: 'application/json',
         Authorization: 'Bearer ' + token
     }
     axios.get(`https://apis.mutani.co/api/users/permissions`, {
        headers: headers
    })
    .then((response) => {
      const data = response.data.user
      const { roles } = data

      dispatch({
        type: GET_ADMIN_PERMISSIONS_AND_ROLES,
        payload: roles
      });     
    })
    .catch(function (error) {
        console.error(error);
    });
  }


  const adminCreateUser = (credentials) => {
    setLoading();
    const headers = {
        //  accept: 'application/json',
         'Content-Type': 'application/json', 
         Authorization: 'Bearer ' + token,
        //  "Access-Control-Allow-Origin" : '*'

     }
     axios.post(`https://apis.mutani.co/api/admin/create`, 
     JSON.stringify(credentials),
     {
        headers: headers,
        // withCredentials: true

    })
    .then((response) => {
      const data = response.data
      console.log(data)
      dispatch({
        type: ADMIN_CREATE_USER,
        payload: data
      });
      navigate(0)
    })
    .catch(function (error) {
        console.error(error);
        dispatch({
          type: DELETE_USER
        });
    });
  }

  const cpmCreateUser = (credentials) => {
    setLoading();
    const headers = {
        //  accept: 'application/json',
         'Content-Type': 'application/json', 
         Authorization: 'Bearer ' + token,
        //  "Access-Control-Allow-Origin" : '*'

     }
     axios.post(`https://apis.mutani.co/api/admin/create/cpm`, 
     JSON.stringify(credentials),
     {
        headers: headers,
        // withCredentials: true

    })
    .then((response) => {
      const data = response.data
      console.log(data)
      dispatch({
        type: CPM_CREATE_USER,
        payload: data
      });
      navigate(0)
    })
    .catch(function (error) {
        console.error(error);
        dispatch({
          type: DELETE_USER
        });
    });
  }

  const changeUserPassword = async (credentials) => {
    // setLoading();
    const headers = {
        //  accept: 'application/json',
         'Content-Type': 'application/json', 
         Authorization: 'Bearer ' + token,
        //  "Access-Control-Allow-Origin" : '*'

     }
     axios.post(`https://apis.mutani.co/api/change-password`, 
     JSON.stringify(credentials),
     {
        headers: headers,

    })
    .then((response) => {
      const data = response.data
      
      dispatch({
        type: CHANGE_PASSWORD,
        payload: data
      });
      
      // if ('failed' in data) {
        
      //   console.log('hello')

      // }

      // dispatch({
      //   type: DELETE_USER
      // });

    })
    .catch(function (error) {
        console.error(error);
        // dispatch({
        //   type: DELETE_USER
        // });
    });
  }



  const setLoading = () => dispatch({ type: SET_LOADING });


  return (
    <UserContext.Provider
      value={{
        user_details: state.user_details,
        loggedInUserPermissions: state.loggedInUserPermissions,
        createUser: state.createUser,
        loading: state.loading,
        roles: state.roles,
        cpm: state.cpm,
        users: state.users,
        pass_change: state.pass_change,
        user: state.user,
        foundUsers: state.foundUsers,
        permissions: state.permissions,
        adminPermissions: state.adminPermissions,
        getUserRole,
        getRole,
        getAllUsers,
        getUsersRoleAlongWithPermissions,  
        getAdminRoleAlongWithPermissions,
        adminCreateUser,
        cpmCreateUser,
        deleteUser,
        getSingleUser,   
        updateUser,
        changeUserPassword,
        searchUser,
        getCurrentLoginUserPermissions
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserState;
