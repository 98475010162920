import React, {useEffect, useState} from 'react'
import Select from "react-select";


const AddSenderId = ({addSender, addSenderIdToUser, handleCancle, user_mails, sendRejectSenderIdResponseToUser, sendReviewSenderIdResponseToUser}) => {
    const [user_email, setEmail] = useState("")
    const [sender_id, setSenderId] = useState("")
    const [mailS, setmailS] = useState([""])

    const handleSubmit = async (e) => {
        e.preventDefault();
        var email = user_email.value
        
    
        addSender({
            email,
            sender_id,
        });
      };

      const sendRejectSenderIdNotificationToUser = async (e) => {
        e.preventDefault();
        var email = user_email.value
        
    
        sendRejectSenderIdResponseToUser({
            email,
            sender_id,
        });
      };

      const sendReviewSenderIdNotificationToUser = async (e) => {
        e.preventDefault();
        var email = user_email.value
        
    
        sendReviewSenderIdResponseToUser({
            email,
            sender_id,
        });
      };
  
    useEffect(() => {
      const newMails = []
    
        const formNewArrayFromUserPermission = () =>{
        user_mails.map((mails)=>{
          return newMails.push({value: mails, label: mails});
        })
        setmailS(newMails);
      }
    
      formNewArrayFromUserPermission();
    
    
    }, [user_mails]);


  return (
    <>
    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-6xl">
          <form  
            onSubmit={handleSubmit} 
            className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-center justify-center p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">
                Add Sender ID To User
              </h3>
            </div>
            <div className="relative p-6 flex-auto">
              <div className="my-4 text-slate-500 text-lg leading-relaxed h-80 w-[50rem] overflow-y-auto">
              <div>
                <div className="mt-6">
                        <label htmlFor="user_email" className="flex ml-[5rem]">
                            Sender ID Name <p className='text-red-500'> * </p> 
                        </label>
                        <div className="flex justify-center items-center mt-1">
                        <input
                            type="text"
                            name="Role"
                            onChange={(e) => setSenderId(e.target.value)}
                            className="border-gray-300 border rounded w-4/5 h-10 mx-2"
                            placeholder='Role Name'
                        />
                    </div>
                </div>
                <div className="flex flex-col w-full my-2">
                    <label htmlFor="role" className="flex mx-2 ml-[5rem] my-1 text-lg">
                        User Email <p className='text-red-500'> * </p> 
                    </label>
                    <div className="flex flex-col w-full items-center justify-center">
                    <Select
                        options={mailS}
                        placeholder="Select User Email"
                        onChange={(user_email) => setEmail(user_email)}
                        className="basic-multi-select w-4/5 "
                        classNamePrefix="select"
                    />
                    
                    </div>
                </div>
                
                <div className="flex items-center justify-between mx-16 p-6 ">
                </div>
                {/* <div className='border-t border-solid border-slate-200 rounded-b mb-12 mx-12'>

                </div> */}
            </div>
              </div>
            </div>
            <div className="flex items-center justify-between p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                    className="bg-gray-300 active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 mx-8"
                    type="button"
                    onClick={handleCancle}
                >
                Cancel
                </button>
                <div>
                  <button
                    className="bg-emerald-500 mr-1  text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleSubmit}
                  >
                    Add ID
                  </button>
                  <button
                    className="bg-[#ffc000] mr-1  text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={sendReviewSenderIdNotificationToUser}
                  >
                    Review Sender ID
                  </button>
                  <button
                    className="bg-mutani mr-1  text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={sendRejectSenderIdNotificationToUser}
                  >
                    Reject ID
                  </button>
                </div>
                
              
            </div>
          </form>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>   )
}

export default AddSenderId