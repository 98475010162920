import React,{useState, useEffect} from 'react'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import Select from "react-select";
import { Countries, Groups, Currencies } from "../types";


const UserEdit = ({user, cancelEditForm, user_roles, updateUser}) => {
    //const userphone = user.phone.substring(3,12)
    const usercode = user.phone.substring(0,3)

    const [code, setCode] = useState("");
    const [tel, setTel] = useState("");
    const [email, setEmail] = useState(user.email);
    const [first_name, setFirstName] = useState(user.first_name);
    const [last_name, setLastName] = useState(user.last_name);
    const [password, setPassword] = useState("");
    const [user_group, setGroup] = useState(user.user_group);
    const [sms_cost, setSmsCost] = useState(user.sms_cost);
    const [billing_currency, setBillingCurrency] = useState("");
    const [payment_type, setPaymentType] = useState(user.payment_type)
    const [prepaidIsChecked, setPrepaidIsChecked] = useState(true)
    const [postpaidIsChecked, setPostpaidIsChecked] = useState(false)
    const [allRoles, setallRoleA] = useState([""])
    const [defaultsRole, setDefaultsRole] = useState([""])
    const [defaultUserGroup, setDefaultUserGroup] = useState({})
    const [defaultBillingCurrency, setDefaultBillingCurrency] = useState([""]);
    const [defaultCountry, setDefaultCountry] = useState([""])
    const [country, setCountry] = useState("");

    const handlePrepaidChange = (e) => {
        setPaymentType('prepaid')
        setPrepaidIsChecked(!prepaidIsChecked)
        setPostpaidIsChecked(!postpaidIsChecked)
    
      }
    
      const handlePostpaidChange = (e) => {
        setPaymentType('postpaid')
        setPrepaidIsChecked(!prepaidIsChecked)
        setPostpaidIsChecked(!postpaidIsChecked)
      }

      useEffect(() => {
        formNewArrayFromUserPermissionContext()
        formNewArrayFromUser()
        formNewArrayFromUserGroup()
        formDefaultCountryArray()
        formDefaultBillingCurrencyArray()

        if (user.payment_type === 'postpaid') {
            setPostpaidIsChecked(true)
            setPrepaidIsChecked(false)
            
        }

      }, []);



      const formNewArrayFromUserPermissionContext = () =>{
        let arr = [];
        user.roles.map((role)=>{
          return arr.push({value: role.name, label: role.name});
        })
        setDefaultsRole(arr);
      }

        const formNewArrayFromUserGroup = () =>{
            let arr = [];
            user_roles.map((role)=>{
              return arr.push({value: role.name, label: role.name});
            })
            setallRoleA(arr);
        }

        const formDefaultCountryArray = () =>{
            let arrrih = [];
            setDefaultCountry({value: user.country, label: user.country});
            //setDefaultCountry(arrrih);
        }


          const formDefaultBillingCurrencyArray = () =>{
            let arrriy = [];
            arrriy.push({value: user.billing_currency, label: user.billing_currency});
            setDefaultBillingCurrency(arrriy);
          }

        const formNewArrayFromUser = () =>{
            let arrra = [];
            arrra.push({value: user.user_group, label: user.user_group});
            setDefaultUserGroup(arrra);
          }


        const handleSubmit = async (e) => {
        e.preventDefault()

        const phone = `${code}${tel}`;

        const roles = [];

        // defaultUserGroup.map( (en) => {
        //     return setGroup(en.value)
        // });

        // defaultCountry.map((count) => {
        //     return check.push(count.value)
        // })

        // defaultCountry.map((count) => {
        //     return setfirst(count.value)
        // })

        defaultBillingCurrency.map( en => setBillingCurrency(en.value));

        defaultsRole.map((en) => {
            return roles.push(en.value);
          });

          await updateUser({
            first_name,
            last_name,
            email,
            phone,
            roles,
            user_group,
            country,
            payment_type,
            billing_currency,
            sms_cost,
    
        }, user.id);
        }
  
    return (
        <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-6xl">
            <form  onSubmit={handleSubmit} className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-center justify-center p-5 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-3xl font-semibold">
                        Edit {user.first_name} {user.last_name} User
                    </h3>
                </div>
                <div className="relative p-6 flex-auto">
                <div className="text-slate-500 text-lg leading-relaxed h-[34rem] w-[65rem] overflow-y-auto">
                    <div className="">
                <div className="flex flex-col justify-center items-center mx-6">
                    <div className="flex">
                    <div className="flex flex-col">
                        <label htmlFor="email" className="mx-2 my-1">
                        Email*
                        </label>
                        <input
                        type="text"
                        name="Email"
                        value={email}
                        disabled={true}
                        onChange={(e) => setEmail(e.target.value)}
                        className="border-gray-300 border rounded w-96 h-10 mx-2"
                        />
                    </div>
                    <div className="flex flex-col">
                        <label htmlFor="password" className="flex mx-2 my-1">
                        Password<p className="text-red-600">*</p>
                        </label>
                        <input
                        type="password"
                        name="password"
                        onChange={(e) => setPassword(e.target.value)}
                        className="border-gray-300 border rounded w-96 h-10 mx-2"
                        readOnly
                        />
                    </div>
                    </div>
                    <div className="flex my-1">
                    <div className="flex flex-col">
                        <label htmlFor="first_name" className="mx-2 my-1">
                        First name*
                        </label>
                        <input
                        type="text"
                        name="first_name"
                        value={first_name}
                        onChange={(e) => setFirstName(e.target.value)}
                        className="border-gray-300 rounded border w-96 h-10 mx-2"
                        />
                    </div>
                    <div className="flex flex-col">
                        <label htmlFor="last_name" className="mx-2 my-1">
                        Last name*
                        </label>
                        <input
                        type="text"
                        name="last_name"
                        value={last_name}
                        onChange={(e) => setLastName(e.target.value)}
                        className="border-gray-300 rounded border w-96 h-10 mx-2"
                        />
                    </div>
                    </div>
                    <div className="flex my-1">
                    <div className="flex w-1/2 flex-col my-2">
                        <label htmlFor="country" className="w-full pl-3">
                        Country
                        </label>
                        <div className="flex flex-col w-full items-center justify-center">
                        <Select
                            options={Countries}
                            onChange={(country) => setCountry(country.value)}
                            className="basic-multi-select w-96"
                            classNamePrefix="select"
                        />
                        </div>
                    </div>
                    <div className="flex w-1/2 flex-col">
                        <label htmlFor="email" className="mx-2 my-1">
                        Mobile Number
                        </label>
                        <div className="flex">
                        <div className=" ml-2">
                            <PhoneInput
                            // country={"gh"}
                            enableSearch={true}
                            value={code}
                            onChange={(code) => setCode(code)}
                            />
                        </div>
                        <input
                            type="text"
                            name="code"
                            value={tel}
                            onChange={(e) => setTel(e.target.value)}
                            className="border-gray-300 rounded mr-4 border w-72 h-10"
                        />
                        </div>
                    </div>
                    </div>
                    <div className="flex flex-col w-full my-1">
                    <label htmlFor="email" className="mx-2 ml-24">
                        User Group
                    </label>
                    <div className="flex flex-col w-full items-center justify-center">
                        <Select
                            options={Groups}
                            // value={defaultUserGroup}
                            onChange={(user_group) => setGroup(user_group.value)}
                            className="basic-multi-select w-4/5"
                            classNamePrefix="select"
                        />
                    </div>
                    </div>
                    <div className="flex flex-col w-full">
                    <label htmlFor="role" className="mx-2 ml-24 my-2">
                        Role(s)
                    </label>
                    <div className="flex flex-col w-full items-center justify-center">
                        <Select
                        isMulti
                        options={allRoles}
                        value={defaultsRole}
                        onChange={(defaultsRole) => setDefaultsRole(defaultsRole)}
                        className="basic-multi-select w-4/5"
                        classNamePrefix="select"
                        />
                        
                    </div>
                    </div>
                    <div className="flex justify-center items-center mt-6 w-full px-24">
                    <div className="flex flex-col w-1/3">
                        <div className="flex">
                            <input
                                type="radio"
                                name="PaymentType"
                                value={payment_type}
                                className="border-gray-300 mx-2"
                                onChange={handlePrepaidChange}
                                checked={prepaidIsChecked}
                                />
                            <label htmlFor="PaymentType" className="mx-2">
                            Pre-paid Customer
                            </label>      
                        </div>
                        <div className="flex">
                            <input
                            type="radio"
                            name="postpaid"
                            value={payment_type}
                            className="border-gray-300 border mx-2"
                            onChange={handlePostpaidChange}
                            checked={postpaidIsChecked}
                            />
                            <label htmlFor="postpaid" className="mx-2 my-1">
                            Post-paid Customer
                            </label>
                        </div>                   
                    </div>
                    <div className="flex w-1/3 flex-col ">
                        <label htmlFor="country" className="w-full items-center justify-center">
                        Billing Currencies
                        </label>
                        <div className="flex flex-col w-full items-center justify-center">
                        <Select
                            options={Currencies}
                            // value={defaultBillingCurrency}
                            onChange={(billing_currency) => setBillingCurrency(billing_currency.value)}
                            className="basic-multi-select w-full"
                            classNamePrefix="select"
                        />
                        </div>
                    </div>
                    <div className="flex w-1/3 flex-col">
                        <label htmlFor="email" className="mx-2">
                        Cost Per SMS
                        </label>
                        <div className="flex">
                        <input
                            type="text"
                            name="code"
                            value={sms_cost}
                            onChange={(sms_cost) => setSmsCost(sms_cost)}
                            className="border-gray-300 border w-60 h-10 mx-2"
                        />
                        </div>
                    </div>

                    </div>
                </div>
                    </div>
                </div>
                </div>
                <div className="flex items-center justify-between p-6 border-t border-solid border-slate-200 rounded-b">
                    <button
                        className="bg-[#ffc000] active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 mx-8"
                        type="button"
                        onClick={cancelEditForm}
                    >
                    Cancel
                    </button>
                <button
                    className="bg-emerald-500 mr-8  text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleSubmit}
                    >
                    Update {first_name} {last_name} Data
                    </button>
                </div>
            </form>
            </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>      )
}

export default UserEdit