import React, {useEffect, useState, useContext} from 'react';
import Activities from './Activities';
import Applications from './Applications';
import Footer from './Footer';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import {GrMenu} from 'react-icons/gr'
import Dropdown from './Dropdown';
import { Link } from 'react-router-dom';
import ReportContext from '../context/reports/reportContext'
import SmsCredit from './SmsCredit';


const Dashboard = () => {


  const user = localStorage.getItem("user");
  const parseUser = JSON.parse(user);
  const email = parseUser.email


  const reportContext = useContext(ReportContext);  

  const { 
      totalSubmitted,
      submitted,

  } 
  = reportContext;





  useEffect(() => {
    if (submitted === 0) {
      totalSubmitted(email)
    }
  }, [submitted])

  

  const [dropdown, setDropdown] = useState(false)

  const menuIconClicked = () => {
    setDropdown(!dropdown)

  }
  
  return (
    <div className=''>
      <div className='block md:hidden lg:hidden'>
            <div className='max-w-sm mx-4 mb-4 my-2'>
                <div>
                  <div>
                  <div className='flex justify-between items-center'>
                        <div>
                            <img
                                src={process.env.PUBLIC_URL + `/images/logo.png`}
                                alt=''
                                className='h-24 w-24'
                                id='co_founder'
                            />  
                        </div>
                        <div  className='' onClick={menuIconClicked}>
                          <GrMenu className='text-3xl'/>
                        </div>
                    </div>
                    {
                            dropdown ? <Dropdown/> : null
                    }  

                  </div>
                    
                </div>
                <div className='flex justify-between bg-white my-8'>
                  <div  className='flex justify-start items-center'>
                    <p className='text-xs'>My WorkSpace</p>

                  </div>
                  <div className='flex justify-end text-xs'>
                    <SmsCredit/>
                  </div>
                </div>
                <div>
                <div className='flex flex-col justify-center items-center border-4 border-gray-200 h-64'>
                  <div className='w-full mx-8 text-white'>
                  <div className="h-8 ml-4 py-2 text-black font-bold text-sm">
                    Summary SMS Activity Dashboard
                  </div>

                    <div className='flex w-full h-20 my-2 font-roboto'>
                      <div className='flex flex-col justify-center items-center w-1/2 bg-[#00b0f0] ml-4'>
                        <p>Sent</p>
                        <p>100,000</p>
                      </div>
                      <div className='flex flex-col justify-center items-center w-1/2 bg-[#00b050] mx-4'>
                        <p>Success</p>
                        <p>100,000</p>
                      </div>                  
                    </div>
                    <div className='flex w-full h-20 my-2 font-roboto'>
                      <div className='flex flex-col justify-center items-center w-1/2 bg-[#FFC000] ml-4'>
                        <p>Pending</p>
                        <p>100,000</p>
                      </div>
                      <div className='flex flex-col justify-center items-center w-1/2 bg-[#FF0000] mx-4'>
                        <p>Failed</p>
                        <p>100,000</p>
                      </div>                  
                    </div>

                  </div>
                    
                </div>
                </div>

                <div className='mt-8'>
                <div className='flex flex-col justify-center items-center border-4 border-gray-200 h-[28rem]'>
                  <div className='w-full mx-8 text-white'>
                  <div className="h-8 ml-4 py-2 mb-4 text-black font-bold text-sm">My Apps</div>
                    <Link to='/bam/sendsms/excel' className='flex w-full h-20 my-2 text-black'>
                      <div className='flex flex-col justify-center items-center w-1/2 bg-[#ECF3FA] ml-4'>
                        <p>Bam</p>
                      </div>
                      <div className='flex flex-col justify-center items-center w-1/2 bg-[#ECF3FA] mx-4'>
                        <p>Bird</p>
                      </div>                  
                    </Link>
                    <div className='flex w-full h-20 my-2 text-black'>
                      <div className='flex flex-col justify-center items-center w-1/2 bg-[#ECF3FA] ml-4'>
                        <p>AdDirect</p>
                      </div>
                      <div className='flex flex-col justify-center items-center w-1/2 bg-[#ECF3FA] mx-4'>
                        <p>BusPASS</p>
                      </div>                  
                    </div>
                    <Link to='/usermanager' className='flex w-full h-20 my-2 text-black'>
                      <div className='flex flex-col justify-center items-center w-1/2 bg-[#ECF3FA] ml-4'>
                        <p>User Manager</p>
                      </div>
                      <div className='flex flex-col justify-center items-center w-1/2 bg-[#ECF3FA] mx-4'>
                        <p>Bird</p>
                      </div>                  
                    </Link>
                    <div className='flex w-full h-20 my-2 text-black'>
                      <div className='flex flex-col justify-center items-center w-1/2 bg-[#ECF3FA] ml-4'>
                        <p>CPM</p>
                      </div>
                      <div className='flex flex-col justify-center items-center w-1/2 bg-[#ECF3FA] mx-4'>
                        <p>Reseller</p>
                      </div>                  
                    </div>
                  </div>
                </div>
                </div>
            </div>       
        </div>

      <div className='hidden md:block lg:block h-screen'>
          <div className='flex'>
            <div className='hidden md:block md:w-1/6 bg-[#394975] h-[49rem] text-white'>
              <Sidebar/>
            </div>
            <div className='w-full md:w-5/6 bg-[#ECF3FA]'>
              <Navbar/>
              {/* <div className='flex justify-between bg-white mx-12 py-2'>
              <div className="justify-start h-8 ml-16 mb-2 py-4 text-black font-bold">My Workspace</div>

                <div className='flex justify-end mr-12 my-4'>
                  <div className='flex justify-center items-center mr-4 space-x-4'>
                    <p className='text-[#757575]'>SMS Wallet Balance</p>
                    <span className='bg-[#00B050] h-8 w-16 flex justify-center items-center rounded'>655</span>
                    <button className='bg-[#ffc000] h-8 w-16 flex justify-center items-center rounded'>Top Up</button>
                  </div>
                </div>
              </div> */}
              <Activities submitted={submitted}/>
              <Applications/>
            </div>
          </div>
          <Footer/>

      </div>

    </div>
   
  )
}

export default Dashboard