import React, {useState} from 'react'
import UserRoles from './UserRoles'
import {BsPencilSquare, BsTrash} from 'react-icons/bs'
import ConfirmDeletion from './ConfirmDeletion'
import UserEdit from './UserEdit'


const Users = ({user, deleteCollaborator, user_roles, updateUser }) => {
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [isEditing, setIsEditing] = useState(false);

    const handleEdit = () => {
        setIsEditing(!isEditing)

      }

      const cancelEditForm = () => {
        setIsEditing(false)
      }

    const handleCancle = () => {
        setConfirmDelete(!ConfirmDeletion);

    }
  return (
    <>
        <tr>
            <td className='text-center border-b border-t-2 text-sm text-gray-500'>{ user.customer_id }</td>
            <td className='text-center border-b border-t-2 text-sm text-gray-500'>{ user.email }</td>
            <td className='text-center border-b border-t-2 text-sm text-gray-500'>{ user.first_name } { user.last_name }</td>
            <td className='text-center border-b border-t-2 text-sm text-gray-500'>{ user.user_group }</td>
            <td className='text-center border-b border-t-2 text-sm text-gray-500'>
                {
                    user.roles.length > 0 ?

                    user.roles.map((userRole)=>(
                        <UserRoles userRole={userRole} key={userRole.id}/>
                    )) : <><p>No Roles Found</p></>
                }
            </td>
            <td className='text-center border-b border-t-2 text-sm text-gray-500'>GHS { 100 }</td>
            <td className="text-center border-t-2 px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <button onClick={handleEdit} 
                    className="text-center flex text-green-500">
                    <BsPencilSquare className='mx-2'/>
                    <p>Edit</p>                                    
                </button>
                <button 
                    onClick={() => setConfirmDelete(true)} 
                    className="flex justify-center items-center text-red-500">
                    <BsTrash className='mx-2'/>
                    <p>Delete</p>                                    
                </button>
                {/* <button onClick={() => deleteUser(user.id)} className="flex justify-center items-center text-red-500">
                    <BsTrash className='mx-2'/>
                    <p>Delete</p>                                    
                </button> */}
            </td>
            <td>
            {
                confirmDelete ?
                <ConfirmDeletion 
                text = "user" 
                user={ user } 
                deleteCollaborator = {deleteCollaborator} 
                handleCancle={handleCancle}/> : null
            }
            </td>
            <td>
            {
                isEditing ? 
                <UserEdit 
                    user={user} 
                    cancelEditForm={cancelEditForm} 
                    user_roles={user_roles} 
                    updateUser={updateUser}
                /> : null
            }
                
            </td>
            

        </tr> 
        </>
        )
}

export default Users