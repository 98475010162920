import React,{useContext, useEffect, useState } from 'react'
import {GrFormAdd} from 'react-icons/gr'
import PermissionContext from '../../../context/permissions/permissionsContext'
import AddPermission from './AddPermission';
import Permissions from './Permissions';
import Search from './Search';

const ViewAllPermissions = () => {
  const permissionContext = useContext(PermissionContext);

  const [viewPermissions, setViewPermissions] = useState("")
  const [displayAddPermissionForm, setDisplayAddPermissionForm] = useState(false)
  
    const [inputName, setInputName] = useState("")
    const [viewSearchResults, setViewSearchResults] = useState('none')
    const [viewUserTable, setViewUserTable] = useState("")
    const [viewPerm, setViewPerm] = useState("")
    const [viewUsersTable, setViewUsersTable] = useState("none")

  const { 
    getAllPermissionsFromDB, 
    allPermissions,
    foundPermissions,
    searchResults,
    permissionLoading, 
    deletePermission,
    updatePermission,
    searchPermission,
    } = permissionContext;


    useEffect(() => {
      getAllPermissionsFromDB()
      console.log(foundPermissions)
  
    },[foundPermissions])

    const addPermission = () => {
      setViewPermissions("none")
      setDisplayAddPermissionForm(true)

    }

    const handleCancle = () => {
      setViewPermissions("")
      setDisplayAddPermissionForm(!displayAddPermissionForm)


    }
    const onNameChange = (e) => {setInputName(e.target.value)}

    const onSearchButtonClick = e =>{
      e.preventDefault();
      if (inputName === '') {
          console.log('Please enter something');
      } else {
          setViewUserTable('none')
          setViewPerm('none')
          searchPermission(inputName);
          setViewSearchResults("")
          setInputName('');
          setViewUsersTable('')
      }
  
  }

    const goBackToViewUsersTable = () =>
    {
          setViewUserTable('')
          setViewPerm('')
          setViewSearchResults("none")
          setViewUsersTable('none')
    }

    // const editPermission = () => {
    //   setIsEditing(!isEditing)


    // }

    // if (permissionLoading) return <div>Loading...</div>


  return (
    <div className='block md:flex items-center justify-center mx-2'>
      <div className='flex flex-col w-full my-2 md:mx-2 h-[28rem]'>
          <div className='border-4 border-gray-300 h-[28rem]'>
              <div style={{ display: viewPermissions }}>
                  <div className='flex justify-between my-8 mr-16'>
                      <div className='flex justify-start'>
                        <Search 
                          searchRole={searchPermission} 
                          onSearchButtonClick = {onSearchButtonClick}
                          inputName = {inputName}
                          onNameChange = {onNameChange}
                        />
                      </div>
                      <div className='flex justify-end' style={{display : viewPerm}}>
                          <button onClick={addPermission} className="flex justify-center items-center bg-[#00B050] mr-16 w-40 text-white h-10">
                              <GrFormAdd className='text-xl mx-1 bg-white'/>
                              <p className='px-1 text-xs'>Add New Permission</p>                                    
                          </button>                
                      </div>
                      <div className='flex justify-end' style={{display : viewUsersTable}}>
                            <button  onClick={goBackToViewUsersTable} className="flex justify-center items-center bg-[#00B050] mr-16 w-52 text-white h-10">
                                <GrFormAdd className='text-xl mx-1 bg-white'/>
                                <p className='px-1 text-xs'>View All Permissions</p>                                    
                            </button>      
                        </div>

                  </div>
                  {/* <p className='flex justify-center items-center mb-2 font-bold tracking-wider text-lg'>Permissions</p> */}
                  <div style={{display: viewUserTable}}  className="overflow-y-scroll h-72 mx-24">
                      <table className="table border-collapse min-w-full h-72 px-2">
                        <thead className=''>
                            <tr className='text-center'>
                                <th className="flexfont-bold tracking-wider text-lg">
                                    {/* Permissions */}
                                </th>
        
                            </tr>
                        </thead>
                        <tbody className=''>
                        {
                                allPermissions.length > 0
                                ?
                                allPermissions.map((permission) => (
                                      <Permissions 
                                        key={permission.id} 
                                        permission={permission} 
                                        deletePermission={deletePermission} 
                                        updatePermission={updatePermission}
                                      />
                                ))
                                :
                                <tr>
                                    <td colSpan="5" className="text-center">No Permissions Found.</td>
                                </tr> 
                        }
                        
                        </tbody>
                      </table>
                      
                  </div> 
                  <div style={{display: viewSearchResults}}  className="overflow-y-scroll h-72 mx-24">
                      <table className="table border-collapse min-w-full h-72 px-2">
                        <thead className=''>
                            <tr className='text-center'>
                                <th className="flexfont-bold tracking-wider text-lg">
                                    {/* Permissions */}
                                </th>
        
                            </tr>
                        </thead>
                        <tbody className=''>
                        {
                                searchResults.length > 0
                                ?
                                searchResults.map((permission) => (
                                      <Permissions 
                                        key={permission.id} 
                                        permission={permission} 
                                        deletePermission={deletePermission} 
                                        updatePermission={updatePermission}
                                      />
                                ))
                                :
                                <tr>
                                    <td colSpan="5" className="text-center">No Permissions Found.</td>
                                </tr> 
                        }
                        
                        </tbody>
                      </table>
                      
                  </div> 

              </div>
              {
                  displayAddPermissionForm ? <AddPermission allPermissions = {allPermissions} handleCancle={handleCancle}/> : null
              }
             
            
          </div>
        
      </div>
    </div>     
    )
}

export default ViewAllPermissions