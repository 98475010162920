import React from 'react'
import Navbar from '../../Navbar'
import Sidebar from '../../Sidebar'
import FileUpload from './FileUpload';

const Index = () => {
   
  return (
    
    <div className='flex'>
        <div className='w-1/6 bg-[#394975] h-screen text-white'>
            <Sidebar/>
        </div>
        <div className='w-5/6 bg-[#ECF3FA]'>
        <Navbar/>
        <FileUpload/>
        </div>
    </div>
  )
}

export default Index