import React from 'react'
import {TbHandStop} from 'react-icons/tb'
const UnauthorisedAccess = ({okayBtnClicked}) => {
  return (
<>
    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-6xl">
          <div  className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-center justify-center p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">
                Unauthorised Access
              </h3>
            </div>
            <div className="relative p-6 flex-auto">
                <p className="flex justify-center items-center text-slate-500 text-lg leading-relaxed h-20 w-[28rem]">
                    <TbHandStop className='text-7xl text-red-500'/>
                </p>

              <p className=" text-slate-500 text-lg leading-relaxed h-20 w-[36rem]">

                Your are Unauthorised to perform this operation, Contact Admin.
              </p>
            </div>
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
              <button
                className="bg-emerald-500 mr-8  text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={okayBtnClicked}
                >
                Okay
                </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>   
    )
}

export default UnauthorisedAccess