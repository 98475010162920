import React,{useContext, useEffect, useState} from 'react'
import GithubContext from '../../../context/user/userContext'
import PermissionContext from '../../../context/permissions/permissionsContext'
import RoleContext from '../../../context/roles/roleContext'
import {GrSettingsOption, GrFormAdd} from 'react-icons/gr'
import {IoPlayBackOutline} from 'react-icons/io5'
import UserPermissions from './UserPermissions'
import UnauthorizedPermissions from './UnauthorizedPermissions'
import Users from './Users'
import AddUser from './AddUser'
import UserEdit from './UserEdit'
import AddUserCpm from './AddUserCpm'
import Search from './Search'



const Read = () => {
    const githubContext = useContext(GithubContext);
    const roleContext = useContext(RoleContext);
    const permissionContext = useContext(PermissionContext);

    

    const { 
        user_details, 
        permissions,  
        adminPermissions,
        users, 
        user, 
        getUserRole, 
        getUsersRoleAlongWithPermissions,
        getAdminRoleAlongWithPermissions, 
        getAllUsers, 
        loading, 
        deleteUser,
        updateUser,
        getSingleUser,
        searchUser,
        foundUsers
    } = githubContext;


    const { 
        getAllRolesFromDB, 
        user_roles,
        loadding, 
    } = roleContext;

    const { 
        getAllPermissionsFromDB, 
        allPermissions,
    } = permissionContext;

    const [viewSearchResults, setViewSearchResults] = useState('none')
    const [viewUserTable, setViewUserTable] = useState("")
    const [viewPerm, setViewPerm] = useState("")
    const [viewUsersTable, setViewUsersTable] = useState("none")

    const [inputEmail, setInputEmail] = useState("")
    const [viewUsers, setviewUsers] = useState('')
    const [viewMyPermissions, setviewMyPermissions] = useState('none')
    const [displayCreateUserForm, setviewdisplayCreateUserForm] = useState(false)
    const [displayCpmCreateUserForm, setDisplayCpmCreateUserForm] = useState(false)

    // const [isEditing, setIsEditing] = useState(false);
    const [groupFromContext, setGroupFromContext] = useState("")

    const userFromLocalStorage = localStorage.getItem("user");
    const parseUser = JSON.parse(userFromLocalStorage);
    const groupFromLocalStorage = parseUser.roles

    

  

    const viewRolesAndPermissions = (e) =>{
        e.preventDefault()
        setviewUsers('none')
        setviewMyPermissions('')

    }

    const goBackToViewUsers = (e) =>{
        e.preventDefault()
        setviewUsers('')
        setviewMyPermissions('none')

    }

    
    

    const createCpmUser = () => {
        setDisplayCpmCreateUserForm(true)
    }

    const createUser = (e) => {
        e.preventDefault()
        setviewdisplayCreateUserForm(!displayCreateUserForm)
    }

    const arr = [];
    const adminPerm = [];



    const formNewArrayFromUserPermissionContext = () =>{
      permissions.map((ed)=>{
        ed.permissions.map((edd)=>{
          return arr.push(edd.name)
        })
    })}

    const formNewArrayFromAdminPermissionContext = () =>{
        allPermissions.map((ed)=>{
            // ed.permissions.map((edd)=>{
                return adminPerm.push(ed.name)
        // })
    })}

    formNewArrayFromUserPermissionContext()
    formNewArrayFromAdminPermissionContext()

    const permissionNotHaving = adminPerm.filter(val => !arr.includes(val));

      useEffect(() => {
        getAllUsers()  
        getUsersRoleAlongWithPermissions()
        getAdminRoleAlongWithPermissions()
        getAllRolesFromDB()
        getAllPermissionsFromDB()

        const getUserGroupFromContext = () =>{
            groupFromLocalStorage.map((edd)=>   {
                setGroupFromContext(edd.name)
            }
    
        )}

        getUserGroupFromContext()

      }, []);
      
    //   const handleEdit = () => {
    //     setIsEditing(!isEditing)

    //   }

    //   const cancelEditForm = () => {
    //     setIsEditing(false)
    //   }

      const handleCancle = (e) => {
        //setviewdisplayCreateUserForm(false) 
        if (displayCpmCreateUserForm) {
            setDisplayCpmCreateUserForm(false)
            
        }else{
            setviewdisplayCreateUserForm(false)
        }
        
    }

    const onSearchButtonClick = e =>{
        e.preventDefault();
        if (inputEmail === '') {
            console.log('Please enter something');
        } else {
            setViewUserTable('none')
            setViewPerm('none')
            searchUser(inputEmail);
            setViewSearchResults("")
            setInputEmail('');
            setViewUsersTable('')
        }

    }

    const goBackToViewUsersTable = () =>
    {
            setViewUserTable('')
            setViewPerm('')
            setViewSearchResults("none")
            setViewUsersTable('none')
    }

    const cancelCpmCreateUserForm = () => {
        setDisplayCpmCreateUserForm(false)
    
    }

    const onEmailChange = (e) => {setInputEmail(e.target.value)}

    //   if (loading) return <div>Loading...</div>


  
  return (
    <div className='block md:flex items-center justify-center mx-2'>
        <div className='flex flex-col w-full my-2 md:mx-2 h-[28rem]'>
            <div className='border-4 border-gray-300' style={{display: viewUsers}}>
                <div className='flex justify-between my-8 mr-8'>
                    <div className='flex justify-start ml-8'>
                        <Search 
                            searchUser={searchUser} 
                            onSearchButtonClick = {onSearchButtonClick}
                            inputEmail = {inputEmail}
                            onEmailChange = {onEmailChange}
                        />
                    </div>
                    <div className='flex justify-end' style={{display : viewPerm}}>
                        <button onClick={viewRolesAndPermissions} className="flex justify-center items-center bg-gray-200 mx-2 h-10">
                            <GrSettingsOption className='mx-1'/>
                            <p className='px-1'>view roles and permissions</p>                                    
                        </button>
                        {
                            groupFromContext === 'Admin' ? 
                                <button  onClick={createUser} className="flex justify-center items-center bg-[#00B050] mr-16 w-32 text-white h-10">
                                    <GrFormAdd className='text-xl mx-1 bg-white'/>
                                    <p className='px-1 text-xs'>Add user</p>                                    
                                </button> 
                          : null
                        }

                        {/* {
                            groupFromContext === 'Admin' ? 
                                <button  onClick={createUser} className="flex justify-center items-center bg-[#00B050] mr-16 w-32 text-white h-10">
                                    <GrFormAdd className='text-xl mx-1 bg-white'/>
                                    <p className='px-1 text-xs'>Add Admin user</p>                                    
                                </button> 
                          :
                                <button  onClick={createCpmUser} className="flex justify-center items-center bg-[#00B050] mr-16 w-32 text-white h-10">
                                    <GrFormAdd className='text-xl mx-1 bg-white'/>
                                    <p className='px-1 text-xs'>Add CPM user</p>                                    
                                </button> 
                        } */}
                                       
                    </div>
                    <div className='flex justify-end' style={{display : viewUsersTable}}>
                        <button  onClick={goBackToViewUsersTable} className="flex justify-center items-center bg-[#00B050] mr-16 w-32 text-white h-10">
                            <GrFormAdd className='text-xl mx-1 bg-white'/>
                            <p className='px-1 text-xs'>View All Users</p>                                    
                        </button>      
                    </div>

                </div>
                <div style={{display: viewUserTable}} className="overflow-y-scroll h-80 mx-8">
                    <table className="table border-collapse min-w-full h-90 px-2">
                        <thead>
                            <tr className='text-left'>
                                <th className="text-xs font-bold tracking-wider">
                                    Customer ID
                                </th>
                                <th className="text-xs font-bold text-gray-700">
                                    Email
                                </th>
                                <th className="py-2 text-xs font-bold text-gray-700 tracking-wider">
                                    Full Name
                                </th>
                                <th className="py-2 text-xs font-bold text-gray-700 tracking-wider">
                                    User Group
                                </th>
                                <th className="py-2 text-xs font-bold text-gray-700 tracking-wider">
                                    Role(s)
                                </th>
                                <th className="py-2 text-xs font-bold text-gray-700 tracking-wider">
                                    Wallet
                                </th>
                            </tr>
                        </thead>
                        <tbody className='text-left'>
                            {
                                users.length > 0
                                ?
                                users.map((user) => (
                                        <Users 
                                            user={user} 
                                            key={user.id} 
                                            getSingleUser={getSingleUser} 
                                            // handleEdit={handleEdit} 
                                            deleteUser={deleteUser}
                                            // cancelEditForm={cancelEditForm} 
                                            user_roles={user_roles} 
                                            updateUser={updateUser}
                                        />
                                    
                                    ))
                                :
                                <tr>
                                    <td colSpan="5" className="text-center">No Users Found.</td>
                                </tr> 
                            }
                        
                        </tbody>
                    </table>
                    <div>
                        {
                            displayCreateUserForm &&
                            <AddUser 
                                user_roles={user_roles} 
                                handleCancle={handleCancle}
                            /> 
                        }
                    </div>
                    <div>
                        {
                            displayCpmCreateUserForm &&
                            <AddUserCpm 
                                user_roles={user_roles} 
                                handleCancle={handleCancle}

                            />
                        }
                    </div>
                    {/* <div>
                        {
                            isEditing ? 
                            <UserEdit 
                                user={user} 
                                cancelEditForm={cancelEditForm} 
                                user_roles={user_roles} 
                                updateUser={updateUser}
                            /> : null
                        }
                    </div> */}
                </div>
                <div style={{display: viewSearchResults}} className="overflow-y-scroll h-80 mx-4">
                    <table className="table border-collapse min-w-full h-90 px-2">
                    <thead>
                        <tr className='text-center'>
                            <th className="text-xs font-bold tracking-wider">
                                Customer ID
                            </th>
                            <th className="text-xs font-bold text-gray-700">
                                Email
                            </th>
                            <th className="py-2 text-xs font-bold text-gray-700 tracking-wider">
                                Full Name
                            </th>
                            <th className="py-2 text-xs font-bold text-gray-700 tracking-wider">
                                User Group
                            </th>
                            <th className="py-2 text-xs font-bold text-gray-700 tracking-wider">
                                Role(s)
                            </th>
                            <th className="py-2 text-xs font-bold text-gray-700 tracking-wider">
                                Wallet
                            </th>
                        </tr>
                    </thead>
                    <tbody className=''>
                        {
                            foundUsers.length > 0
                            ?
                            foundUsers.map((user) => (
                                    <Users 
                                        user={user} 
                                        key={user.id} 
                                        getSingleUser={getSingleUser} 
                                        // handleEdit={handleEdit} 
                                        deleteUser={deleteUser}
                                        // cancelEditForm={cancelEditForm} 
                                        user_roles={user_roles} 
                                        updateUser={updateUser}
                                    />
                                
                                ))
                            :
                            <tr>
                                <td colSpan="5" className="text-center">No Users Found.</td>
                            </tr> 
                        }
                    
                    </tbody>
                    </table>
                    <div>
                        {
                            displayCreateUserForm &&
                            <AddUser 
                                user_roles={user_roles} 
                                handleCancle={handleCancle}
                            /> 
                        }
                    </div>
                    <div>
                        {
                            displayCpmCreateUserForm &&
                            <AddUserCpm 
                                user_roles={user_roles} 
                                handleCancle={handleCancle}

                            />
                        }
                    </div>
                    {/* <div>
                        {
                            isEditing ? 
                            <UserEdit 
                                user={user} 
                                cancelEditForm={cancelEditForm} 
                                user_roles={user_roles} 
                                updateUser={updateUser}
                            /> : null
                        }
                    </div> */}
                </div>

            </div>
            <div className='' style={{display: viewMyPermissions}}>
                <button onClick={goBackToViewUsers} className="flex justify-center items-center h-10">
                    <IoPlayBackOutline className='mx-1 text-xl'/>
                    <p className='px-1'>Back</p>                                    
                
                </button>
                <div className='border-4 border-gray-300'>
                    <div className='flex flex-col justify-center my-4 mr-16'>
                        <div className="flex justify-center items-center font-semibold">
                            <p className='px-1'>Hello, {user_details.first_name} {user_details.last_name}</p>                                    
                        </div>  
                        <div className="flex justify-center items-center h-10">
                            <p className='px-1'>These are your roles and associated permissions</p>                                    
                        </div>             
                    </div>
                    <div className='flex '>
                        <div className='flex flex-col w-1/2'>
                            <h3 className='flex justify-center items-center bg-[#E2F0D9] mx-4 h-12'>What you can access</h3>
                            <div className="overflow-y-scroll h-80 mx-4">
                                <table className="table border-collapse min-w-full h-80 px-2">
                                {
                                    permissions.length > 0 ?
                                    permissions.map((permission)=>(
                                        <UserPermissions permission={permission} key={permission.id} />
                                    
                                ))  : 
                                <tr>
                                    <td colSpan="5" className="text-center">No Users Found.</td>
                                </tr> 
                                }
                                </table>
                            </div>
                        </div>
                        <div className='flex flex-col w-1/2'>
                            <h3 className='flex justify-center items-center bg-red-300 mx-4 h-12'>What you can't access</h3>
                            <div className="overflow-y-scroll h-80 mx-4">
                                <table className="table border-collapse min-w-full h-80 px-2">
                                        <thead>

                                            {/* <tr className='border'>
                                                <th className="flex justify-center items-center bg-red-300 h-12">
                                                    What you can't access
                                                </th>
                                            </tr> */}
                                        </thead>
                                        <tbody className='overflow-y-scroll border'>
                                        {
                                                permissionNotHaving.length
                                                ?
                                                permissionNotHaving.map((unassignedPermissions, index) => (
                                                    <UnauthorizedPermissions unassignedPermissions={unassignedPermissions} key={index}/>
                                                ))
                                                :
                                                <tr>
                                                    <td colSpan="5" className="text-center">
                                                        <h3 className='font-semibold'>You have full access!</h3>
                                                        <p className='text-gray-400'>You can access all sections of the dashboard</p>
                                                    </td>
                                                </tr> 
                                            }
                                        
                                        </tbody>
                                </table>
                            </div>
                        </div>
                      

                    </div>
                </div>
              
            </div>
        </div>
    </div>  
    )
}

export default Read