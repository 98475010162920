import React, {useState, useContext, useEffect} from 'react'
import Sidebar from '../Sidebar'
import Navbar from '../../Navbar'
import SmsCredit from '../../SmsCredit'
import UploadPricelist from './UploadPricelist'
import ExtractedFile from './ExtractedFile'
import { read, utils } from 'xlsx';
import axios from 'axios'
import Button from './Button'
import UploadSuccessful from './UploadSuccessful'
import UserContext from '../../../context/user/userContext'
import Users from './Users'
import UploadPersonalisedPricelist from './UploadPersonalisedPricelist'

const Index = () => {
    const userContext = useContext(UserContext);

    
    const { 
        users, 
        getAllUsers, 
        loading, 
    } = userContext;

    useEffect(() => {
        getAllUsers()  
      }, []);

    const token = localStorage.getItem("token");

    const [fileUpload, setfileUpload] = useState(false)
    const [showExtractedFile, setshowExtractedFile] = useState(false)
    const [showSuccessModal, setShowSuccessModal] = useState(false)

    const [uploads, setUploadsFile] = useState([])
    const [uploadPersonalisedPricing, setUploadPersonalisedPricing] = useState(false)

    const [data, setData] = useState([])
    const [cols, setCols] = useState([])

    const [globalPricingBtnColor, setGlobalPricingBtnColor] = useState('#ECF3FA')
    const [personalisedPricingBtnColor, setpersonalisedPricingBtnColor] = useState('')

    const [iD, setId] = useState(null)

    
    const [globalPricing, setglobalPricing] = useState(true)
    const [personalisedPricing, setPersonalisedPricing] = useState(false)
    const [uploadType, setuploadType] = useState('global standard pricing')



    const clickToMakeGlobalPricingActive = () => {
        setGlobalPricingBtnColor('#ECF3FA')
        setpersonalisedPricingBtnColor('')
        setPersonalisedPricing(false)
        setUploadPersonalisedPricing(false)
        setglobalPricing(true)
        setuploadType('global standard pricing')


    }

    const clickToMakePersonalisedPricingActive = () => {
        setGlobalPricingBtnColor('')
        setpersonalisedPricingBtnColor('#ECF3FA')
        setPersonalisedPricing(true)
        setglobalPricing(false)
        setuploadType('personalised pricing')

    }


    const clickToUploadPersonalisedPricing = (id) => {
        setGlobalPricingBtnColor('')
        setpersonalisedPricingBtnColor('#ECF3FA')
        setPersonalisedPricing(false)
        setglobalPricing(false)
        setUploadPersonalisedPricing(true)
        setuploadType('personalised pricing')
        setId(id)


    }




    const make_cols = refstr => {
        let o = [],
        C = utils.decode_range(refstr).e.c + 1;
        for (var i = 0; i < C; ++i) o[i] = { name: utils.encode_col(i), key: i };
        return o;
    };

    const handleSpreedSheetImport = ($event) => {
        const files = $event.target.files;
        if (files.length) {
            const file = files[0];
            const reader = new FileReader();

            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;

                const wsname = wb.SheetNames[0];

                const ws = wb.Sheets[wsname];

                if (sheets.length) {
                    const rows = utils.sheet_to_json(ws, { header: 1 });

                    const result = rows.map(([value]) => value);

                    const rowss = utils.sheet_to_json(wb.Sheets[sheets[0]]);

                    setUploadsFile(rowss)


                    const cols = make_cols(ws["!ref"])

                    setData(rows)
                    setCols(cols)

                }
            }
            reader.readAsArrayBuffer(file);

            setfileUpload(false)
            setshowExtractedFile(true)

        }
    }

    const goUploadFileConfirmationModal = () => {
        setfileUpload(true)
        setshowExtractedFile(false)

    }

    const cancelExtractedUploadFileModal = () => {
        setshowExtractedFile(false)
    }

    const cancelUploadFileConfirmationModal = () => {
        setfileUpload(false)
    }

    const gotoSuccessModal = () => {
        setfileUpload(false);
        setShowSuccessModal(true)
    }


    const gotoCancelSuccessModal = () => {
        setShowSuccessModal(false)
    }


    const  sendSmsParamsToBackend = async (credentials) => {
        const headers = {
             'Content-Type': 'application/json', 
             Authorization: 'Bearer ' + token,    
         }
         axios.post(`https://apis.mutani.co/api/pricelist/`, 
         JSON.stringify(credentials),
         {
            headers: headers,
    
        })
        .then((response) => {
          const data = response
          if (data.status == 200) {
            gotoSuccessModal()
            
        }
          console.log(data)
         
        })
        .catch(function (error) {
            console.error(error);
        });
    }

    const  makeRequestToPersonalisedPricingApi = async (credentials) => {
        const headers = {
             'Content-Type': 'application/json', 
             Authorization: 'Bearer ' + token,    
         }
         axios.post(`https://apis.mutani.co/api/pricelist/personalised`, 
         JSON.stringify(credentials),
         {
            headers: headers,
    
        })
        .then((response) => {
          const data = response
          if (data.status == 200) {
            gotoSuccessModal()  
        }
         
        })
        .catch(function (error) {
            console.error(error);
        });
    }


    const handleSubmit = async (e) => 
    {
        if (globalPricing === true) {
            return await sendSmsParamsToBackend({
                uploads,          
            });
            
        }else if (uploadPersonalisedPricing) {
            return await makeRequestToPersonalisedPricingApi({
                uploads,   
                iD       
            });            
        }
            
    }


  return (
    <div className='flex'>
        <div className='hidden md:block md:w-1/6 bg-[#394975] h-[49rem] text-white'>
          <Sidebar/>
        </div>
        <div className='w-full md:w-5/6 bg-[#ECF3FA]'>
         <Navbar/>
         <div className='bg-white mt-8 mx-10 pb-2'>
            <SmsCredit/>
            <div className='flex ml-10 text-black mt-6'>
                <div className='flex w-full ml-4 mr-14'>
                    <Button 
                        clickToMakeButtonActive={clickToMakeGlobalPricingActive} 
                        className='' text='Set Global Standard Pricing' 
                        color={globalPricingBtnColor}
                        />
                    <Button 
                        clickToMakeButtonActive={clickToMakePersonalisedPricingActive} 
                        className=''
                        text='Set Personalised Pricing' 
                        color={personalisedPricingBtnColor}
                        />
                </div>
               
            </div>
            <div className='block md:flex items-center justify-center mx-2'>
                <div className='flex flex-col w-full my-2 md:mx-2 h-[20rem]'>
                    {
                        globalPricing &&
                        <div className='mx-8 h-80'>
                            <div className='mt-8'>
                                <div className='border mx-2'>
                                    <p className='text-xs text-gray-400 pl-2 ml-2 my-2'>Upload Contact (Format: CSV, XLSX, XLS)</p>
                                    <div className='flex justify-center items-center w-1/2 mx-4 h-20 border border-gray-300 my-4'>
                                        <input type="file" name='file' className='text-gray-700 ml-2 mx-2 px-4 file:h-10 file:w-40 hover:file:cursor-pointer hover:file:bg-white'
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        required onChange={handleSpreedSheetImport}/>   
                                    </div>
                                    
                                </div>    
                            </div>
                        </div>
                    }
                    {
                        personalisedPricing &&
                        <div className="overflow-y-scroll h-80 mx-10 mt-4">
                            <table className="table border-collapse min-w-full h-90 px-2">
                                <thead>
                                    <tr className='text-left'>
                                        <th className="text-xs font-bold tracking-wider">
                                            Customer ID
                                        </th>
                                        <th className="text-xs font-bold text-gray-700">
                                            Email
                                        </th>
                                        <th className="py-2 text-xs font-bold text-gray-700 tracking-wider">
                                            Full Name
                                        </th>
                                        <th className="py-2 text-xs font-bold text-gray-700 tracking-wider">
                                            User Group
                                        </th>
                                        <th className="py-2 text-xs font-bold text-gray-700 tracking-wider">
                                            Wallet
                                        </th>
                                        <th className="py-2 text-xs font-bold text-gray-700 tracking-wider">
                                            Set Pricing
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className='text-left'>
                                    {
                                        users.length > 0
                                        ?
                                        users.map((user) => (
                                                <Users 
                                                    user={user} 
                                                    key={user.id} 
                                                    clickToUploadPersonalisedPricing={clickToUploadPersonalisedPricing}
                                                />
                                            
                                            ))
                                        :
                                        <tr>
                                            <td colSpan="5" className="text-center">No Users Found.</td>
                                        </tr> 
                                    }
                                
                                </tbody>
                            </table>
                        </div>
                    }
                    {
                        uploadPersonalisedPricing &&
                        <div className='mx-8 h-80'>
                            <div className='mt-8'>
                                <div className='border mx-2'>
                                    <p className='text-xs text-gray-400 pl-2 ml-2 my-2'>Upload Contact (Format: CSV, XLSX, XLS)</p>
                                    <div className='flex justify-center items-center w-1/2 mx-4 h-20 border border-gray-300 my-4'>
                                        <input type="file" name='file' className='text-gray-700 ml-2 mx-2 px-4 file:h-10 file:w-40 hover:file:cursor-pointer hover:file:bg-white'
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        required onChange={handleSpreedSheetImport}/>   
                                    </div>
                                    
                                </div>    
                            </div>
                        </div>
                    }
                </div>
            </div> 
          </div>
        </div>
        {
            fileUpload && <UploadPricelist 
                handleSubmit={handleSubmit}
                uploadType={uploadType}
                goUploadFileConfirmationModal={goUploadFileConfirmationModal} 
                cancelUploadFileConfirmationModal={cancelUploadFileConfirmationModal}
            />
        }
        {
            showSuccessModal && 
            <UploadSuccessful 
                gotoCancelSuccessModal={gotoCancelSuccessModal}
            />
        }
        
        {
            showExtractedFile && 
            <ExtractedFile 
            goUploadFileConfirmationModal={goUploadFileConfirmationModal} 
            cancelExtractedUploadFileModal={cancelExtractedUploadFileModal} 
            data={data} 
            cols={cols} 
            handleSubmit={handleSubmit}
            />
        }

    </div>  
    )
}

export default Index