import React, {useState} from 'react'
import { Link, useNavigate  } from "react-router-dom";

const ChangePassword =  ({handleCancel, changeUserPassword, pass_change, onOkayPress}) => {
  const [old_password, setOldPassword] = useState("")
  const [new_password, setNewPassword] = useState("")
  const [new_password_confirmation, setNewPasswordConfirmation] = useState("")
  const [passwordError, setPasswordError] = useState("none")
  const [passwordMatchingError, setPasswordMatchingError] = useState('none')
  const [passwordIncorrectlyFormError, setPasswordIncorrectlyFormError] = useState('none')
  const navigate = useNavigate()

  
  const onChangeNewPassword = (e) => {
    var regex =  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;

    setNewPassword(e.target.value)

    if(new_password.match(regex) === null){
      setPasswordIncorrectlyFormError('');     
    }else{
      setPasswordIncorrectlyFormError('none');

    }
  }

  const onChangeNewPasswordConf = (e) => {
    setNewPasswordConfirmation(e.target.value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    var regex =  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;

    if(new_password.match(regex) === null)
    {
      setPasswordIncorrectlyFormError('');   

      return setTimeout(() => {
        setPasswordIncorrectlyFormError('none')
        
    }, 5000); 


    }else if (new_password !== new_password_confirmation ) {
       setPasswordMatchingError('')

       return setTimeout(() => {
        setPasswordMatchingError('none')
        
    }, 5000);
      
    }else{

    await changeUserPassword({
      old_password,
      new_password,
      new_password_confirmation

    })

    if ('failed' in pass_change) {
      setPasswordError('')

      return setTimeout(() => {
        setPasswordError('none');
      }, 3000); // Delay of 3000 milliseconds (3 seconds)
    }else {
        return navigate('/dashboard',{replace: true})
    }
  }



  }
  return (
<>
    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-6xl">
          <form  
            onSubmit={handleSubmit} 
            className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-center justify-center p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">
                Change Password
              </h3>
            </div>
            <div className="relative p-6 flex-auto">
              <div className="my-4 text-slate-500 text-lg leading-relaxed h-72 w-[50rem]">
              <div>
                <div className="mt-2">
                  <label htmlFor="email" className="flex ml-[5rem]">
                      Current Password <p className='text-red-500'> * </p> 
                  </label>
                  <div className="flex justify-center items-center mt-1">
                    <input
                        type="text"
                        onChange={(e) => setOldPassword(e.target.value)}
                        className="border-gray-300 border rounded w-4/5 h-10 mx-2"
                        placeholder='Old Password'
                        required
                    />

                </div>
                <p className='flex items-center ml-[5rem] text-sm text-red-500 mt-1' style={{display: passwordError}}>Current Password does not match old password</p>

                </div>
                <div className="my-2">
                  <label htmlFor="email" className="flex ml-[5rem]">
                      New Password <p className='text-red-500'> * </p> 
                  </label>
                  <div className="flex justify-center items-center mt-1">
                    <input
                        type="text"
                        onChange={onChangeNewPassword}
                        className="border-gray-300 border rounded w-4/5 h-10 mx-2"
                        placeholder='New Password'
                        required
                    />
                </div>
                <p className='flex items-center ml-[5rem] text-sm text-red-500 mt-1' style={{display: passwordIncorrectlyFormError}}>Must be 7-15 characters, 1 number and 1 Special Characeter</p>

                </div>
                <div className="">
                  <label htmlFor="email" className="flex ml-[5rem]">
                      Confirm New Password <p className='text-red-500'> * </p> 
                  </label>
                  <div className="flex justify-center items-center mt-1">
                    <input
                        type="text"
                        onChange={onChangeNewPasswordConf}
                        className="border-gray-300 border rounded w-4/5 h-10 mx-2"
                        placeholder='Confirm New Password'
                        required
                    />
                </div>
                <p className='flex items-center ml-[5rem] text-sm text-red-500 mt-1' style={{display: passwordMatchingError}}>Password do not match</p>

                </div>
                
                <div className="flex items-center justify-between mx-16 p-6 ">
                </div>
                {/* <div className='border-t border-solid border-slate-200 rounded-b mb-12 mx-12'>

                </div> */}
            </div>
              </div>
            </div>
            <div className="flex items-center justify-between p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                    className="bg-[#ffc000] active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 mx-8"
                    type="button"
                    onClick={handleCancel}
                >
                Cancel
                </button>

                <input 
                 className="bg-emerald-500 mr-8 hover:cursor-pointer  text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                type="submit" 
                placeholder='Change Password' />
              
              {/* <input
                className="bg-emerald-500 mr-8  text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                type="submit"
                // onClick={handleSubmit}
                >
                Change Password
                </input> */}
            </div>
          </form>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>   )
}
export default ChangePassword