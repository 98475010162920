import React, { useState } from 'react'


const EditPermission = ({permission, cancelEditForm, updatePermission}) => {
    const [name, setName] = useState(permission.name)



    const handlePermissionUpdate = async (e) => {
        e.preventDefault()
        const guard_name = 'web'
        await updatePermission({name, guard_name}, permission.id)
    }
  return (
    <>
    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-6xl">
          <form  className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <div className="flex items-center justify-center p-5 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-3xl font-semibold">
                Add New Permission
              </h3>
            </div>
            <div className="relative p-6 flex-auto">
              <div className=" text-slate-500 text-lg leading-relaxed h-20 w-[40rem]">
              <div>
                <div className="">
                        <label htmlFor="email" className="flex ml-[4rem]">
                            Permission Name <p className='text-red-500'> * </p> 
                        </label>
                        <div className="flex justify-center items-center mt-1">
                        <input
                            type="text"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            className="border-gray-300 border rounded w-4/5 h-10 mx-2"
                            placeholder='Add New Permission'
                        />
                    </div>
                </div>
                <div className="flex items-center justify-between mx-16 p-6 ">
                </div>
            </div>
              </div>
            </div>
            <div className="flex items-center justify-between p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                    className="bg-[#ffc000] active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 mx-8"
                    type="button"
                    onClick={cancelEditForm}
                >
                Cancel
                </button>
              <button
                className="bg-emerald-500 mr-8  text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                type="button"
                // onClick={() => {
                //     updatePermission(permission.id)
                // }}

                onClick={handlePermissionUpdate}
                >
                Update Permission
                </button>
            </div>
          </form>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </> 
    )
}

export default EditPermission