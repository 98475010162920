import React from 'react'
import { Link } from "react-router-dom";

const Applications = () => {
    return (
        <div className='h-80 bg-white mx-12'>
            <div className=' border-4 py-4 border-gray-100  bg-white h-72 mx-16'>
            <div className='flex justify-between'>
                <div className="justify-start h-8 mx-12 mb-2 py-4 text-black font-bold">My Applications</div>
            </div>
            <div className='mx-2 font-roboto'>
                <div className='block md:flex items-center justify-center h-full md:h-20 mx-4 my-4'>
                    <Link to='/bam/sms' className='flex flex-col justify-center items-center w-1/4 h-20 bg-[#ECF3FA] ml-4'>
                        <p className='text-2xl bold text-gray-700'>BAM</p>
                    </Link>
                    <Link to='/report' className='flex cursor-pointer flex-col justify-center items-center w-1/4 h-20 bg-[#ECF3FA] ml-4'>
                        <p className='text-2xl bold text-gray-700'>BiRD</p>
                    </Link>   
                    <div className='flex flex-col justify-center items-center w-1/4 h-20 bg-[#ECF3FA] ml-4'>
                        <div className='flex flex-col my-4 justify-center items-center'>
                            <p className='text-2xl bold text-gray-700'>AdDirect</p>
                        </div>   
                    </div>
                    <div className='flex flex-col justify-center items-center w-1/4 h-20 bg-[#ECF3FA] ml-4'>
                        <div className='flex flex-col my-4 justify-center items-center'>
                            <p className='text-2xl bold text-gray-700'>ReSeller</p>
                        </div>   
                    </div>
                </div>
                <div className='block md:flex items-center justify-center h-full md:h-20 mx-4 my-4'>
                    <Link to='/usermanager' className='flex flex-col justify-center items-center w-1/4 h-20 bg-[#ECF3FA] ml-4'>
                            <p className='text-2xl bold text-gray-700'>User Manager</p>
                    </Link>
                    <div className='flex flex-col justify-center items-center w-1/4 h-20 bg-[#ECF3FA] ml-4'>
                        <div className='flex flex-col my-4 justify-center items-center'>
                            <p className='text-2xl bold text-gray-700'>CPM</p>
                        </div>   
                    </div>
                    <div className='flex flex-col justify-center items-center w-1/4 h-20 bg-[#ECF3FA] ml-4'>
                        <div className='flex flex-col my-4 justify-center items-center'>
                            <p className='text-2xl bold text-gray-700'>BusPas</p>
                        </div>   
                    </div>
                    <div className='flex flex-col justify-center items-center w-1/4 h-20 bg-[#ECF3FA] ml-4'>
                        <div className='flex flex-col my-4 justify-center items-center'>
                            <p className='text-2xl bold text-gray-700'>Billing Manager</p>
                        </div>   
                    </div>
                </div> 
            </div>
     
        </div>

        </div>
      
    )
}

export default Applications