import React from 'react'
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

const Info = () => {
  return (
    <div className="md:bg-[#f4f9fd] h-screen w-full font-roboto ">
         <div class="hidden md:flex flex-col justify-center items-center h-screen text-[#757575]">
            <div className='flex flex-col justify-center items-center md:w-1/3 lg:2/3 h-[38rem]  bg-white my-16'>
                <div className='w-full'>
                    <div className='flex justify-between items-center mx-12 mt-10 lg:mt-6'>
                        <div>
                            <img
                                src={process.env.PUBLIC_URL + `/images/logo.png`}
                                alt=''
                                className='h-24 w-24'
                                id='co_founder'
                            />  
                        </div>
                    

                    </div>
                    <h1 className='my-4 mx-12 text-gray-500'>Reset Password</h1>
                    <h2 className='my-4 mx-12 leading-tight'>Check your email for a link to reset your password. If it doesn’t appear within a few minutes, check your spam folder.</h2>   
                    <div className='flex mt-10 mx-12'>
                        <div className='flex justify-center items-center bg-[#B0DD7F] h-10 w-10'>
                            <MdOutlineKeyboardArrowRight className=''/>
                        </div>
                        <button className='bg-[#92D050] h-10 text-white w-full'>Sign In</button>
                    </div>
                </div>  
                        
            </div>
        </div>

    </div>
  )
}

export default Info