import React,{useState, useEffect, useContext} from 'react'
import {GrFormAdd} from 'react-icons/gr'
import SenderIdContext from '../../context/senderids/senderidContext';
import RequestSenderIds from './RequestSenderIds';
import UserSenderIds from './UserSenderIds';

const ViewAllSenderIds = () => {
    const senderIdContext = useContext(SenderIdContext);
    const { 
        getUserSenderIdByUserEmail,
        userSenderIds,
        sendSenderIdNotificationToAdmin,
        getSenderIds,
        sender_id
    } = senderIdContext;

    const [displayRequestSenderIds, setDisplayRequestSenderIds] = useState(false)

    const user = localStorage.getItem("user");
    const parseUser = JSON.parse(user);
    const email = parseUser.email

    useEffect(() => {
        getUserSenderIdByUserEmail(email)
        getSenderIds()
      },[])


      const viewRequestSenderIdsForm = () => {
        setDisplayRequestSenderIds(true)
      }

      const handleCancle = () => {
        setDisplayRequestSenderIds(false)

      }


  return (
    <div className='block md:flex items-center justify-center mx-2'>
    <div className='flex flex-col w-full my-2 md:mx-2 h-[28rem]'>
        <div className='border-4 border-gray-300 h-[28rem]'>
            <div 
            // style={{ display: viewPermissions }}
            >
                <div className='flex justify-between my-8 mr-16'>
                    <div className='flex justify-start'>
                      {/* <Search 
                        searchRole={searchPermission} 
                        onSearchButtonClick = {onSearchButtonClick}
                        inputName = {inputName}
                        onNameChange = {onNameChange}
                      /> */}
                    </div>
                    
                    <div className='flex justify-end' 
                        // style={{display : viewUsersTable}}
                    >
                          <button  
                            onClick={viewRequestSenderIdsForm} 
                            className="flex justify-center items-center bg-[#00B050] mr-16 w-52 text-white h-10">
                              <GrFormAdd className='text-xl mx-1 bg-white'/>
                              <p className='px-1 text-xs'>Request New Sender ID</p>                                    
                          </button>      
                      </div>

                </div>
                {/* <p className='flex justify-center items-center mb-2 font-bold tracking-wider text-lg'>Permissions</p> */}
                <div 
                    // style={{display: viewUserTable}}  
                    className="overflow-y-scroll h-40 mx-32"
                >
                    <table className="table border-collapse min-w-full h-40 ">
                      <thead className=''>
                          <tr className='text-center'>
                            <th className="tracking-wider text-lg">
                                IDs
                              </th>
                              <th className="tracking-wider text-lg">
                                  Sender IDs
                              </th>
      
                          </tr>
                      </thead>
                      <tbody className=''>
                      {
                              userSenderIds.length > 0
                              ?
                              userSenderIds.map((userSenderId, index) => (
                                    <UserSenderIds 
                                      key={userSenderId.id} 
                                      userSenderId={userSenderId} 
                                      index={index}
                                    />
                              ))
                              :
                              <tr>
                                  <td colSpan="5" className="text-center">No SenderIds Found.</td>
                              </tr> 
                      }
                      
                      </tbody>
                    </table>
                    
                </div> 
            </div>
            {
                displayRequestSenderIds ? 
                <RequestSenderIds 
                    sender_id={sender_id}
                    handleCancle={handleCancle}
                    sendSenderIdNotificationToAdmin={sendSenderIdNotificationToAdmin}
                /> 
                : null
            }
           
          
        </div>
      
    </div>
  </div>  
    )
}

export default ViewAllSenderIds