import {
    GET_ALL_PERMISSIONS,
    SET_LOADING,
    SET_LOADING_OFF,
    ADD_NEW_ROLE,
    ADD_NEW_PERMISSION,
    SEARCH_PERMISSION,
    PERMISSION_SEARCH
  } from '../types';
  
  export default (state, action) => {
    switch (action.type) {   
      case GET_ALL_PERMISSIONS: {
        return {
          ...state,
          allPermissions: action.payload,
          permissionLoading: false
        };
      }
      case PERMISSION_SEARCH: {
        return {
          ...state,
          searchResults: action.payload,
          permissionLoading: false
        };
      }
      case ADD_NEW_ROLE: {
        return {
          ...state,
          newRole: action.newRole,
          permissionLoading: false
        };
      }
      case ADD_NEW_PERMISSION: {
        return {
          ...state,
          newPermission: action.newPermission,
          permissionLoading: false
        };
      }

      case SEARCH_PERMISSION: {
        return {
          ...state,
          foundPermissions: action.foundPermissions,
          permissionLoading: false
        };
      }
  
      case SET_LOADING:
        return {
          ...state,
          permissionLoading: true
        };
      case SET_LOADING_OFF: {
        return {
          ...state,
          permissionLoading: false
  
        };
      }
      default:
        return state;
    }
  };
  