import React, {useState} from 'react'

const Search = ({inputName, onNameChange, onSearchButtonClick}) => {

  return (
        <div onSubmit={onSearchButtonClick} className="flex items-center">
            <form className="flex border border-purple-200 rounded">
                <input
                    type="text"
                    name='name'
                    className="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md focus:border-green-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
                    placeholder="Search..."
                    onChange={onNameChange}
                    value={inputName}

                />
                <input 
                    type='submit' 
                    value='Search'
                    className="px-4 text-white bg-[#00B050] border-l rounded "
                />
            </form>
        </div>  
    )
}

export default Search