import React, {useState} from 'react'
import {FaTimes} from 'react-icons/fa'
import ResendOTPTimer from './ResendOTPTimer';

const OtpVerification = ({cancelotpVerify, otpVerify}) => {
  const [otp, setOtpChange] = useState('')
  const [timeOutMessage, setTimeOutMessage] = useState('none')
  const [showTimeOutMessage, setShowTimeOutMessage] = useState('')
  const [showPaymentBtn, setshowPaymentBtn] = useState('')
  const [showResendOtpBtn, setshowResendOtpBtn] = useState('none')
  const [disableBtnWhenTimerIsOut, setdisableBtnWhenTimerIsOut] = useState(false)

  


  
  const momo_no = localStorage.getItem("momo_no");
  const reference = localStorage.getItem("ref");

  const handleResendOTP = () => {
    setTimeOutMessage('')
    setShowTimeOutMessage('none')
    setshowPaymentBtn('none')  
    setshowResendOtpBtn('')
    setdisableBtnWhenTimerIsOut(true)
  };

  
  async function makeRequestToPaystack(credentials) {
    return fetch('https://api.paystack.co/charge/submit_otp', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer sk_live_19d639296e60069cef35e2f2289f7132945003f6`,

      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
  }
  
  const handleSubmit = async (e) => {
    e.preventDefault()

    const response = await makeRequestToPaystack({
      otp,
      reference,
    })

    if ('data' in response) {
        localStorage.removeItem('ref');
        localStorage.removeItem('momo_no');
        otpVerify()
      } else {
    }

  }

  return (
    <>
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
    >
      <div className="relative w-auto my-6 mx-auto max-w-3xl">
        {/*content*/}
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex items-start justify-between p-5 border-b border-solid border-slate-300 rounded-t">
            <div></div>
            <h3 className="text-2xl justify-center text-gray-500 font-semibold">
              Mobile Money
            </h3>
            <div 
              onClick={cancelotpVerify} 
              className="flex items-center justify-center h-8 hover:cursor-pointer text-3xl font-semibold">
              <FaTimes className='flex justify-center items-center fill-gray-500 text-xl'/>
            </div>
          </div>
          {/*body*/}
          <div className="relative p-6 flex-auto">
            <h3 className='flex justify-center items-center w-full'>OTP VERIFICATION</h3>
            <h3 className='flex justify-center items-center w-full text-green-500 text-sm my-2'>A One Time Password (OTP) has been sent to {momo_no}</h3>

            <form 
              onSubmit={handleSubmit}
            >
                <div className="flex flex-col justify-center items-center mx-6 w-96 h-48">
                      <div className="flex flex-col w-full">
                        <label htmlFor="first_name" className="my-1 text-sm flex" style={{display: showTimeOutMessage}}>
                          Enter OTP before timer runs out  <ResendOTPTimer resendOTP={handleResendOTP}/>
                        </label>
                        <label htmlFor="first_name" className="my-1 text-sm flex text-red-500" style={{display: timeOutMessage}}>
                          Timer is runned out, resquest new OTP
                        </label>
                        <input
                        type="text"
                        name="first_name"
                        placeholder=''
                        disabled={disableBtnWhenTimerIsOut}
                        onChange={(e)=> setOtpChange(e.target.value)}
                        className="border-gray-300 border w-full h-10  placeholder:px-2"
                        />
                    </div>
                    <div 
                      style={{display: showPaymentBtn}} 
                      className="flex flex-col items-center justify-center p-6 w-full  rounded-b">
                      <button
                        className="bg-[#00B050] text-white w-full font-bold uppercase text-sm my-4 px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={handleSubmit}
                      >
                        Authorized Payment
                      </button>
                      
                    </div>
                    <div 
                      style={{display: showResendOtpBtn}} 
                      className="flex flex-col items-center justify-center p-6 w-full  rounded-b">
                      <button
                        className="bg-[#00B050] text-white w-full font-bold uppercase text-sm my-4 px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        // onClick={handleSubmit}
                      >
                        Resend OTP
                      </button>
                      
                    </div>
                  
              
                </div>
              
                
            </form>
          </div>
          {/* <div className="flex flex-col items-center justify-center p-6 border-t border-solid border-slate-300 rounded-b w-full">

            <p className='w-full text-sm'>An additional E-levy fee of 1% may apply to this payment. <br /> Learn more.</p>
            <p className='flex w-full text-sm'>Wrong number? Re-enter number</p>

            <button
              className="bg-[#8497B0] text-white w-48 font-bold uppercase text-sm my-4 px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              // onClick={handleSubmit}
            >
              Resend OTP
            </button>
            
          </div> */}
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>  
    )
}

export default OtpVerification