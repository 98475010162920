import React,{useContext, useEffect, useState } from 'react'
import RoleContext from '../../../context/roles/roleContext'
import PermissionContext from '../../../context/permissions/permissionsContext'
import {BsPencilSquare, BsTrash} from 'react-icons/bs'
import {GrFormAdd} from 'react-icons/gr'
import Roles from './Roles'
import AddRoles from './AddRoles'
import Search from './Search'


const ViewAllRoles = () => {
    const roleContext = useContext(RoleContext);
    const permissionContext = useContext(PermissionContext);

    const [displayAddRoleForm, setDisplayAddRoleForm] = useState(false)
    const [viewRoles, setViewRoles] = useState("")
    const [inputName, setInputName] = useState("")
    const [viewSearchResults, setViewSearchResults] = useState('none')
    const [viewUserTable, setViewUserTable] = useState("")
    const [viewPerm, setViewPerm] = useState("")
    const [viewUsersTable, setViewUsersTable] = useState("none")

    const { 
      getAllRolesFromDB, 
      user_roles,
      deleteRole,
      updateRole,
      foundRole,
      searchRole,
      loadding
  } = roleContext;

  const { 
    getAllPermissionsFromDB, 
    allPermissions,
    permissionLoading, 
    } = permissionContext;

  useEffect(() => {
    getAllRolesFromDB()
    getAllPermissionsFromDB()

  },[])

  const onSearchButtonClick = e =>{
    e.preventDefault();
    if (inputName === '') {
        console.log('Please enter something');
    } else {
        setViewUserTable('none')
        setViewPerm('none')
        searchRole(inputName);
        setViewSearchResults("")
        setInputName('');
        setViewUsersTable('')
    }

}

const onNameChange = (e) => {setInputName(e.target.value)}



  const addRole = () => {
    // e.preventDefault()
    setViewRoles('none')
    setDisplayAddRoleForm(true)



  }

  const handleCancle = (e) => {
    e.preventDefault()
    setDisplayAddRoleForm(!displayAddRoleForm)
    setViewRoles('')


}


const goBackToViewUsersTable = () =>
{
        setViewUserTable('')
        setViewPerm('')
        setViewSearchResults("none")
        setViewUsersTable('none')
}



  

  return (
<div className='block md:flex items-center justify-center mx-2'>
        <div className='flex flex-col w-full my-2 md:mx-2 h-[28rem]'>
            <div className='border-4 border-gray-300 h-[28rem]'>
                <div style={{ display: viewRoles }}>
                    <div className='flex justify-between my-8 mr-16'>
                        <div className='flex justify-start ml-16'>
                            <Search 
                                searchRole={searchRole} 
                                onSearchButtonClick = {onSearchButtonClick}
                                inputName = {inputName}
                                onNameChange = {onNameChange}
                            />
                        </div>
                        <div className='flex justify-end' style={{display : viewPerm}}>
                            {/* <button onClick={viewRolesAndPermissions} className="flex justify-center items-center bg-gray-200 mx-2 h-10">
                                <GrSettingsOption className='mx-1'/>
                                <p className='px-1'>view roles and permissions</p>                                    
                            </button> */}
                            <button onClick={addRole} className="flex justify-center items-center bg-[#00B050] mr-16 w-32 text-white h-10">
                                <GrFormAdd className='text-xl mx-1 bg-white'/>
                                <p className='px-1 text-xs'>Add New Role</p>                                    
                            </button>                
                        </div>
                        <div className='flex justify-end' style={{display : viewUsersTable}}>
                            <button  onClick={goBackToViewUsersTable} className="flex justify-center items-center bg-[#00B050] mr-16 w-52 text-white h-10">
                                <GrFormAdd className='text-xl mx-1 bg-white'/>
                                <p className='px-1 text-xs'>View All Permissions</p>                                    
                            </button>      
                        </div>

                    </div>
                    <div style={{display: viewUserTable}}  className="overflow-y-scroll h-80 mx-16">
                        <table className="table border-collapse min-w-full h-90 px-2">
                        <thead>
                            <tr className='text-left'>
                                <th className="text-xs font-bold tracking-wider">
                                    Roles
                                </th>
                                <th className="text-xs font-bold tracking-wider pl-4">
                                    Permissions
                                </th>
                            </tr>
                        </thead>
                        <tbody className=''>
                        {
                                user_roles.length
                                ?
                                user_roles.map((role) => (
                                    <>
                                        <Roles 
                                            role={role} 
                                            key={role.id} 
                                            deleteRole={deleteRole} 
                                            allPermissions = {allPermissions}
                                            updateRole = {updateRole}
                                            />

                                    </>
                                ))
                                :
                                <tr>
                                    <td colSpan="5" className="text-center">No Roles Found.</td>
                                </tr> 
                            }
                        
                        </tbody>
                        </table>
                        
                    </div>
                    <div style={{display: viewSearchResults}}  className="overflow-y-scroll h-80 mx-16">
                        <table className="table border-collapse min-w-full h-90 px-2">
                        <thead>
                            <tr className='text-left'>
                                <th className="text-xs font-bold tracking-wider">
                                    Roles
                                </th>
                                <th className="text-xs font-bold tracking-wider pl-4">
                                    Permissions
                                </th>
                            </tr>
                        </thead>
                        <tbody className=''>
                        {
                                foundRole.length > 0
                                ?
                                foundRole.map((role) => (
                                    <>
                                        <Roles 
                                            role={role} 
                                            key={role.id} 
                                            deleteRole={deleteRole} 
                                            allPermissions = {allPermissions}
                                            updateRole = {updateRole}
                                            />

                                    </>
                                ))
                                :
                                <tr>
                                    <td colSpan="5" className="text-center">No Roles Found.</td>
                                </tr> 
                            }
                        
                        </tbody>
                        </table>
                        
                    </div>
                </div>
                {
                    displayAddRoleForm ? <AddRoles allPermissions = {allPermissions} handleCancle={handleCancle}/> : null
                }

               
            </div>
          
        </div>
</div>   
)
}

export default ViewAllRoles