import React, { useState } from 'react';
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import {AiOutlineQuestionCircle} from 'react-icons/ai'
import { Link, useNavigate } from "react-router-dom";
import Footer from './Footer';
import Spinner from './layout/Spinner';
import Alert from './layout/Alert';
import PasswordInfo from './PasswordInfo';
import { Countries } from "./constants/Country";
import { Findus } from "./constants//FindUs";
import Select from "react-select";
import PasswordConfirmationInfo from './PasswordConfirmationInfo';
import axios from 'axios';
import PhoneInput from "react-phone-input-2";


const Register = () => {
    const navigate = useNavigate()
    const [passValidation, setPassValidation] = useState(false);
    const [loading, setLoading] = useState(false)
    const [fisrtNameErrorMsg, setFisrtNameErrorMsg] = useState('')
    const [passwordErrorMsg, setPasswordErrorMsg] = useState('')
    const [passwordConfirmationErrorMsg, setPasswordConfirmationErrorMsg] = useState('')
    const [phoneErrorMsg, setPhoneErrorMsg] = useState('')
    const [emailErrorMsg, setEmailErrorMsg] = useState('')
    const [emailConfErrorMsg, setEmailConfErrorMsg] = useState('')
    const [tel, setTel] = useState('')
    const [country, setCountry] = useState("");
    const [passwordType, setPasswordType] = useState("text");
    const [info, setInfo] = useState(false);
    const [passwordConfInfo, setPasswordConfInfo] = useState(false)
    const [checked, setChecked] = useState(false); 
    const [agreement, setAgreement] = useState("");
    const [error1, setError1] = useState('none');
    const [code, setCode] = useState('233')

    const [input, setInput] = useState({
        inputEmail: '',
        inputEmailConfimation: '',
        inputPassword: '',
        inputPasswordConfirmation: '',
        inputFirstName: '',
        inputPhone: '',
      });

    const [error, setError] = useState({
        inputEmail: '',
        inputEmailConfimation: '',
        inputPassword: '',
        inputPasswordConfirmation: '',
        inputFirstName: '',
        inputPhone: '',
        // country: '',
        // findus: ''
      })

    const validateInputFeildsOnSubmit = e => {
        var regex =  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
        if (!checked) {
            setAgreement('red')
            setTimeout(() => {
                setAgreement('')
            }, 5000);
            
        }
        else if (input.inputFirstName === '') {
            setFisrtNameErrorMsg('Enter Fistname');
            setTimeout(() => {
                setFisrtNameErrorMsg('')
                
            }, 5000);
        } 
        else if(input.inputPassword === '' || input.inputPasswordConfirmation === '' || input.inputPassword !== input.inputPasswordConfirmation)
        {
            setPasswordErrorMsg('Password is empty or do not match');
            setTimeout(() => {
                setPasswordErrorMsg('')
                
            }, 5000);        
        } 
        else if(input.inputPassword.match(regex) === null)
        {
            setPasswordConfirmationErrorMsg('Must be 7-15 characters, 1 number and 1 Special Characeter');
            setTimeout(() => {
                setPasswordConfirmationErrorMsg('')
                
            }, 5000);        
        } 
        else if(input.inputEmail === '')
        {
            setEmailErrorMsg('Enter Email');
            setTimeout(() => {
                setEmailErrorMsg('')
                
            }, 5000);        
        }  
        else if(input.inputEmailConfimation === '')
        {
            setEmailConfErrorMsg('Enter Email');
            setTimeout(() => {
                setEmailConfErrorMsg('')
                
            }, 5000);        
        } 
        else if(input.inputPhone === '')
        {
            setPhoneErrorMsg('Enter Email');
            setTimeout(() => {
                setPhoneErrorMsg('')
            }, 5000);        
        } 
        else{
            setPassValidation(true)
        }         
    }

    const validateInput = e => {
        let { name, value } = e.target;
        setError(prev => {
          const stateObj = { ...prev, [name]: "" };
     
          switch (name) {
            case "inputPassword":
                var regex =  /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/;
                if (!value) {
                    stateObj[name] = "Please enter Password.";
                } else if (input.inputPasswordConfirmation && value !== input.inputPasswordConfirmation) {
                    stateObj["inputPasswordConfirmation"] = "Password and Confirm Password does not match.";
                }else if(!input.inputPassword.match(regex))
                {
                    stateObj["inputPassword"] = "Must be 7-15 characters, 1 numeric digit and a Special Characeter";                          
                } 
                 else {
                    stateObj["inputPasswordConfirmation"] = input.inputPasswordConfirmation ? "" : error.inputPasswordConfirmation;
                }
                break;
            case "inputPasswordConfirmation":
                if (!value) {
                    stateObj[name] = "Please enter Confirm Password.";
                } else if (input.inputPassword && value !== input.inputPassword) {
                    stateObj[name] = "Password and Confirm Password does not match.";
                }
                break;
            case "inputEmail":
                if (!value) {
                    stateObj[name] = "Please enter Password.";
                } else if (input.inputEmailConfimation && value !== input.inputEmailConfimation) {
                    stateObj["inputEmailConfimation"] = "Email and Confirm Email does not match.";
                } else {
                    stateObj["inputEmailConfimation"] = input.inputEmailConfimation ? "" : error.inputEmailConfimation;
                }
                break;
            case "inputEmailConfimation":
                if (!value) {
                    stateObj[name] = "Please enter Confirm Email.";
                } else if (input.inputEmail && value !== input.inputEmail) {
                    stateObj[name] = "Email and Confirm Email does not match.";
                }
                break;
            default:
              break;
          }
     
          return stateObj;
        });
    }

    const onInputChange = e => {
    const { name, value } = e.target;
    setInput(prev => ({
        ...prev,
        [name]: value
    }));
    validateInput(e);
    }

      
    const makePasswordVisible =()=>{
        if (passwordType === "inputPassword") {
            setPasswordType('text')
            return;
            
        }
        setPasswordType("inputPassword")
    }
    

      async function RegisterUser(credentials) {
        return fetch('https://apis.mutani.co/api/register', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(credentials)
        })
          .then(data => data.json())
    }

    const handleSubmit = async e => {
        e.preventDefault();
        var phone = `${code}${input.inputPhone}`;

        validateInputFeildsOnSubmit();

        if (passValidation) 
        {
            // setLoading(true);
            var first_name = input.inputFirstName;
            var password = input.inputPassword;
            var password_confirmation = input.inputPasswordConfirmation;
            var email = input.inputEmail;
            var phone = `${code}${input.inputPhone}`;


            const response = await RegisterUser({
                first_name,
                password,
                password_confirmation,
                email,
                phone,
                // country,
                // findus
            });            
            // if (response.status !== 200) {
            //     console.log('response')

            // }
           
            if ('token' in response) {
                localStorage.setItem('token', response['token']);
                localStorage.setItem('user', JSON.stringify(response['user']));
                setLoading('')
                navigate('/dashboard',{replace: true})
            }else{
                setLoading('')
                setError1('')
                setTimeout(() => {
                    setError1('none');
                }, 3000);
            }   
        }
    }

    const displayPasswordLengthInfo = () => {
        setInfo(true)
        setTimeout(() => {
            setInfo(false)
            
        }, 5000);

    }

    const displayPasswordConfirmationLengthInfo = () => {
        setPasswordConfInfo(true)
        setTimeout(() => {
            setPasswordConfInfo(false)
            
        }, 5000);

    }
    const handleReview = () => {
        setChecked(!checked)

    }

    if (loading) {
        return <Spinner/>
      } else {
        return (
            <>
                <div className='block font-roboto md:hidden lg:hidden'>
                    <div className='max-w-sm mx-4 my-4'>
                        <div className='flex justify-between items-center'>
                            <div >
                                <img
                                    src={process.env.PUBLIC_URL + `/images/logo.png`}
                                    alt=''
                                    className='h-24 w-24'
                                    id='co_founder'
                                />  
                            </div>
                            <Link to="/login" className='flex w-32 border border-black justify-center items-center h-12'>
                                <button>Login</button>
                            </Link>

                        </div>
                        <form className='mt-4 rounded' onSubmit={handleSubmit}>
                            <div className='flex w-full my-2'>
                                <div className="w-full">
                                    <label htmlFor="inputEmail" className='flex text-sm my-2'>Email<p className="text-red-500">*</p></label>
                                    <input
                                        className='appearance-none border border-black h-10 w-full px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline my-1'
                                        name='inputEmail'
                                        type='text'
                                        value={input.inputEmail}
                                        // placeholder='Enter Email'
                                        onChange={onInputChange}
                                        onBlur={validateInput}>

                                    </input>
                                    {emailErrorMsg !== "" &&  <Alert errorMsg={emailErrorMsg}/>}
                                    {error.inputEmail && <span className='text-xs text-red-500'>{error.inputEmail}</span>}
                                </div>
                            </div>
                            <div className='flex w-full my-2'>
                                <div className="w-full">
                                    <label htmlFor="inputEmailConfimation" className='flex text-sm my-2'>Email Confirmation <p className="text-red-500">*</p></label>                                   
                                    <input
                                        className='appearance-none border border-black h-10 w-full px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline my-1'
                                        name='inputEmailConfimation'
                                        type='text'
                                        value={input.inputEmailConfimation}
                                        placeholder=''
                                        onChange={onInputChange}
                                        onBlur={validateInput}>
                                    </input>
                                    {emailConfErrorMsg !== "" &&  <Alert errorMsg={emailConfErrorMsg}/>}
                                    {error.inputEmailConfimation && <span className='text-xs text-red-500'>{error.inputEmailConfimation}</span>}
                                 </div>
                            </div>
                            <div className='flex w-full my-2'>
                                <div className="w-full">
                                    <label htmlFor="inputEmailConfimation" className='flex text-sm my-2'>Password <p className="text-red-500">*</p></label>                                   
                                    <input
                                        className='appearance-none border border-black h-10 w-full px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline my-1'
                                        name='inputPassword'
                                        type='password'
                                        value={input.inputPassword}
                                        autoComplete='off'
                                        placeholder=''
                                        onChange={onInputChange}
                                        onBlur={validateInput}>
                                    </input>
                                    {passwordErrorMsg !== "" &&  <Alert errorMsg={passwordErrorMsg}/>}
                                    {error.inputPassword && <span className='flex text-xs text-red-400 my-1'>{error.inputPassword}</span>}

                                 </div>
                            </div>
                            <div className='flex w-full my-2'>
                                <div className="w-full">
                                    <label htmlFor="inputEmailConfimation" className='flex text-sm my-2'>Password Confirmation <p className="text-red-500">*</p></label>                                   
                                    <input
                                        className='appearance-none border border-black h-10 w-full px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline my-1'
                                        name='inputPasswordConfirmation'
                                        type='password'
                                        value={input.inputPasswordConfirmation}
                                        placeholder=''
                                        autoComplete='off'
                                        onChange={onInputChange}
                                        onBlur={validateInput}>
                                    </input>
                                    {passwordConfirmationErrorMsg !== "" &&  <Alert errorMsg={passwordConfirmationErrorMsg}/>}
                                    {error.inputPasswordConfirmation && <span className='flex text-xs text-red-400 my-1'>{error.inputPasswordConfirmation}</span>}
                                    
                                 </div>
                            </div>
                            <div className='flex w-full my-2'>
                                <div className="w-full">
                                    <label htmlFor="inputEmailConfimation" className='flex text-sm my-2'>Name <p className="text-red-500">*</p></label>                                   
                                    <input
                                        className='appearance-none border border-black h-10 w-full px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline my-1'
                                        name='inputFirstName'
                                        type='text'
                                        value={input.inputFirstName}
                                        placeholder=''
                                        onChange={onInputChange}
                                        onBlur={validateInput}>
                                       
                                    </input>
                                    {error.inputFirstName && <span className='err'>{error.inputFirstName}</span>}
                                    {fisrtNameErrorMsg !== "" &&  <Alert errorMsg={fisrtNameErrorMsg}/>}
                                
                                 </div>
                            </div>
                            <div className='flex w-full my-2'>
                                <div className="w-full">
                                    <label htmlFor="inputEmailConfimation" className='flex text-sm my-2'>Mobile Number <p className="text-red-500">*</p></label>                                   
                                    <input
                                        className='appearance-none border border-black h-10 w-full px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline my-1'
                                        name='inputPhone'
                                        maxLength='12'
                                        minLength='12'
                                        type="tel"
                                        // pattern="[0-9]{12}"
                                        value={input.inputPhone}
                                        placeholder=''
                                        onChange={onInputChange}
                                        onBlur={validateInput}>
                                       
                                    </input>
                                    {error.inputPhone && <span className='err'>{error.inputPhone}</span>}
                                    {phoneErrorMsg !== "" &&  <Alert errorMsg={phoneErrorMsg}/>}

                                 </div>
                            </div>
                           
                            {/* <div className='my-4'>
                                <label htmlFor="inputEmailConfimation" className='flex text-sm my-2'>Country <p className="text-red-500">*</p></label>                                   
                                <Select
                                    options={Countries}
                                    onChange={(country) => setCountry(country.value)}
                                    className="basic-multi-select w-full "
                                    classNamePrefix="select"
                                    placeholder="Select Country"
                                    styles={{
                                        control: (baseStyles, state) => ({
                                          ...baseStyles,
                                          borderColor: 'black',
                                          height: 40,

                                        }),
                                      }}
                                />
                            </div>
                            <div className='my-4'>
                                <label htmlFor="inputEmailConfimation" className='flex text-sm my-2'>How we did you find Mutani SMS? <p className="text-red-500">*</p></label>                                   
                                <Select
                                    options={Findus}
                                    onChange={(findus) => setFindUs(findus.value)}
                                    className="basic-multi-select w-full "
                                    // classNamePrefix="select"
                                    styles={{
                                        control: (baseStyles, state) => ({
                                          ...baseStyles,
                                          borderColor: 'black',
                                          height: 40,

                                        }),
                                      }}
                                />
                            </div> */}
                            <div className='flex mt-10 mb-4 w-full'>
                                <div className='flex justify-center items-center bg-[#B0DD7F] h-10 w-10'><MdOutlineKeyboardArrowRight className=''/></div>
                                <button className='bg-[#92D050] h-10 text-white w-full'>Sign Up</button>

                            </div>

                            <div className="flex text-xs">
                                <input type="checkbox" className="" onChange={handleReview}/>
                                <div style={{color: agreement}} className="">
                                    <p className='flex text-xs mx-4'>
                                        Review and Accept Mutani Account Aggrement
                                    </p>
                                </div>
                            </div>
                        </form>

                    </div>       
                </div>
                <div className='hidden font-roboto md:flex flex-col justify-center items-center py-4 md:h-screen bg-[#44546A] text-[#757575]'>
                    <div className='block md:flex flex-col bg-white md:mx-2 h-[40rem] w-[36rem]'>
                        <div className='mx-10 my-4'>
                            <div className='flex flex-col w-full  mt-2 mb-4 md:mx-2'>
                                <div className='flex justify-between items-center'>
                                    <div className='justify-start'>
                                        <img
                                            src={process.env.PUBLIC_URL + `/images/logo.png`}
                                            alt=''
                                            className='h-24 w-24'
                                            id='co_founder'
                                        />  
                                    </div>
                                    <Link to="/login" className='flex w-32 border border-gray-300 justify-center items-center h-12 mr-4'>
                                        <button>Login</button>
                                    </Link>

                                </div>
                                <h1 className='mt-4 text-sm text-gray-500'>Powerful Self-Service Messaging Portal</h1>

                                <h2 className='mt-4 text-black text-4xl font-bold leading-tight'>Sign Up</h2>

                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className='flex w-full my-2'>
                                    <div className="w-1/2 mx-2">
                                        <label htmlFor="inputEmail" className='flex text-sm my-2'>Email<p className="text-red-500">*</p></label>
                                        <input
                                            className='appearance-none border border-gray-300 h-10 w-full px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline my-1'
                                            name='inputEmail'
                                            type='text'
                                            value={input.inputEmail}
                                            // placeholder='Enter Email'
                                            onChange={onInputChange}
                                            onBlur={validateInput}>

                                        </input>
                                        {emailErrorMsg !== "" &&  <Alert errorMsg={emailErrorMsg}/>}
                                        {error.inputEmail && <span className='text-xs text-red-500'>{error.inputEmail}</span>}
                                    </div>
                                    <div className="w-1/2 mx-2">
                                        <label htmlFor="inputEmailConfimation" className='flex text-sm my-2'>Email Confirmation <p className="text-red-500">*</p></label>                                   
                                        <input
                                            className='appearance-none border border-gray-300  h-10 w-full px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline my-1'
                                            name='inputEmailConfimation'
                                            type='text'
                                            value={input.inputEmailConfimation}
                                            placeholder=''
                                            onChange={onInputChange}
                                            onBlur={validateInput}>
                                        </input>
                                        {emailConfErrorMsg !== "" &&  <Alert errorMsg={emailConfErrorMsg}/>}
                                        {error.inputEmailConfimation && <span className='text-xs text-red-500'>{error.inputEmailConfimation}</span>}
                                    </div>
                                </div>
                                <div className='flex w-full'>
                                    <div className="w-1/2 mx-2">
                                            <label htmlFor="inputEmailConfimation" className='flex text-sm my-2'>Password <p className="text-red-500">*</p></label>                                   
                                            <input
                                                className='appearance-none border border-gray-300 h-10 w-full px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline'
                                                name='inputPassword'
                                                type='password'
                                                value={input.inputPassword}
                                                autoComplete='off'
                                                placeholder=''
                                                onChange={onInputChange}
                                                onBlur={validateInput}>
                                            </input>
                                            {passwordErrorMsg !== "" &&  <Alert errorMsg={passwordErrorMsg}/>}
                                            {error.inputPassword && <span className='flex text-xs text-red-400 my-1'>{error.inputPassword}</span>}

                                    </div>
                                    <div className="w-1/2 mx-2">
                                        <label htmlFor="inputEmailConfimation" className='flex text-sm my-2'>Password Confirmation <p className="text-red-500">*</p></label>                                   
                                        <input
                                            className='appearance-none border border-gray-300 h-10 w-full px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline'
                                            name='inputPasswordConfirmation'
                                            type='password'
                                            value={input.inputPasswordConfirmation}
                                            placeholder=''
                                            autoComplete='off'
                                            onChange={onInputChange}
                                            onBlur={validateInput}>
                                        </input>
                                        {passwordConfirmationErrorMsg !== "" &&  <Alert errorMsg={passwordConfirmationErrorMsg}/>}
                                        {error.inputPasswordConfirmation && <span className='flex text-xs text-red-400'>{error.inputPasswordConfirmation}</span>}
                                    </div>
                                </div>
                                <div className='flex w-full my-2'>
                                    <div className="w-full mx-2">
                                            <label htmlFor="inputEmailConfimation" className='flex text-sm'>Name <p className="text-red-500">*</p></label>                                   
                                            <input
                                                className='appearance-none border border-gray-300 h-10 w-full px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline my-1'
                                                name='inputFirstName'
                                                type='text'
                                                value={input.inputFirstName}
                                                placeholder=''
                                                onChange={onInputChange}
                                                onBlur={validateInput}>
                                            
                                            </input>
                                            {error.inputFirstName && <span className='err'>{error.inputFirstName}</span>}
                                            {fisrtNameErrorMsg !== "" &&  <Alert errorMsg={fisrtNameErrorMsg}/>}
                                        
                                    </div>
                                </div>
                                <div className='flex w-full my-2'>
                                    <div className="flex w-full flex-col">
                                        <label htmlFor="email" className="mx-2 text-sm my-1">
                                        Mobile Number
                                        </label>
                                        <div className="flex">
                                            <div className=" ml-2">
                                                <PhoneInput
                                                country={"gh"}
                                                enableSearch={true}
                                                value={code}
                                                onChange={(code) => setCode(code)}
                                                />
                                            </div>
                                            <input
                                                type="text"
                                                name="inputPhone"
                                                className="border-gray-300 mr-2 border w-full h-10"
                                                value={input.inputPhone}
                                                onChange={onInputChange}
                                                onBlur={validateInput}
                                                minLength='9'
                                                maxLength='9'
                                            />
                                        </div>
                                    </div>
                                </div>
                                
                                <div className='flex items-center w-full' style={{display: error1}}>
                                    <input type='submit' value='Email or phone number already taken'  className='bg-red-500 w-full  my-2 h-10 text-white'/>
                                </div>

                                <div className="flex items-center justify-center mx-2">
                                        <div className="flex text-xs w-1/2">
                                            <input type="checkbox" className="mr-4" onChange={handleReview}/>
                                            <div style={{color: agreement}}>
                                                <p>Review and Accept the</p>
                                                <p>Mutani Account Agreement</p>
                                            </div>
                                        </div>
                                        <div className='flex justify-center items-center w-1/2 bg-[#B0DD7F] h-10'>
                                            {/* <div className='flex justify-center items-center  h-10 w-10'><MdOutlineKeyboardArrowRight className=''/></div> */}
                                            <input type='submit' value='submit'  className='cursor-pointer w-full my-8 h-10 text-white text-center'/>
                                        </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* <div className='w-full md:2 mt-20'>
                        <Footer/>
                    </div> */}
                </div>
            </>
          
      )
      }

    
 
}

export default Register




