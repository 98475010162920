import React, {useReducer} from 'react'
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import CollaborationContext from './collaborationContext';
import CollaborationReducer from './collaborationReducer';

import {
    GET_ALL_COLLABORATORS,
    SET_COLLABORATION_LOADING,
    SET_COLLABORATION_LOADING_OFF,
    token
  } from '../types';

const CollaborationState = ({children}) => {
  const navigate = useNavigate()

    const initialState = {
        loadingCollaborators: false,
        collaborators: [],
      };

      const token = localStorage.getItem("token");

      const [state, dispatch] = useReducer(CollaborationReducer, initialState);


      const deleteCollaborator = (id) => {
        setLoading();
        //const token = "3|ujg9hRXDrKL7AFdN8ms0wGmX8D0REfHflbuXDumZ";
        const headers = {
             accept: 'application/json',
             Authorization: 'Bearer ' + token
         }
         axios.delete(`https://apis.mutani.co/api/users/collaborators/${id}`, {
            headers: headers
        })
        .then((response) => {
          const data = response.data
          if ('Success' in data) {
            navigate(0)
            
          }
    
          dispatch({
            type: SET_COLLABORATION_LOADING_OFF
          });
    
          navigate(0)
              
        })
        .catch(function (error) {
            console.error(error);
        });
      }

      const getCollaborators = (email) => {
        setLoading();
        const headers = {
             accept: 'application/json',
             Authorization: 'Bearer ' + token
         }
         axios.get(`https://apis.mutani.co/api/users/collaboration/${email}`, {
            headers: headers
        })
        .then((response) => {
          const data = response.data.user
          // const { email, first_name, last_name, phone, organisation_name,} = data
          dispatch({
            type: GET_ALL_COLLABORATORS,
            // payload: {email,first_name, last_name, phone, organisation_name}
            payload: data
              
        });
        // navigate(0)

        })
        .catch(function (error) {
            console.error(error);
            setLoading()
            // navigate(0)

        });
      }

      const  updateCollaborator = async (credentials, id) => {
        const headers = {
             'Content-Type': 'application/json', 
             Authorization: 'Bearer ' + token,    
         }
         axios.post(`https://apis.mutani.co/api/users/collaborators/${id}`, 
         JSON.stringify(credentials),
         {
            headers: headers,
            // withCredentials: true
    
        })
        .then((response) => {
          const data = response.data
          if ('Success' in data) {
            navigate(0)
            
          }
    
          dispatch({
            type: SET_COLLABORATION_LOADING_OFF
          });
    
          navigate(0)          
        })
        .catch(function (error) {
            console.error(error);
            dispatch({
              type: SET_COLLABORATION_LOADING_OFF
            });
            
        });
      }

      const setLoading = () => dispatch({ type: SET_COLLABORATION_LOADING });

      const setLoadingOff = () => dispatch({ type: SET_COLLABORATION_LOADING_OFF });


  return (
    <CollaborationContext.Provider
        value={{
            loadingCollaborators: state.loadingCollaborators,
            collaborators: state.collaborators,
            getCollaborators,
            deleteCollaborator,
            updateCollaborator,
            setLoading,
            setLoadingOff
        }}>
        {children}
    </CollaborationContext.Provider>
  )
}

export default CollaborationState