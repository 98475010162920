import { upload } from '@testing-library/user-event/dist/upload';
import React, {useState} from 'react'
import {FaTimes} from 'react-icons/fa'
import { read, utils } from 'xlsx';

const UploadPricelist = ({handleSubmit, cancelUploadFileConfirmationModal, uploadType}) => {


   
  return (
    <>
    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-auto my-6 mx-auto max-w-3xl">
        {/*content*/}
        <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
          {/*header*/}
          <div className="flex items-start justify-between p-5 border-b border-solid border-slate-300 rounded-t">
            <div></div>
            <h3 className="text-2xl justify-center text-gray-500 font-semibold">
              Confirmation
            </h3>
            <div 
              onClick={cancelUploadFileConfirmationModal} 
              className="flex items-center justify-center h-8 hover:cursor-pointer text-3xl font-semibold">
              <FaTimes className='flex justify-center items-center fill-gray-500 text-xl'/>
            </div>
          </div>
          {/*body*/}
          <div className="relative p-6 flex-auto">
              <div className="my-4 text-slate-500 text-lg leading-relaxed h-10 w-[30rem]">
                  <p className="flex justify-center items-center  text-slate-500 text-lg leading-relaxed">
                    Are you sure you want to override the existing                
                  </p>
                  <p className="flex justify-center items-center text-slate-500 text-lg leading-relaxed">
                    <span className='font-bold mr-2'>{uploadType}</span>  list with a new pricing list?
               
                  </p>
              <div>                
            </div>
              </div>
            </div>
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                <button
                    className="bg-[#ffc000] active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 mx-8"
                    type="button"
                    onClick={cancelUploadFileConfirmationModal}
                >
                Cancel
                </button>
              <button
                className="bg-emerald-500 mr-8  text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                type="button"
                onClick={handleSubmit}
                >
                Confirm Upload
                </button>
            </div>
        </div>
      </div>
    </div>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>  
    )
}

export default UploadPricelist