export const userRoles = [
    { value: 'Primary Account Owner', label: 'Primary Account Owner' },
    { value: 'Secondary Account Owner', label: 'Secondary Account Owner' },
    { value: 'Portal Manager', label: 'Portal Manager' },
    { value: 'Advance Portal Manager', label: 'Advance Portal Manager' },
    { value: 'Send Sms Manager', label: 'Send Sms Manager' },
    { value: 'Sms Status Manager', label: 'Sms Status Manager' },
    { value: 'Campaign Engagement Viewer', label: 'Campaign Engagement Viewer' },
    { value: "Platform Performance Viewer", label: "Platform Performance Viewer" }

  ];