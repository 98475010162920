import React, {useEffect, useContext} from 'react';
import { Link } from 'react-router-dom'
import GithubContext from '../../context/user/userContext';

const Sidebar = () => {
    const githubContext = useContext(GithubContext);
    const { getRole, getUsersRoleAlongWithPermissions, permissions  } = githubContext;
    
    const user = localStorage.getItem("user");
    const parseUser = JSON.parse(user);
    const id = parseUser.id

    const arr = [];

    const formNewArrayFromUserPermissionContext = () =>{
      permissions.map((ed)=>{
        return ed.permissions.map((edd)=>{
          return arr.push(edd.name)
  
        })
  
      })

    }
    formNewArrayFromUserPermissionContext()  

    useEffect(() => {
        getRole(id);   
        getUsersRoleAlongWithPermissions()

    }, [id]);
  return (
    <div>
        <ul className='flex flex-col justify-center mt-24 align-center'>
          <Link to="/dashboard">
              <li className='border-b border-t border-gray-500 pl-8 py-1'>My Workspace</li>
            </Link>
          <div className=''>
            <Link to="">
              <li className='bg-gray-500 border-b border-t border-gray-500 pl-8 py-1'>User Management</li>
            </Link>
            <Link to="/usermanager">
              <li className='border-b border-t border-gray-500 pl-8 py-1'>My Profile</li>
            </Link>
            {
              arr.includes('can_read_collaborators') && 
              <>
                  <Link to="/usermanager/collaboration">
                    <li className='border-b pl-8 py-1 border-gray-500'>Collaboration</li>
                  </Link>
              </>

            }
            
            <li className='border-b pl-8 py-1 border-gray-500'>Preferences</li>
            <Link to="/dashboard">
              <li className='border-b pl-8 py-1 border-gray-500'>My Apps</li>
            </Link>
            <li className='border-b pl-8 py-1 border-gray-500'>Payment & Billing</li>
            <li className='border-b pl-8 py-1 border-gray-500'>API Keys</li>
            <Link to="/usermanager/csmanager">
              <li className='border-b pl-8 py-1 border-gray-500'>Customer Success</li>
            </Link>
          </div>
          {
                arr.includes('can_view_administration') && 
                <>
                  <li className='bg-gray-500 border-b border-t border-gray-500 pl-8 py-1'>Administration</li>
                  {
                    arr.includes('can_read_users') &&
                    <>
                      <Link to="/usermanager/team">
                        <li className='border-b pl-8 py-1 border-gray-500'>
                            Users
                        </li>  
                      </Link>
                    </>
                  }
                  {
                    arr.includes('can_read_roles') &&
                    <>
                      <Link to="/usermanager/roles">
                        <li className='border-b pl-8 py-1 border-gray-500'>
                            Roles
                        </li>
                      </Link>
                    </>
                  }
                  {
                    arr.includes('can_read_permissions') &&
                    <>
                      <Link to="/usermanager/permissions">
                        <li className='border-b pl-8 py-1 border-gray-500'>
                            Permissions
                        </li>
                      </Link>
                    </>
                  }
                  {/* {
                    arr.includes('can_read_groups') &&
                    <>
                      <Link to="/usermanager/groups">
                        <li className='border-b pl-8 py-1 border-gray-500'>
                            Groups
                        </li>
                      </Link>
                    </>
                  } */}
                  {
                    arr.includes('can_read_sender_id') &&
                    <>
                      <Link to="/usermanager/senderids">
                        <li className='border-b pl-8 py-1 border-gray-500'>
                          Sender IDs
                        </li>
                      </Link>
                    </>
                  }  
                  {
                    arr.includes('can_read_sender_id') &&
                    <>
                      <Link to="/usermanager/pricelist">
                        <li className='border-b pl-8 py-1 border-gray-500'>
                          Pricelists
                        </li>
                      </Link>
                    </>
                  } 
                </>
          }
        </ul>
        
    </div>  
    )}

export default Sidebar

