export const Countries = [
    {   value: "AFGHANISTAN",   label: "AFGHANISTAN"},
    {   value: "ALBANIA",   label: "ALBANIA"},
    {   value: "ALGERIA",   label: "ALGERIA"},
    {   value: "AMERICAN SAMOA",    label: "AMERICAN SAMOA"},
    {   value: "ANDORRA",   label: "ANDORRA"},
    {   value: "ANGOLA",    label: "ANGOLA"},
    {   value: "ANGUILLA",  label: "ANGUILLA"},
    {   value: "ANTARCTICA",    label: "ANTARCTICA"},
    {   value: "ANTIGUA AND BARBUDA",   label: "ANTIGUA AND BARBUDA"},
    {   value: "ARGENTINA", label: "ARGENTINA"},
    {   value: "ARMENIA",   label: "ARMENIA"},
    {   value: "ARUBA", label: "ARUBA"},
    {   value: "AUSTRALIA", label: "AUSTRALIA"},
    {   value: "AUSTRIA",   label: "AUSTRIA"},
    {   value: "AZERBAIJAN",    label: "AZERBAIJAN"},
    {   value: "BAHAMAS",   label: "BAHAMAS"},
    {   value: "BAHRAIN",   label: "BAHRAIN"},
    {   value: "BANGLADESH",    label: "BANGLADESH"},
    {   value: "BARBADOS",  label: "BARBADOS"},
    {   value: "BELARUS",   label: "BELARUS"},
    {   value: "BELGIUM",   label: "BELGIUM"},
    {   value: "BELIZE",    label: "BELIZE"},
    {   value: "BENIN", label: "BENIN"},
    {   value: "BERMUDA",   label: "BERMUDA"},
    {   value: "BHUTAN",    label: "BHUTAN"},
    {   value: "BOLIVIA",   label: "BOLIVIA"},
    {   value: "BOSNIA AND HERZEGOWINA",    label: "BOSNIA AND HERZEGOWINA"},
    {   value: "BOTSWANA",  label: "BOTSWANA"},
    {   value: "BOUVET ISLAND", label: "BOUVET ISLAND"},
    {   value: "BRAZIL",    label: "BRAZIL"},
    {   value: "BRIT. INDIAN OCEAN TERRITORY",  label: "BRIT. INDIAN OCEAN TERRITORY"},
    {   value: "BRUNEI DARUSSALAM", label: "BRUNEI DARUSSALAM"},
    {   value: "BULGARIA",  label: "BULGARIA"},
    {   value: "BURKINA FASO",  label: "BURKINA FASO"},
    {   value: "BURUNDI",   label: "BURUNDI"},
    {   value: "CAMBODIA",  label: "CAMBODIA"},
    {   value: "CAMEROON",  label: "CAMEROON"},
    {   value: "CANADA",    label: "CANADA"},
    {   value: "CAPE VERDE",    label: "CAPE VERDE"},
    {   value: "CAYMAN ISLANDS",    label: "CAYMAN ISLANDS"},
    {   value: "CENTRAL AFRICAN REPUBLIC",  label: "CENTRAL AFRICAN REPUBLIC"},
    {   value: "CHAD",  label: "CHAD"},
    {   value: "CHILE", label: "CHILE"},
    {   value: "CHINA", label: "CHINA"},
    {   value: "CHRISTMAS ISLAND",  label: "CHRISTMAS ISLAND"},
    {   value: "COCOS (KEELING) ISLANDS",   label: "COCOS (KEELING) ISLANDS"},
    {   value: "COLOMBIA",  label: "COLOMBIA"},
    {   value: "COMOROS",   label: "COMOROS"},
    {   value: "CONGO", label: "CONGO"},
    {   value: "COOK ISLANDS",  label: "COOK ISLANDS"},
    {   value: "COSTA RICA",    label: "COSTA RICA"},
    {   value: "COTE D'IVOIRE", label: "COTE D'IVOIRE"},
    {   value: "CROATIA",   label: "CROATIA"},
    {   value: "CUBA",  label: "CUBA"},
    {   value: "CYPRUS",    label: "CYPRUS"},
    {   value: "CZECH REPUBLIC",    label: "CZECH REPUBLIC"},
    {   value: "DENMARK",   label: "DENMARK"},
    {   value: "DJIBOUTI",  label: "DJIBOUTI"},
    {   value: "DOMINICA",  label: "DOMINICA"},
    {   value: "DOMINICAN REPUBLIC",    label: "DOMINICAN REPUBLIC"},
    {   value: "EAST TIMOR",    label: "EAST TIMOR"},
    {   value: "ECUADOR",   label: "ECUADOR"},
    {   value: "EGYPT", label: "EGYPT"},
    {   value: "EL SALVADOR",   label: "EL SALVADOR"},
    {   value: "EQUATORIAL GUINEA", label: "EQUATORIAL GUINEA"},
    {   value: "ERITREA",   label: "ERITREA"},
    {   value: "ESTONIA",   label: "ESTONIA"},
    {   value: "ETHIOPIA",  label: "ETHIOPIA"},
    {   value: "FALKLAND ISLANDS (MALVINAS)",   label: "FALKLAND ISLANDS (MALVINAS)"},
    {   value: "FAEROE ISLANDS",    label: "FAEROE ISLANDS"},
    {   value: "FIJI",  label: "FIJI"},
    {   value: "FINLAND",   label: "FINLAND"},
    {   value: "FRANCE",    label: "FRANCE"},
    {   value: "FRENCH GUIANA", label: "FRENCH GUIANA"},
    {   value: "FRENCH POLYNESIA",  label: "FRENCH POLYNESIA"},
    {   value: "FRENCH SOUTHERN TERRITOR.", label: "FRENCH SOUTHERN TERRITOR."},
    {   value: "GABON", label: "GABON"},
    {   value: "GAMBIA",    label: "GAMBIA"},
    {   value: "GEORGIA",   label: "GEORGIA"},
    {   value: "GERMANY",   label: "GERMANY"},
    {   value: "GHANA", label: "GHANA"},
    {   value: "GIBRALTAR", label: "GIBRALTAR"},
    {   value: "GREECE",    label: "GREECE"},
    {   value: "GREENLAND", label: "GREENLAND"},
    {   value: "GRENADA",   label: "GRENADA"},
    {   value: "GUADELOUPE",    label: "GUADELOUPE"},
    {   value: "GUAM",  label: "GUAM"},
    {   value: "GUATEMALA", label: "GUATEMALA"},
    {   value: "GUINEA",    label: "GUINEA"},
    {   value: "GUINEA-BISSAU", label: "GUINEA-BISSAU"},
    {   value: "GUYANA",    label: "GUYANA"},
    {   value: "HAITI", label: "HAITI"},
    {   value: "HEARD AND MC DONALD ISLANDS",   label: "HEARD AND MC DONALD ISLANDS"},
    {   value: "HONDURAS",  label: "HONDURAS"},
    {   value: "HONG KONG", label: "HONG KONG"},
    {   value: "HUNGARY",   label: "HUNGARY"},
    {   value: "ICELAND",   label: "ICELAND"},
    {   value: "INDIA", label: "INDIA"},
    {   value: "INDONESIA", label: "INDONESIA"},
    {   value: "IRAN",  label: "IRAN"},
    {   value: "IRAQ",  label: "IRAQ"},
    {   value: "IRELAND",   label: "IRELAND"},
    {   value: "ISRAEL",    label: "ISRAEL"},
    {   value: "ITALY", label: "ITALY"},
    {   value: "JAMAICA",   label: "JAMAICA"},
    {   value: "JAPAN", label: "JAPAN"},
    {   value: "JORDAN",    label: "JORDAN"},
    {   value: "KAZAKHSTAN",    label: "KAZAKHSTAN"},
    {   value: "KENYA", label: "KENYA"},
    {   value: "KIRIBATI",  label: "KIRIBATI"},
    {   value: "KOREA, DEM. PEOPLE'S REP. OF",  label: "KOREA, DEM. PEOPLE'S REP. OF"},
    {   value: "KOREA, REPUBLIC OF",    label: "KOREA, REPUBLIC OF"},
    {   value: "KUWAIT",    label: "KUWAIT"},
    {   value: "KYRGYZSTAN",    label: "KYRGYZSTAN"},
    {   value: "LAO PEOPLE'S DEMOCRATIC REP.",  label: "LAO PEOPLE'S DEMOCRATIC REP."},
    {   value: "LATVIA",    label: "LATVIA"},
    {   value: "LEBANON",   label: "LEBANON"},
    {   value: "LESOTHO",   label: "LESOTHO"},
    {   value: "LIBERIA",   label: "LIBERIA"},
    {   value: "LIBYAN ARAB JAMAHIRIYA",    label: "LIBYAN ARAB JAMAHIRIYA"},
    {   value: "LIECHTENSTEIN", label: "LIECHTENSTEIN"},
    {   value: "LITHUANIA", label: "LITHUANIA"},
    {   value: "LUXEMBOURG",    label: "LUXEMBOURG"},
    {   value: "MACAU", label: "MACAU"},
    {   value: "MACEDONIA", label: "MACEDONIA"},
    {   value: "MADAGASCAR",    label: "MADAGASCAR"},
    {   value: "MALAWI",    label: "MALAWI"},
    {   value: "MALAYSIA",  label: "MALAYSIA"},
    {   value: "MALDIVES",  label: "MALDIVES"},
    {   value: "MALI",  label: "MALI"},
    {   value: "MALTA", label: "MALTA"},
    {   value: "MARSHALL ISLANDS",  label: "MARSHALL ISLANDS"},
    {   value: "MARTINIQUE",    label: "MARTINIQUE"},
    {   value: "MAURITANIA",    label: "MAURITANIA"},
    {   value: "MAURITIUS", label: "MAURITIUS"},
    {   value: "MEXICO",    label: "MEXICO"},
    {   value: "MICRONESIA  (FED. STATES OF)",  label: "MICRONESIA  (FED. STATES OF)"},
    {   value: "MOLDOVA, REPUBLIC OF",  label: "MOLDOVA, REPUBLIC OF"},
    {   value: "MONACO",    label: "MONACO"},
    {   value: "MONGOLIA",  label: "MONGOLIA"},
    {   value: "MONTSERRAT",    label: "MONTSERRAT"},
    {   value: "MOROCCO",   label: "MOROCCO"},
    {   value: "MOZAMBIQUE",    label: "MOZAMBIQUE"},
    {   value: "MYANMAR",   label: "MYANMAR"},
    {   value: "NAMIBIA",   label: "NAMIBIA"},
    {   value: "NAURU", label: "NAURU"},
    {   value: "NEPAL", label: "NEPAL"},
    {   value: "NETHERLANDS",   label: "NETHERLANDS"},
    {   value: "NETHERLANDS ANTILLES",  label: "NETHERLANDS ANTILLES"},
    {   value: "NEW CALEDONIA", label: "NEW CALEDONIA"},
    {   value: "NEW ZEALAND",   label: "NEW ZEALAND"},
    {   value: "NICARAGUA", label: "NICARAGUA"},
    {   value: "NIGER", label: "NIGER"},
    {   value: "NIGERIA",   label: "NIGERIA"},
    {   value: "NIUE",  label: "NIUE"},
    {   value: "NORFOLK ISLAND",    label: "NORFOLK ISLAND"},
    {   value: "NORTHERN MARIANA ISLANDS",  label: "NORTHERN MARIANA ISLANDS"},
    {   value: "NORWAY",    label: "NORWAY"},
    {   value: "OMAN",  label: "OMAN"},
    {   value: "PAKISTAN",  label: "PAKISTAN"},
    {   value: "PALAU", label: "PALAU"},
    {   value: "PANAMA",    label: "PANAMA"},
    {   value: "PAPUA NEW GUINEA",  label: "PAPUA NEW GUINEA"},
    {   value: "PARAGUAY",  label: "PARAGUAY"},
    {   value: "PERU",  label: "PERU"},
    {   value: "PHILIPPINES",   label: "PHILIPPINES"},
    {   value: "PITCAIRN",  label: "PITCAIRN"},
    {   value: "POLAND",    label: "POLAND"},
    {   value: "PORTUGAL",  label: "PORTUGAL"},
    {   value: "PUERTO RICO",   label: "PUERTO RICO"},
    {   value: "QATAR", label: "QATAR"},
    {   value: "REUNION",   label: "REUNION"},
    {   value: "ROMANIA",   label: "ROMANIA"},
    {   value: "RUSSIAN FEDERATION",    label: "RUSSIAN FEDERATION"},
    {   value: "RWANDA",    label: "RWANDA"},
    {   value: "ST. HELENA",    label: "ST. HELENA"},
    {   value: "SAINT KITTS AND NEVIS", label: "SAINT KITTS AND NEVIS"},
    {   value: "SAINT LUCIA",   label: "SAINT LUCIA"},
    {   value: "ST. PIERRE AND MIQUELON",   label: "ST. PIERRE AND MIQUELON"},
    {   value: "SAINT VINCENT / GRENADINES",    label: "SAINT VINCENT / GRENADINES"},
    {   value: "SAMOA WESTERN", label: "SAMOA WESTERN"},
    {   value: "SAN MARINO",    label: "SAN MARINO"},
    {   value: "SAO TOME AND PRINCIPE", label: "SAO TOME AND PRINCIPE"},
    {   value: "SAUDI ARABIA",  label: "SAUDI ARABIA"},
    {   value: "SENEGAL",   label: "SENEGAL"},
    {   value: "SEYCHELLES",    label: "SEYCHELLES"},
    {   value: "SIERRA LEONE",  label: "SIERRA LEONE"},
    {   value: "SINGAPORE", label: "SINGAPORE"},
    {   value: "SLOVAKIA",  label: "SLOVAKIA"},
    {   value: "SLOVENIA",  label: "SLOVENIA"},
    {   value: "SOLOMON ISLANDS",   label: "SOLOMON ISLANDS"},
    {   value: "SOMALIA",   label: "SOMALIA"},
    {   value: "SOUTH AFRICA",  label: "SOUTH AFRICA"},
    {   value: "SPAIN", label: "SPAIN"},
    {   value: "SRI LANKA", label: "SRI LANKA"},
    {   value: "SUDAN", label: "SUDAN"},
    {   value: "SURINAME",  label: "SURINAME"},
    {   value: "SVALBARD / JAN MAYEN IS.",  label: "SVALBARD / JAN MAYEN IS."},
    {   value: "SWAZILAND", label: "SWAZILAND"},
    {   value: "SWEDEN",    label: "SWEDEN"},
    {   value: "SWITZERLAND",   label: "SWITZERLAND"},
    {   value: "SYRIAN ARAB REPUBLIC",  label: "SYRIAN ARAB REPUBLIC"},
    {   value: "TAIWAN, PROVINCE OF CHINA", label: "TAIWAN, PROVINCE OF CHINA"},
    {   value: "TAJIKISTAN",    label: "TAJIKISTAN"},
    {   value: "TANZANIA, UNITED REPUBLIC OF",  label: "TANZANIA, UNITED REPUBLIC OF"},
    {   value: "THAILAND",  label: "THAILAND"},
    {   value: "TOGO",  label: "TOGO"},
    {   value: "TOKELAU",   label: "TOKELAU"},
    {   value: "TONGA", label: "TONGA"},
    {   value: "TRINIDAD AND TOBAGO",   label: "TRINIDAD AND TOBAGO"},
    {   value: "TUNISIA",   label: "TUNISIA"},
    {   value: "TURKEY",    label: "TURKEY"},
    {   value: "TURKMENISTAN",  label: "TURKMENISTAN"},
    {   value: "TURKS AND CAICOS ISLANDS",  label: "TURKS AND CAICOS ISLANDS"},
    {   value: "TUVALU",    label: "TUVALU"},
    {   value: "UGANDA",    label: "UGANDA"},
    {   value: "UKRAINE",   label: "UKRAINE"},
    {   value: "UNITED ARAB EMIRATES",  label: "UNITED ARAB EMIRATES"},
    {   value: "UNITED KINGDOM",    label: "UNITED KINGDOM"},
    {   value: "UNITED STATES", label: "UNITED STATES"},
    {   value: "UNITED STATES MIN. OUTL. ISLS.",    label: "UNITED STATES MIN. OUTL. ISLS."},
    {   value: "URUGUAY",   label: "URUGUAY"},
    {   value: "UZBEKISTAN",    label: "UZBEKISTAN"},
    {   value: "VANUATU",   label: "VANUATU"},
    {   value: "VATICAN CITY STATE (HOLY SEE)", label: "VATICAN CITY STATE (HOLY SEE)"},
    {   value: "VENEZUELA", label: "VENEZUELA"},
    {   value: "VIET NAM",  label: "VIET NAM"},
    {   value: "VIRGIN ISLANDS (BRITISH)",  label: "VIRGIN ISLANDS (BRITISH)"},
    {   value: "VIRGIN ISLANDS (U.S.)", label: "VIRGIN ISLANDS (U.S.)"},
    {   value: "WALLIS AND FUTUNA ISLANDS", label: "WALLIS AND FUTUNA ISLANDS"},
    {   value: "WESTERN SAHARA",    label: "WESTERN SAHARA"},
    {   value: "YEMEN", label: "YEMEN"},
    {   value: "YUGOSLAVIA",    label: "YUGOSLAVIA"},
    {   value: "ZAIRE", label: "ZAIRE"},
    {   value: "ZAMBIA",    label: "ZAMBIA"},
    {   value: "ZIMBABWE",  label: "ZIMBABWE"},
    {   value: "EUROPEAN CURRENCY UNIT",    label: "EUROPEAN CURRENCY UNIT"},
    {   value: "SPECIAL DRAWING RIGHTS",    label: "SPECIAL DRAWING RIGHTS"}
]